function containsLowerCaseLetter(string: string): boolean {
  const regex = /(?=.*[a-z])/;

  return regex.test(string);
}

function containsUpperCaseLetter(string: string): boolean {
  const regex = /(?=.*[A-Z])/;

  return regex.test(string);
}

function containsNumber(string: string): boolean {
  const regex = /(?=.*\d)/;

  return regex.test(string);
}

function containsSpecialCharacter(string: string): boolean {
  const regex = /(?=.*[^a-zA-Z0-9])/;

  return regex.test(string);
}

function longerThanTenCharacters(string: string): boolean {
  const regex = /(?=.{10,})/;

  return regex.test(string);
}

type TPasswordRequirements = {
  containsLowerCaseLetter: boolean;
  containsUpperCaseLetter: boolean;
  containsNumber: boolean;
  containsSpecialCharacter: boolean;
  longerThanTenCharacters: boolean;
  meetsCharacterRequirements: boolean;
};

export function getPasswordRequirements(string: string): TPasswordRequirements {
  const requirements = {
    containsLowerCaseLetter: containsLowerCaseLetter(string),
    containsUpperCaseLetter: containsUpperCaseLetter(string),
    containsNumber: containsNumber(string),
    containsSpecialCharacter: containsSpecialCharacter(string),
    longerThanTenCharacters: longerThanTenCharacters(string),
  };
  const meetsCharacterRequirements =
    [
      requirements.containsLowerCaseLetter,
      requirements.containsUpperCaseLetter,
      requirements.containsNumber,
      requirements.containsSpecialCharacter,
    ].filter((value) => value === true).length >= 3;

  return {
    ...requirements,
    meetsCharacterRequirements,
  };
}

export function passwordMeetsRequirements(string: string): boolean {
  const { longerThanTenCharacters, meetsCharacterRequirements } =
    getPasswordRequirements(string);

  return longerThanTenCharacters && meetsCharacterRequirements;
}
