import { SyntheticEvent, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Heading, Link, Text } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { Events, mixpanel } from '../../../analytics';
import { LegalDocumentTitle } from '../../../types';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../../constants';

interface IProps {
  document: any;
  documentTitle: LegalDocumentTitle;
  number: number;
  onNext: () => void;
  totalCount: number;
}

const BOTTOM_BAR_HEIGHT = '210px';

export default function DocumentViewer({
  document,
  documentTitle,
  number,
  onNext,
  totalCount,
}: IProps): JSX.Element {
  const [markdown, setMarkdown] = useState<any>();
  const [agreedToDocument, setAgreedToDocument] = useState<boolean>(false);

  useEffect(() => {
    setAgreedToDocument(false);

    async function fetchMarkdown() {
      const response = await fetch(document);
      const text = await response.text();

      setMarkdown(text);
    }

    fetchMarkdown();
  }, [document]);

  useEffect(() => {
    mixpanel.track(Events.AGREEMENT_VIEWED, { agreement_name: documentTitle });
  }, [documentTitle]);

  function handleOnClickNext() {
    mixpanel.track(Events.AGREEMENT_SIGNED, { agreement_name: documentTitle });

    onNext();
    window.scrollTo(0, 0);
  }

  function handleOnChange({ currentTarget }: SyntheticEvent<HTMLInputElement>) {
    setAgreedToDocument(currentTarget.checked);
  }

  return (
    <Box w="100%" display="flex" flexDirection="column">
      <Box alignSelf="center" maxW={512} p={6} mb={BOTTOM_BAR_HEIGHT}>
        <Text color="gray.400" fontWeight="semibold" mb={6}>
          {number} of {totalCount}
        </Text>
        <Box>
          <ReactMarkdown
            children={markdown}
            components={{
              h1: ({ children }) => (
                <Heading size="md" mb={4}>
                  {children}
                </Heading>
              ),
              h2: ({ children }) => (
                <Heading size="sm" mb={1} mt={4}>
                  {children}
                </Heading>
              ),
              h3: ({ children }) => (
                <Heading size="xs" mb={1} mt={4}>
                  {children}
                </Heading>
              ),
              p: ({ children }) => <Text mb={2}>{children}</Text>,
            }}
          />
        </Box>
      </Box>
      <Box
        position="fixed"
        bottom={0}
        w="100%"
        h={BOTTOM_BAR_HEIGHT}
        bg="functional.cream"
        borderTop="1px solid"
        borderColor="functional.warmGray.100"
        boxShadow="0 -1px 2px var(--chakra-colors-functional-warmGray-100)"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box w="100%" maxW={512} p={6}>
          <Checkbox
            mb={4}
            onChange={handleOnChange}
            isChecked={agreedToDocument}
          >
            <Text fontSize="sm">
              By clicking this box, I acknowledge that I have read, understood,
              and agree to the {documentTitle} document.
            </Text>
          </Checkbox>

          <Button
            bg="brand.purple"
            mb={3}
            borderRadius={20}
            color="white"
            width="100%"
            type="submit"
            _hover={{
              backgroundColor: 'brand.darkPurple',
            }}
            isDisabled={!agreedToDocument}
            onClick={handleOnClickNext}
          >
            Next
          </Button>

          <Box textAlign="center" fontSize="sm" fontWeight="light">
            <Text>If you have questions, please call us at</Text>
            <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
              {MARLEY_PATIENT_PHONE_NUMBER}
            </Link>
            .
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
