import { Timestamp } from 'firebase/firestore';

type TSexAtBirth = 'male' | 'female' | 'other' | 'unknown';

export enum CommunicationPreference {
  EMAIL = 'email',
  SMS = 'sms',
}

export interface Appointment {
  id: string;
  adminId: string;
  confirmationPage?: string;
  datetime: string;
  timezone: string;
  datetimeCreated: string;
  duration: number;
  receivedAt: string;
  patientUid: string;
  canceled: boolean;
  completed: boolean;
  noShow: boolean;
  type: {
    id: string;
    name: string;
    isMeetAndGreet: boolean;
    isHealthCoachVisit: boolean;
    isWelcomeToMarley: boolean;
    isIntakeVisit: boolean;
    isNewPatientVisit: boolean;
    isPatientEducationVisit: boolean;
    isMonthlyCheckIn: boolean;
    isOfficeVisit: boolean;
    isPharmacistVisit: boolean;
  };
  location: string;
}

export interface IPatientPrograms {
  diabetes: {
    enrolled: boolean;
    enrolledOn: Timestamp | null;
  };
  hypertension: {
    enrolled: boolean;
    enrolledOn: Timestamp | null;
  };
  weight: {
    enrolled: boolean;
    enrolledOn: Timestamp | null;
  };
}

interface IPatientFirstLastBpReading {
  diastolic: number;
  heartRate: number;
  id: string;
  readingTime: Timestamp;
  systolic: number;
}

interface IPatientFirstLastGlucoseReading {
  id: string;
  readingTime: Timestamp;
  value: number;
}

interface IPatientFirstLastWeightReading {
  id: string;
  bmi?: number | null; // body mass index calculated at the time of the reading
  readingTime: Timestamp; // maps to item uid on readingWeights collection
  value: number;
}

interface IPatientBloodPressureGoal {
  atGoal: boolean;
  diastolic: number;
  id: string;
  notAtGoalReasons: string[];
  systolic: number;
  text: string;
}

export enum BillingType {
  INSURANCE = 'insurance',
  SELF_PAY = 'self-pay',
}

export enum SubscriptionType {
  GRANDFATHERED = 'grandfathered',
  FINANCIAL_AID = 'financialAid',
  STANDARD = 'standard',
}

export enum FinancialAidStatus {
  APPROVED = 'approved',
  APPLICATION_SENT = 'application_sent',
  DENIED = 'denied',
  NOT_APPLICABLE = 'not_applicable',
}

export interface IPatient {
  uid: string;
  address: {
    lineOne: string;
    lineTwo?: string;
    city: string;
    state: string;
    zipCode: string;
  };
  billing: {
    autoCollection: boolean | null;
    balance: number;
    financialAidStatus: FinancialAidStatus;
    financialAidApprovedAt: Timestamp | null;
    subscriptionType: SubscriptionType;
    type: BillingType;
  };
  bloodPressureGoals: IPatientBloodPressureGoal[];
  canvasId: string;
  careTeamLead: ICareTeamLead | null;
  clinicalReviewState: {
    comment: string | null;
    date: Timestamp | null;
    state: ClinicalReviewStates;
  };
  communicationPreference: CommunicationPreference;
  createdAt: Timestamp;
  customerIOId: string;
  dateOfBirth: string;
  deactivatedOn?: Timestamp | null;
  email: string;
  engagementActivity: {
    lastMessageReadAt: Timestamp | null;
    lastMessageReceivedAt: Timestamp | null;
    lastMessageSentAt: Timestamp | null;
  };
  enrolledOn: Timestamp | null;
  firstName: string;
  firstReadingBloodGlucose?: IPatientFirstLastGlucoseReading;
  firstReadingBloodPressure?: IPatientFirstLastBpReading;
  firstReadingWeight?: IPatientFirstLastWeightReading;
  hasConsentedToTreatment?: boolean;
  hasDailyReadingReminders?: boolean;
  hasLoggedIn?: boolean;
  impiloId: string;
  isActive: boolean;
  isEnrolled: boolean;
  lastName: string;
  lastReadingBloodGlucose?: IPatientFirstLastGlucoseReading;
  lastReadingBloodPressure?: IPatientFirstLastBpReading;
  lastReadingWeight?: IPatientFirstLastWeightReading;
  phoneNumber: string;
  programs: IPatientPrograms;
  readingBloodGlucoseCount?: number;
  readingBloodPressureCount?: number;
  readingWeightCount?: number;
  segments: string[];
  sexAtBirth: TSexAtBirth;
  sourceId: string;
  stripeId: string | null;
}

interface ICareTeamLead {
  adminId: string;
  display: string;
  role: string;
}

export enum ClinicalReviewStates {
  NOT_APPLICABLE = 'not_applicable',
  REVIEWABLE = 'reviewable',
  BLOCKED = 'blocked',
  CHASE_LIST = 'chase_list',
  REVIEWING = 'reviewing',
  CHART_PREP = 'chart_prep',
}

export enum LegalDocumentTitle {
  NEW_PATIENT_AGREEMENT = 'New Patient Agreement',
  INFORMED_CONSENT = 'Informed Consent',
  HIPAA_NOTICE = 'HIPAA Notice',
  TELEPHONE_CONSUMER_PROTECTIONS = 'Receiving Texts and Emails',
  ASSIGNMENT_OF_BENEFITS = 'Assignment of Benefits',
}

export type TLegalDocument = {
  file: File;
  title: LegalDocumentTitle;
};

export interface IBloodPressureSummaryMetrics {
  firstBP?: IPatientFirstLastBpReading;
  lastBP?: IPatientFirstLastBpReading;
  readingsAvg: IBloodPressureReading | null;
  readingsCount: number;
  bpGoal: IPatientBloodPressureGoal | null;
}

export interface IReadingWeight {
  id: string;
  createdAt: Timestamp;
  deviceId: string;
  outlier: boolean;
  patientUid: string;
  readingTime: Timestamp;
  receivedAt: Timestamp;
  source: string;
  value: number;
}

export interface IReadingBloodGlucose {
  id: string;
  createdAt: Timestamp;
  deviceId: string;
  patientUid: string;
  readingTime: Timestamp;
  receivedAt: Timestamp;
  source: string;
  value: number;
}

export interface IReadingBloodPressure {
  id: string;
  createdAt: Timestamp;
  deviceId: string;
  diastolic: number;
  heartRate: number;
  patientUid: string;
  readingTime: Timestamp;
  receivedAt: Timestamp;
  source: string;
  systolic: number;
}

export interface IBloodPressureReading {
  systolic: number;
  diastolic: number;
}
