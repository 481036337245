import { createContext, useContext, useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { IPatient } from '../types';

const PatientContext = createContext<any>(undefined);

interface IPatientProvider {
  children: React.ReactNode;
}

type TPatientProviderValue = {
  patient: IPatient | null;
};

export default function PatientProvider({ children }: IPatientProvider) {
  const [patientUid, setPatientUid] = useState<string | null>(null);
  const [patient, setPatient] = useState<IPatient | null>(null);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setPatientUid(user.uid);
    } else {
      setPatientUid(null);
      setPatient(null);
    }
  });

  useEffect(() => {
    if (!patientUid) {
      return;
    }

    const patientRef = doc(db, `patients/${patientUid}`);

    const unsubscribe = onSnapshot(patientRef, (doc) => {
      setPatient({
        uid: doc.id,
        ...doc.data(),
      } as IPatient);
    });

    return () => unsubscribe();
  }, [patientUid]);

  const value: TPatientProviderValue = {
    patient,
  };

  return (
    <PatientContext.Provider value={value}>{children}</PatientContext.Provider>
  );
}

function usePatient() {
  const context: TPatientProviderValue = useContext(PatientContext);

  if (!context) {
    throw new Error('usePatient must be used within PatientProvider');
  }

  return context;
}

export { usePatient };
