import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useToast } from '@chakra-ui/react';
import DocumentViewer from './DocumentViewer';
import Overview from './Overview';
import { legalDocumentsByTitle } from '../../constants';
import { LegalDocumentTitle } from '../../types';
import { Events, mixpanel } from '../../analytics';
import { functions } from '../../firebase';
import { captureException } from '../../utils';

export const DOCUMENT_ORDER = [
  LegalDocumentTitle.NEW_PATIENT_AGREEMENT,
  LegalDocumentTitle.INFORMED_CONSENT,
  LegalDocumentTitle.HIPAA_NOTICE,
  LegalDocumentTitle.TELEPHONE_CONSUMER_PROTECTIONS,
  LegalDocumentTitle.ASSIGNMENT_OF_BENEFITS,
];

function getCurrentLegalDocument(documentIndex: number) {
  const currentDocumentTitle = DOCUMENT_ORDER[documentIndex];

  return legalDocumentsByTitle[currentDocumentTitle];
}

export default function LegalAgreementsPage(): JSX.Element {
  const [documentIndex, setDocumentIndex] = useState<number>(0);
  const [showOverview, setShowOverview] = useState<boolean>(true);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  const toast = useToast();

  const currentDocument = getCurrentLegalDocument(documentIndex);

  function handleNextStep() {
    const nextDocumentIndex = documentIndex + 1;

    if (nextDocumentIndex < DOCUMENT_ORDER.length) {
      setDocumentIndex(documentIndex + 1);
    } else {
      setIsComplete(true);
      setShowOverview(true);
    }
  }

  function handleStart() {
    setShowOverview(false);
  }

  async function handleComplete() {
    const onPatientConsentToTreatment = httpsCallable(
      functions,
      'onPatientConsentToTreatment',
    );

    mixpanel.track(Events.AGREEMENTS_COMPLETED);

    setIsSubmitting(true);

    try {
      await onPatientConsentToTreatment();

      navigate('/messages');
    } catch (error) {
      setIsSubmitting(false);
      captureException(error, 'critical');
      toast({
        description: 'An error occurred. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return showOverview ? (
    <Overview
      onClick={isComplete ? handleComplete : handleStart}
      isComplete={isComplete}
      isSubmitting={isSubmitting}
    />
  ) : (
    <DocumentViewer
      document={currentDocument.file}
      documentTitle={currentDocument.title}
      number={documentIndex + 1}
      totalCount={DOCUMENT_ORDER.length}
      onNext={handleNextStep}
    />
  );
}
