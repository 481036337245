import { useEffect, useRef } from 'react';
import { Textarea } from '@chakra-ui/react';

interface IProps {
  isDisabled?: boolean;
  onChange: (value: string) => void;
  placeholder: string;
  textareaHeight: number;
  setTextareaHeight?: React.Dispatch<React.SetStateAction<number>>;
  value: string;
}

export const INITIAL_TEXTAREA_HEIGHT = 112;
const MAX_TEXTAREA_HEIGHT = 150;

export default function AutoSizeTextarea({
  isDisabled = false,
  onChange,
  placeholder,
  textareaHeight,
  setTextareaHeight,
  value,
}: IProps): JSX.Element {
  useEffect(() => {
    if (!value) {
      const textarea = textareaRef.current!;

      textarea.style.height = `${textareaHeight}px`;
    }
  }, [textareaHeight, value]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  function handleResize() {
    const textarea = textareaRef.current!;
    // sometimes scrollHeight ref will be less than original height in input, always grab the greater of the two vals
    const dynamicTextareaHeight = Math.max(
      textarea.scrollHeight,
      INITIAL_TEXTAREA_HEIGHT,
    );

    textarea.style.height = `${dynamicTextareaHeight}px`;
    // only resize parent container if prop is provided and it's below max height val
    if (setTextareaHeight && dynamicTextareaHeight < MAX_TEXTAREA_HEIGHT) {
      setTextareaHeight(dynamicTextareaHeight);
    }
  }

  return (
    <Textarea
      borderWidth="2px"
      borderColor="functional.warmGray.300"
      h={`${textareaHeight}px`}
      ref={textareaRef}
      bg="white"
      fontSize="lg"
      isDisabled={isDisabled}
      placeholder={placeholder}
      onChange={({ currentTarget }) => onChange(currentTarget.value)}
      onInput={handleResize}
      maxHeight={`${MAX_TEXTAREA_HEIGHT}px`}
      value={value}
    />
  );
}
