import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { db } from '../firebase';
import {
  QueryConstraint,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { IReadingBloodPressure } from '../types';

export default function useFetchBPReadings({
  patientUid,
  startTime,
  endTime,
}: {
  patientUid?: string;
  startTime?: Date;
  endTime?: Date;
}): [IReadingBloodPressure[], boolean] {
  const [isLoading, setIsLoading] = useState(true);
  const [bloodPressureReadings, setBloodPressureReadings] = useState<
    IReadingBloodPressure[]
  >([]);
  const toast = useToast();

  useEffect(() => {
    async function fetchBloodPressureReadings() {
      try {
        if (!patientUid) {
          return;
        }

        setIsLoading(true);

        const getConditionalQueryConstraints = () => {
          const queryContraints: QueryConstraint[] = [];
          if (startTime) {
            queryContraints.push(where('readingTime', '>=', startTime));
          }
          if (endTime) {
            queryContraints.push(where('readingTime', '<=', endTime));
          }
          return queryContraints;
        };

        const q = query(
          collection(db, 'readingBloodPressures'),
          where('patientUid', '==', patientUid),
          ...getConditionalQueryConstraints(),
          orderBy('readingTime', 'desc'),
        );
        const querySnapshot = await getDocs(q);
        const bps: IReadingBloodPressure[] = [];

        querySnapshot.forEach((doc) =>
          bps.push({ id: doc.id, ...doc.data() } as IReadingBloodPressure),
        );

        if (bps.length) {
          setBloodPressureReadings(bps);
        }
      } catch (error) {
        toast({
          description: 'Error fetching blood pressure readings.',
          status: 'error',
          title: 'Uh oh!',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchBloodPressureReadings();
  }, [endTime, patientUid, startTime, toast]);

  return [bloodPressureReadings, isLoading];
}
