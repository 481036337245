import { Box, Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function QuestionsPrompt() {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" w="full" p={4} mb={8}>
      <Text
        fontWeight="bold"
        fontSize="xl"
        lineHeight={6}
        color="functional.warmGray.700"
        pb={2}
      >
        Questions?
      </Text>
      <Text pb={4} fontWeight="medium" color="warmGray.700">
        Your care team is here to support your health goals and answer your
        questions.
      </Text>
      <Button onClick={() => navigate('/messages')} variant="outlined" w="full">
        Send us a message
      </Button>
    </Box>
  );
}
