export function isInternalMarleyEmail(email: string): boolean {
  const marleyEmailDomain = '@marleymedical.com';

  if (
    Boolean(process.env.REACT_APP_PREVIEW_ENVIRONMENT) ||
    process.env.NODE_ENV === 'development'
  ) {
    return false;
  }

  return email.includes(marleyEmailDomain);
}
