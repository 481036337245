import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { db } from '../firebase';
import {
  QueryConstraint,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { IReadingBloodGlucose } from '../types';

export default function useFetchGlucoseReadings({
  patientUid,
  startDate,
  endDate,
}: {
  patientUid?: string;
  startDate?: Date;
  endDate?: Date;
}): [IReadingBloodGlucose[], boolean] {
  const [isLoading, setIsLoading] = useState(true);
  const [readings, setReadings] = useState<IReadingBloodGlucose[]>([]);
  const toast = useToast();

  useEffect(() => {
    async function fetchBloodGlucoseReadings() {
      try {
        if (!patientUid) {
          return;
        }

        setIsLoading(true);

        const getConditionalQueryConstraints = () => {
          const queryConstraints: QueryConstraint[] = [];
          if (startDate) {
            queryConstraints.push(where('readingTime', '>=', startDate));
          }
          if (endDate) {
            queryConstraints.push(where('readingTime', '<=', endDate));
          }
          return queryConstraints;
        };

        const q = query(
          collection(db, 'readingBloodGlucoses'),
          where('patientUid', '==', patientUid),
          ...getConditionalQueryConstraints(),
          orderBy('readingTime', 'desc'),
        );
        const querySnapshot = await getDocs(q);
        const bps: IReadingBloodGlucose[] = [];

        querySnapshot.forEach((doc) =>
          bps.push({ id: doc.id, ...doc.data() } as IReadingBloodGlucose),
        );

        if (bps.length) {
          setReadings(bps);
        }
      } catch (error) {
        toast({
          description: 'Error fetching blood glucose readings.',
          status: 'error',
          title: 'Uh Oh',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchBloodGlucoseReadings();
  }, [endDate, patientUid, startDate, toast]);

  return [readings, isLoading];
}
