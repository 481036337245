import React, { useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Header from './Header';
import BottomTabNav from './BottomTabNav';
import { ArrowLeftCircle } from '../../assets';

interface IProps {
  children: React.ReactNode;
  nestedPage?: boolean;
  nestedPageTitle?: string;
  onNestedPageBack?: () => void;
}

const BOTTOM_TAB_NAV_HEIGHT = 76;

export default function Layout({
  children,
  nestedPage,
  nestedPageTitle,
  onNestedPageBack,
}: IProps): JSX.Element {
  const [innerHeight, setInnerHeight] = useState<number>();

  useEffect(() => {
    function onResize() {
      setInnerHeight(window.innerHeight);
    }

    window.addEventListener('resize', () => {
      onResize();
    });

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={`${innerHeight}px`}
      overflowY="hidden"
      bg="functional.cream"
    >
      <Box overflowY="scroll" mb={BOTTOM_TAB_NAV_HEIGHT}>
        {nestedPage ? (
          <Box
            position="relative"
            alignItems="center"
            justifyContent="center"
            display="flex"
            px={4}
            py={2}
            borderBottom="1px solid"
            borderColor="gray.300"
          >
            <ArrowLeftCircle
              position="absolute"
              left={4}
              h={6}
              w={6}
              color="brand.purple"
              onClick={onNestedPageBack}
            />
            <Heading fontSize="xl" minH={7} color="warmGray.900">
              {nestedPageTitle ? nestedPageTitle : ''}
            </Heading>
          </Box>
        ) : (
          <Header />
        )}

        <Box
          pb={4}
          px={7}
          width="100%"
          maxWidth={512}
          h="inherit"
          marginX="auto"
        >
          {children}
        </Box>
      </Box>
      <BottomTabNav height={BOTTOM_TAB_NAV_HEIGHT} />
    </Box>
  );
}
