import { Box, Image, Text } from '@chakra-ui/react';
import { getPasswordRequirements } from './utils';
import circleChecked from '../../assets/circle-checked-icon.svg';
import circleDashed from '../../assets/circle-dashed-icon.svg';

interface IProps {
  password: string;
}

export default function PasswordRequirements({
  password,
}: IProps): JSX.Element {
  const {
    containsLowerCaseLetter,
    containsUpperCaseLetter,
    containsNumber,
    containsSpecialCharacter,
    longerThanTenCharacters,
    meetsCharacterRequirements,
  } = getPasswordRequirements(password);

  return (
    <Box bg="functional.cream" rounded="xl" p={4} color="gray.900">
      <Text fontWeight="bold" mb={1}>
        Your password should contain:
      </Text>

      <Box display="flex" alignItems="center">
        <Image
          src={meetsCharacterRequirements ? circleChecked : circleDashed}
          mr={2}
        />
        <Text>At least three of the following:</Text>
      </Box>

      <Box ml={7}>
        <Box display="flex" alignItems="center">
          <Image
            src={containsLowerCaseLetter ? circleChecked : circleDashed}
            mr={2}
          />
          <Text>Lowercase letters (a-z)</Text>
        </Box>

        <Box display="flex" alignItems="center">
          <Image
            src={containsUpperCaseLetter ? circleChecked : circleDashed}
            mr={2}
          />
          <Text>Uppercase letters (A-Z)</Text>
        </Box>

        <Box display="flex" alignItems="center">
          <Image src={containsNumber ? circleChecked : circleDashed} mr={2} />
          <Text>Numbers (0-9)</Text>
        </Box>

        <Box display="flex" alignItems="center">
          <Image
            src={containsSpecialCharacter ? circleChecked : circleDashed}
            mr={2}
          />
          <Text>Special characters (ex. @#$)</Text>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Image
          src={longerThanTenCharacters ? circleChecked : circleDashed}
          mr={2}
        />
        <Text>10 or more characters</Text>
      </Box>
    </Box>
  );
}
