import { IReadingBloodPressure } from '../../types';

import { Box, Text } from '@chakra-ui/react';
import ListSkeletonLoader from '../../components/ListSkeletonLoader';
import { useFetchBPReadings } from '../../hooks';
import { usePatient } from '../../contexts';
import dayjs from '../../utils/dayjs';
import { isSameDay } from '../TrackMyHealthPage/utils';
import DownloadBiometricsButton, {
  BiometricCollectionType,
} from '../TrackMyHealthPage/DownloadBiometricsButton';

export default function AllBPReadings() {
  const { patient } = usePatient();
  const [allBpReadings, isLoading] = useFetchBPReadings({
    patientUid: patient?.uid,
  });

  return (
    <>
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
        All readings
      </Text>
      <DownloadBiometricsButton type={BiometricCollectionType.BLOOD_PRESSURE} />
      {allBpReadings.map((reading: IReadingBloodPressure, i) => {
        const isFirstReading = i === 0;
        const isLastReading = i === allBpReadings.length - 1;
        const firstReadingForDay =
          isFirstReading ||
          !isSameDay(reading.readingTime, allBpReadings[i - 1].readingTime);
        const lastReadingForDay =
          isLastReading ||
          !isSameDay(reading.readingTime, allBpReadings[i + 1].readingTime);

        return (
          <Box key={reading.id}>
            {firstReadingForDay && (
              <Text
                fontSize={18}
                bgColor="white"
                color="functional.warmGray.700"
                fontWeight="semibold"
                lineHeight="28px"
                px={4}
                py={1.5}
                borderTopRadius={24}
                borderBottom="2px"
                borderColor="functional.cream"
              >
                {dayjs(reading.readingTime.toDate()).format('MMMM D')}
              </Text>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              bg="white"
              p={4}
              borderBottom={lastReadingForDay ? undefined : '2px'}
              borderColor="functional.cream"
              borderBottomRadius={lastReadingForDay ? 24 : undefined}
              mb={lastReadingForDay ? 4 : undefined}
            >
              <Text lineHeight={6} color="functional.warmGray.700">
                {dayjs(reading.readingTime.toDate()).format('h:mm A')}
              </Text>
              <Text
                lineHeight={7}
                fontSize="xl"
                fontWeight="semibold"
                color="functional.warmGray.700"
              >
                {reading.systolic}/{reading.diastolic}
              </Text>
            </Box>
          </Box>
        );
      })}
      {isLoading && <ListSkeletonLoader />}
    </>
  );
}
