import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import { PatientProvider } from './contexts';
import theme, { toastDefaultOptions } from './styles/theme';
import './styles/index';
import { ErrorBoundary, initializeSentry } from './utils';
import reportWebVitals from './reportWebVitals';

initializeSentry();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <PatientProvider>
        <BrowserRouter>
          <ChakraProvider
            theme={theme}
            toastOptions={{ defaultOptions: toastDefaultOptions }}
          >
            <App />
          </ChakraProvider>
        </BrowserRouter>
      </PatientProvider>
    </React.StrictMode>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
