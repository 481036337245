type EncodedFile = {
  encodedFile: string;
  fileName: string;
};

function encodeFileAsBase64(file: File): Promise<EncodedFile> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve({
        encodedFile: reader.result as string,
        fileName: file.name,
      });
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export async function encodeFiles(
  files: File[],
): Promise<EncodedFile[] | undefined> {
  if (!files.length) {
    return;
  }

  const encodedFiles = await Promise.all(files.map(encodeFileAsBase64));

  return encodedFiles;
}
