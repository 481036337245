import { Heading } from '@chakra-ui/react';
import { Layout } from '../components';

export default function ProfilePage(): JSX.Element {
  return (
    <Layout>
      <Heading mb={8}>My Profile</Heading>
    </Layout>
  );
}
