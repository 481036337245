import { Box, Button, Link, Text } from '@chakra-ui/react';
import { Globe, ExternalLink } from '../../assets';
import { isAppointmentComplete } from '../../utils';
import { Events, mixpanel } from '../../analytics';
import { Appointment } from '../../types';

interface Props {
  appointment: Appointment;
}

export default function JoinAppointmentButton({
  appointment,
}: Props): JSX.Element {
  const appointmentComplete = isAppointmentComplete(appointment);
  const { canceled } = appointment;
  const url = getAppointmentUrl(appointment);

  if (appointmentComplete) {
    return (
      <Text color="brand.red" textAlign="center" py={4}>
        This appointment has been completed.
      </Text>
    );
  }

  function handleOnClick() {
    mixpanel.track(Events.APPOINTMENT_URL_VIEWED);
  }

  return (
    <Box>
      <Box
        alignItems="center"
        color="functional.warmGray.500"
        display="flex"
        gap={2}
        mb={2}
      >
        <Globe />
        <Text>Virtual</Text>
      </Box>

      <Text color="functional.warmGray.500" fontSize="sm" mb={4}>
        Your appointment will take place online. You can join it by clicking the
        button below.
      </Text>

      <Button
        as={Link}
        leftIcon={<ExternalLink />}
        w="full"
        variant="primary"
        isDisabled={canceled || !url}
        href={url}
        target="_blank"
        onClick={handleOnClick}
      >
        Join Appointment
      </Button>
    </Box>
  );
}

function getAppointmentUrl(appointment: Appointment): string | undefined {
  const { canceled, location } = appointment;

  if (canceled) {
    return undefined;
  }

  const [, url] = location.split(' ');

  return url;
}
