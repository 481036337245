import { useMemo } from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';
import { Layout, QuestionsPrompt } from '../../components';
import { usePatient } from '../../contexts';
import { useNavigate } from 'react-router-dom';
import HealthSummaryCardItem from './HealthSummaryCardItem';
import HealthSummaryCard from './HealthSummaryCard';
import HealthAllReadingsCard from './HealthAllReadingsCard';
import { useFetchBPReadings } from '../../hooks';
import { getBPSummaryMetrics, getPatientWeightMetrics } from './utils';
import { dayjs, isPatientDeactivated } from '../../utils';
import GlucoseSummary from './GlucoseSummary';
import { BloodGlucose } from '../../assets';
import bpCuff from '../../assets/bp-cuff.svg';
import bpCuffCircle from '../../assets/bp-cuff-circle.svg';
import scale from '../../assets/scale.svg';
import scaleCircle from '../../assets/scale-circle.svg';

export default function TrackMyHealthPage(): JSX.Element {
  const navigate = useNavigate();
  const { patient } = usePatient();
  const patientDeactivated = isPatientDeactivated(patient);
  const twoWeeksAgoTimestamp = useMemo(
    () => dayjs().subtract(2, 'week').toDate(),
    [],
  );
  const [twoWeekBPReadings, isLoading] = useFetchBPReadings({
    patientUid: patient?.uid,
    startTime: twoWeeksAgoTimestamp,
  });
  const { firstBP, lastBP, readingsAvg } = getBPSummaryMetrics({
    patient,
    bpReadings: twoWeekBPReadings,
  });
  const { firstWeight, lastWeight, weightDelta } =
    getPatientWeightMetrics(patient);

  const enrolledInWeightProgram = patient?.programs.weight.enrolled;
  const enrolledInHypertensionProgram = patient?.programs.hypertension.enrolled;
  const enrolledInGlucoseProgram = patient?.programs.diabetes.enrolled;
  const firstBPText = firstBP
    ? `${firstBP.systolic}/${firstBP.diastolic}`
    : '—/—';
  const lastBPText = lastBP ? `${lastBP.systolic}/${lastBP.diastolic}` : '—/—';

  const readingsAvgText = readingsAvg
    ? `${readingsAvg.systolic}/${readingsAvg.diastolic}`
    : '—/—';

  return (
    <Layout>
      <Heading lineHeight={10} fontSize="4xl" py={8} color="warmGray.900">
        Track your health progress
      </Heading>
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
        Summary
      </Text>
      <Box pb={6}>
        {enrolledInHypertensionProgram && (
          <HealthSummaryCard
            title="BP TREND"
            icon={<Image src={bpCuff} />}
            onClick={() => navigate('/track-my-bp')}
          >
            <Box display="flex" justifyContent="space-between">
              <HealthSummaryCardItem title="First BP" text={firstBPText} />
              <HealthSummaryCardItem
                title="Avg. now"
                text={readingsAvgText}
                isLoading={isLoading}
              />
              <HealthSummaryCardItem title="Last BP" text={lastBPText} />
            </Box>
          </HealthSummaryCard>
        )}
        {enrolledInWeightProgram && (
          <HealthSummaryCard
            title="WEIGHT PROGRESSION"
            icon={<Image src={scale} />}
            onClick={() => navigate('/track-my-weight')}
          >
            <Box display="flex" justifyContent="space-between">
              <HealthSummaryCardItem title="First weight" text={firstWeight} />
              <HealthSummaryCardItem title="Last weight" text={lastWeight} />
              <HealthSummaryCardItem title="Weight change" text={weightDelta} />
            </Box>
          </HealthSummaryCard>
        )}

        {enrolledInGlucoseProgram && (
          <GlucoseSummary
            onClick={(path) => navigate(path)}
            patient={patient}
          />
        )}
      </Box>
      <Box pb={6}>
        <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
          All Readings
        </Text>

        <Box
          border="2px solid"
          borderColor="functional.warmGray.100"
          borderRadius="xl"
          bg="white"
        >
          {enrolledInHypertensionProgram && (
            <HealthAllReadingsCard
              onClick={() => navigate('/track-my-bp')}
              icon={<Image h={12} src={bpCuffCircle} />}
              title="Blood pressures"
              borderBottom={
                enrolledInWeightProgram || enrolledInGlucoseProgram
                  ? '2px solid'
                  : undefined
              }
            />
          )}

          {enrolledInWeightProgram && (
            <HealthAllReadingsCard
              icon={<Image h={12} src={scaleCircle} />}
              title="Weight"
              onClick={() => navigate('/track-my-weight')}
              borderBottom={enrolledInGlucoseProgram ? '2px solid' : undefined}
            />
          )}

          {enrolledInGlucoseProgram && (
            <HealthAllReadingsCard
              icon={
                <Box
                  alignItems="center"
                  bg="brand.sky"
                  display="flex"
                  h={12}
                  justifyContent="center"
                  rounded="full"
                  w={12}
                >
                  <BloodGlucose color="brand.purple" h={7} w={7} />
                </Box>
              }
              title="Glucose"
              onClick={() => navigate('/track-my-glucose')}
            />
          )}
        </Box>
      </Box>

      {!patientDeactivated && <QuestionsPrompt />}
    </Layout>
  );
}
