import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import HealthSummaryCard from './HealthSummaryCard';
import HealthSummaryCardItem from './HealthSummaryCardItem';
import { useFetchGlucoseReadings } from '../../hooks';
import { BloodGlucose } from '../../assets';
import { dayjs } from '../../utils';
import { IPatient } from '../../types';

interface Props {
  onClick: (path: string) => void;
  patient: IPatient;
}

export default function GlucoseSummary({
  onClick,
  patient,
}: Props): JSX.Element {
  const twoWeeksAgoDate = useMemo(
    () => dayjs().subtract(14, 'days').toDate(),
    [],
  );
  const [readings, isLoading] = useFetchGlucoseReadings({
    patientUid: patient.uid,
    startDate: twoWeeksAgoDate,
  });
  const twoWeekAverage = useMemo(() => {
    if (readings.length === 0) {
      return null;
    }

    const sum = readings.reduce((memo, reading) => memo + reading.value, 0);
    return Math.floor(sum / readings.length);
  }, [readings]);

  const { firstReadingBloodGlucose, lastReadingBloodGlucose } = patient;

  return (
    <HealthSummaryCard
      title="GLUCOSE"
      icon={<BloodGlucose />}
      onClick={() => onClick('/track-my-glucose')}
    >
      <Box display="flex" justifyContent="space-between">
        <HealthSummaryCardItem
          title="First reading"
          text={firstReadingBloodGlucose?.value || '—'}
        />
        <HealthSummaryCardItem
          title="Avg. now"
          text={twoWeekAverage || '—'}
          isLoading={isLoading}
        />
        <HealthSummaryCardItem
          title="Last reading"
          text={lastReadingBloodGlucose?.value || '—'}
        />
      </Box>
    </HealthSummaryCard>
  );
}
