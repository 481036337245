import { Icon, IconProps } from '@chakra-ui/react';

export default function Cash(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M34 26.25H4C3.66848 26.25 3.35054 26.1183 3.11612 25.8839C2.8817 25.6495 2.75 25.3315 2.75 25C2.75 24.6685 2.8817 24.3505 3.11612 24.1161C3.35054 23.8817 3.66848 23.75 4 23.75H34C34.3315 23.75 34.6495 23.8817 34.8839 24.1161C35.1183 24.3505 35.25 24.6685 35.25 25C35.25 25.3315 35.1183 25.6495 34.8839 25.8839C34.6495 26.1183 34.3315 26.25 34 26.25Z"
          fill="#5F5B52"
        />
        <path
          d="M31.5 30H6.5C6.16848 30 5.85054 29.8683 5.61612 29.6339C5.3817 29.3995 5.25 29.0815 5.25 28.75C5.25 28.4185 5.3817 28.1005 5.61612 27.8661C5.85054 27.6317 6.16848 27.5 6.5 27.5H31.5C31.8315 27.5 32.1495 27.6317 32.3839 27.8661C32.6183 28.1005 32.75 28.4185 32.75 28.75C32.75 29.0815 32.6183 29.3995 32.3839 29.6339C32.1495 29.8683 31.8315 30 31.5 30Z"
          fill="#5F5B52"
        />
        <path
          d="M1.5 16.25H0.25V20C0.25 20.663 0.513392 21.2989 0.982233 21.7678C1.45107 22.2366 2.08696 22.5 2.75 22.5H6.5V21.25C6.49855 19.9244 5.9713 18.6534 5.03393 17.7161C4.09657 16.7787 2.82564 16.2514 1.5 16.25Z"
          fill="#5F5B52"
        />
        <path
          d="M36.5 13.75H37.75V8.75H36.5C34.5116 8.74773 32.6052 7.95682 31.1992 6.55079C29.7932 5.14476 29.0023 3.23843 29 1.25V0H9V1.25C8.99773 3.23843 8.20682 5.14476 6.80079 6.55079C5.39476 7.95682 3.48843 8.74773 1.5 8.75H0.25V13.75C0.640625 13.75 1.05781 13.75 1.5 13.75C3.48843 13.7523 5.39476 14.5432 6.80079 15.9492C8.20682 17.3552 8.99773 19.2616 9 21.25V22.5H29V21.25C29.0023 19.2616 29.7932 17.3552 31.1992 15.9492C32.6052 14.5432 34.5116 13.7523 36.5 13.75ZM19 18.75C17.5166 18.75 16.0666 18.3101 14.8332 17.486C13.5999 16.6619 12.6386 15.4906 12.0709 14.1201C11.5032 12.7497 11.3547 11.2417 11.6441 9.78682C11.9335 8.33197 12.6478 6.99559 13.6967 5.9467C14.7456 4.89781 16.082 4.1835 17.5368 3.89411C18.9917 3.60472 20.4997 3.75325 21.8701 4.3209C23.2406 4.88856 24.4119 5.84985 25.236 7.08322C26.0601 8.31659 26.5 9.76664 26.5 11.25C26.4977 13.2384 25.7068 15.1448 24.3008 16.5508C22.8948 17.9568 20.9884 18.7477 19 18.75Z"
          fill="#5F5B52"
        />
        <path
          d="M19 16.25C21.7614 16.25 24 14.0114 24 11.25C24 8.48858 21.7614 6.25 19 6.25C16.2386 6.25 14 8.48858 14 11.25C14 14.0114 16.2386 16.25 19 16.25Z"
          fill="#5F5B52"
        />
        <path
          d="M31.5 21.25V22.5H35.25C35.913 22.5 36.5489 22.2366 37.0178 21.7678C37.4866 21.2989 37.75 20.663 37.75 20V16.25H36.5C35.1744 16.2514 33.9034 16.7787 32.9661 17.7161C32.0287 18.6534 31.5014 19.9244 31.5 21.25Z"
          fill="#5F5B52"
        />
        <path
          d="M36.5 6.25H37.75V2.5C37.75 1.83696 37.4866 1.20107 37.0178 0.732233C36.5489 0.263392 35.913 0 35.25 0H31.5V1.25C31.5014 2.57564 32.0287 3.84657 32.9661 4.78393C33.9034 5.7213 35.1744 6.24855 36.5 6.25Z"
          fill="#5F5B52"
        />
        <path
          d="M6.5 1.25V0H2.75C2.08696 0 1.45107 0.263392 0.982233 0.732233C0.513392 1.20107 0.25 1.83696 0.25 2.5V6.25H1.5C2.82564 6.24855 4.09657 5.7213 5.03393 4.78393C5.9713 3.84657 6.49855 2.57564 6.5 1.25Z"
          fill="#5F5B52"
        />
      </svg>
    </Icon>
  );
}
