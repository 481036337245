import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import { ArrowLeft } from '../assets';

interface IProps {
  children: React.ReactNode;
  showBackButton: boolean;
  title: string;
}

export default function Page({
  children,
  showBackButton,
  title,
}: IProps): JSX.Element {
  const [innerHeight, setInnerHeight] = useState<number>();
  const navigate = useNavigate();

  useEffect(() => {
    function onResize() {
      setInnerHeight(window.innerHeight);
    }

    window.addEventListener('resize', () => {
      onResize();
    });

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Box
      bg="white"
      display="grid"
      gridTemplateRows="min-content auto"
      height={`${innerHeight}px`}
    >
      <Box
        display="grid"
        alignItems="center"
        px={4}
        py={2}
        gridTemplateColumns="1fr auto 1fr"
      >
        <Box>
          {showBackButton && (
            <Box
              bg="brand.purple"
              h={8}
              w={8}
              rounded="2xl"
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowLeft color="white" />
            </Box>
          )}
        </Box>
        <Heading size="md">{title}</Heading>
        <Box></Box>
      </Box>

      <Box>
        <Box maxWidth={512} margin="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
