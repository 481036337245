import { Box, Text } from '@chakra-ui/react';
import checkmarkCircle from '../../assets/checkmark-circle.svg';
import { DOCUMENT_ORDER } from '.';

interface IProps {
  onClick?: () => void;
  isComplete: boolean;
}

export default function DocumentChecklist({
  onClick,
  isComplete,
}: IProps): JSX.Element {
  return (
    <Box
      onClick={onClick}
      border="2px solid"
      borderColor="functional.warmGray.100"
      rounded="lg"
    >
      {DOCUMENT_ORDER.map((documentName, index) => {
        const isLastItem = index === DOCUMENT_ORDER.length - 1;
        return (
          <Box
            key={documentName}
            borderBottom={!isLastItem ? '2px solid' : undefined}
            borderColor="functional.warmGray.100"
            p={3}
            display="flex"
            alignItems="center"
          >
            <Box
              bgImg={isComplete ? checkmarkCircle : undefined}
              backgroundPosition="center"
              h={5}
              w={5}
              rounded="xl"
              border={isComplete ? '' : '2px solid'}
              borderColor="warmGray.500"
              mr={2}
            />
            <Text fontSize="lg" fontWeight="medium">
              {documentName}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
}
