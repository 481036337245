import { Box, Text } from '@chakra-ui/react';
import { IPatient } from '../../../../types';

interface Props {
  patient: IPatient;
}

export default function ReadingsOverview({ patient }: Props): JSX.Element {
  const { firstReadingBloodGlucose, lastReadingBloodGlucose } = patient;

  return (
    <>
      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          First Reading
        </Text>
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {firstReadingBloodGlucose ? firstReadingBloodGlucose.value : '—'}
        </Text>
      </Box>

      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          Last Reading
        </Text>
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {lastReadingBloodGlucose ? lastReadingBloodGlucose.value : '—'}
        </Text>
      </Box>

      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          Total Change
        </Text>
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {getTotalChange(patient)}
        </Text>
      </Box>
    </>
  );
}

function getTotalChange(patient: IPatient): number | string {
  const { firstReadingBloodGlucose, lastReadingBloodGlucose } = patient;

  if (!firstReadingBloodGlucose || !lastReadingBloodGlucose) {
    return '—';
  }

  const totalChange =
    lastReadingBloodGlucose.value - firstReadingBloodGlucose.value;

  return Math.round(totalChange);
}
