import * as Sentry from '@sentry/react';

interface IProps {
  children: React.ReactNode;
}

function ErrorFallback(): JSX.Element {
  return (
    <div
      style={{
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>
        <h2>Uh Oh.</h2>
        <p>An unknown error occurred. Sorry about that.</p>
      </div>
    </div>
  );
}

export default function ErrorBoundary({ children }: IProps): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
