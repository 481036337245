import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from '@chakra-ui/react';
import SMSReminders from './SMSReminders';
import { usePatient } from '../../../contexts';
import { functions } from '../../../firebase';
import { Bell } from '../../../assets';
import { CommunicationPreference } from '../../../types';
import { Events, mixpanel } from '../../../analytics';
import { captureException } from '../../../utils';

export default function Notifications(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { patient } = usePatient();
  const [communicationPreference, setCommunicationPreference] =
    useState<CommunicationPreference | undefined>();
  const toast = useToast();

  useEffect(() => {
    if (patient) {
      setCommunicationPreference(
        patient.communicationPreference || CommunicationPreference.SMS,
      );
    }
  }, [patient]);

  function handleClick(value: CommunicationPreference) {
    setCommunicationPreference(value);
  }

  async function handleSave() {
    setIsLoading(true);

    const updatePatientRecord = httpsCallable(functions, 'updatePatient');

    try {
      await updatePatientRecord({
        communicationPreference,
      });

      mixpanel.track(Events.SETTINGS_UPDATED, {
        communication_preference: communicationPreference!,
      });

      toast({
        description: 'Successfully updated your communication preferences.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred while updating your communication  preferences.',
        status: 'error',
        title: 'Uh Oh',
      });
      captureException(error, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={4}
        p={4}
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        <Bell fontSize="4xl" />
        <Text>Tell us how and when you prefer us to communicate with you.</Text>
      </Box>

      <Box px={4} py={6}>
        <Text textTransform="uppercase" fontWeight="bold" color="gray.600">
          Message notifications
        </Text>
        <Text fontWeight="medium" mb={4}>
          Tell us how you prefer to receive new message notifications when our
          care team sends new messages.
        </Text>

        <RadioGroup mb={4} value={communicationPreference}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            cursor="pointer"
            onClick={() => handleClick(CommunicationPreference.EMAIL)}
          >
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                Email
              </Text>
              <Text>Get notifications by email.</Text>
            </Box>
            <Radio
              colorScheme="purple"
              size="lg"
              value={CommunicationPreference.EMAIL}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            onClick={() => handleClick(CommunicationPreference.SMS)}
          >
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                SMS
              </Text>
              <Text>Get notifications by SMS text.</Text>
            </Box>
            <Radio
              colorScheme="purple"
              size="lg"
              value={CommunicationPreference.SMS}
            />
          </Box>
        </RadioGroup>

        <Button
          variant="primary"
          w="100%"
          onClick={handleSave}
          isLoading={isLoading}
        >
          Save
        </Button>
      </Box>

      {patient && <SMSReminders patient={patient} />}
    </>
  );
}
