import { Avatar, Box, Text } from '@chakra-ui/react';
import type { Message as MessageResource } from '@source-health/client';
import Attachment from './Attachment';
import dayjs from '../../../utils/dayjs';

interface IProps {
  message: MessageResource;
}

export default function Message({ message }: IProps): JSX.Element {
  const { attachments, sender, sent_at: sentAt, text } = message;
  const {
    // @ts-ignore
    first_name: firstName,
    // @ts-ignore
    last_name: lastName,
    // @ts-ignore
    object,
    // @ts-ignore
    profile_image: profileImage,
  } = sender;
  const { url } = profileImage || {};
  const isMemberMessage = object === 'member';
  const senderName = isMemberMessage ? 'You' : `${firstName} ${lastName}`;
  const formattedSentAt = dayjs(sentAt).format('MMM D [at] h:mma');

  return (
    <Box mb={6}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isMemberMessage ? 'flex-end' : 'flex-start'}
      >
        <Box
          bg={isMemberMessage ? '#EDF7FF' : '#FFF'}
          p={3}
          mb={2}
          rounded="md"
          shadow="sm"
          width="90%"
          whiteSpace="pre-wrap"
        >
          {text}
        </Box>

        {attachments.map((attachment) => (
          <Attachment
            key={attachment.url}
            attachment={attachment}
            isMemberAttachment={isMemberMessage}
          />
        ))}

        <Box
          display="flex"
          gap={2}
          alignItems="center"
          color="blackAlpha.700"
          fontSize="sm"
        >
          {!isMemberMessage && <Avatar size="xs" src={url} />}

          <Text fontWeight="bold">{senderName}</Text>

          <Text>{formattedSentAt}</Text>
        </Box>
      </Box>
    </Box>
  );
}
