import { Box, Button, Link, Text } from '@chakra-ui/react';
import { ExternalLink } from '../../assets';
import { isAppointmentComplete } from '../../utils';
import { Events, mixpanel } from '../../analytics';
import { Appointment } from '../../types';

interface Props {
  appointment: Appointment;
}

export default function RescheduleAppointment({
  appointment,
}: Props): JSX.Element | null {
  const { confirmationPage } = appointment;
  const appointmentComplete = isAppointmentComplete(appointment);

  if (appointmentComplete || !confirmationPage) {
    return null;
  }

  function handleOnClick() {
    mixpanel.track(Events.APPOINTMENT_CONFIRMATION_PAGE_VIEWED);
  }

  return (
    <Box>
      <Text fontWeight="bold" fontSize="2xl" mb={4}>
        Reschedule
      </Text>

      <Box
        bg="white"
        border="2px solid"
        borderColor="functional.warmGray.100"
        p={4}
        rounded="lg"
      >
        <Text color="functional.warmGray.500" fontSize="sm" mb={4}>
          If you need to reschedule your appointment, you can do so by clicking
          the button below.
        </Text>

        <Button
          as={Link}
          leftIcon={<ExternalLink />}
          w="full"
          variant="outlined"
          href={confirmationPage}
          target="_blank"
          onClick={handleOnClick}
        >
          Reschedule Appointment
        </Button>
      </Box>
    </Box>
  );
}
