import { Box, Image, Text, Link } from '@chakra-ui/react';
import logo from '../../../assets/marley-logo-purple.svg';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../../constants';

interface Props {
  email: string;
}

export default function Success({ email }: Props): JSX.Element {
  return (
    <Box
      bg="white"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxW={512} p={4} textAlign="center">
        <Image
          src={logo}
          alt="Marley Medical Logo"
          mb="72px"
          h="72px"
          w="72px"
        />

        <Box textAlign="left" mb={6}>
          <Box
            color="brand.purple"
            mb={2}
            fontSize={36}
            display="flex"
            flexWrap="wrap"
            alignItems="baseline"
            lineHeight="normal"
          >
            <Text fontFamily="Reckless" mr={2}>
              Check
            </Text>
            <Text fontWeight="bold">your email</Text>
          </Box>
          <Text>
            We sent an email to {email}. Click the link to access your account.
          </Text>
        </Box>

        <Box textAlign="center" fontSize="sm" fontWeight="light" mt={3}>
          <Text>If you have questions, please call us at</Text>
          <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
            {MARLEY_PATIENT_PHONE_NUMBER}
          </Link>
          .
        </Box>
      </Box>
    </Box>
  );
}
