import { useState } from 'react';
import axios from 'axios';
import { Button, useToast } from '@chakra-ui/react';
import { captureException, getFirebaseURL } from '../../utils';
import { Events, mixpanel } from '../../analytics';
import { auth } from '../../firebase';

export enum BiometricCollectionType {
  BLOOD_GLUCOSE = 'readingBloodGlucoses',
  BLOOD_PRESSURE = 'readingBloodPressures',
  WEIGHT = 'readingWeights',
}

function getBiometricFileText(biometricType: BiometricCollectionType) {
  switch (biometricType) {
    case BiometricCollectionType.BLOOD_GLUCOSE:
      return 'blood-glucose';
    case BiometricCollectionType.BLOOD_PRESSURE:
      return 'blood-pressure';
    case BiometricCollectionType.WEIGHT:
      return 'weight';
    default:
      return 'biometric';
  }
}

const exportNameByBiometricType: Record<
  BiometricCollectionType,
  | 'All Blood Pressure Readings'
  | 'All Weight Readings'
  | 'All Blood Glucose Readings'
> = {
  [BiometricCollectionType.BLOOD_GLUCOSE]: 'All Blood Glucose Readings',
  [BiometricCollectionType.BLOOD_PRESSURE]: 'All Blood Pressure Readings',
  [BiometricCollectionType.WEIGHT]: 'All Weight Readings',
};

export default function DownloadBiometricsButton({
  type,
}: {
  type: BiometricCollectionType;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  async function handleDownloadBiometrics() {
    setIsLoading(true);

    try {
      const url = getFirebaseURL('downloadPatientBiometrics');
      const token = await auth.currentUser?.getIdToken();
      const { data } = await axios.post(
        url,
        {
          biometricType: type,
        },
        {
          headers: {
            'firebase-auth-token': token,
          },
        },
      );
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = `${getBiometricFileText(type)}-data.csv`;
      link.click();

      mixpanel.track(Events.DATA_EXPORTED, {
        export_name: exportNameByBiometricType[type],
      });
    } catch (error) {
      captureException(error, 'error');
      toast({
        description: 'An error occurred downloading your biometric data.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      isLoading={isLoading}
      onClick={handleDownloadBiometrics}
      variant="outlined"
      w="full"
      mb={4}
    >
      Download as CSV file
    </Button>
  );
}
