import { Box, Image, Text } from '@chakra-ui/react';
import { Check } from '../../../assets';
import marleyLogo from '../../../assets/marley-logo-purple.svg';

const planDetails = [
  'Dedicated care team',
  'Cellular-connected devices for monitoring',
  'Unlimited 24/7 messaging with your care team',
  'Scheduled video visits',
  'Evidence-based recommendations',
  'Check-ins to keep on track of your goals',
];

export default function MembershipCard(): JSX.Element {
  return (
    <Box bg="functional.cream" p={6} rounded="xl">
      <Box mb={2} pb={6} borderBottom="1px solid" borderColor="gray.200">
        <Box alignItems="center" display="flex" gap={2}>
          <Image src={marleyLogo} h={14} />
          <Text
            color="gray.600"
            fontSize="xl"
            fontWeight="bold"
            lineHeight="1.3"
          >
            Monthly <br /> Membership
          </Text>
        </Box>
      </Box>

      <Box>
        <Text fontWeight="medium" mb={2}>
          Plan includes
        </Text>

        {planDetails.map((detail) => (
          <Box alignItems="center" display="flex" gap={2} key={detail} mb={0.5}>
            <Check color="green.600" fontSize="lg" />
            <Text fontSize="sm">{detail}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
