import { extendTheme, UseToastOptions } from '@chakra-ui/react';
import { checkboxTheme, buttonTheme } from './variants';

export const toastDefaultOptions: UseToastOptions = {
  isClosable: true,
  position: 'top-right',
  variant: 'subtle',
};

const theme = extendTheme({
  colors: {
    brand: {
      purple: '#5A38B2',
      purpleDisabled: '#5A38B27A',
      purpleActive: '#341F66',
      darkPurple: '#472B8C',
      sky: '#C9E5FC',
      lightSky: '#EDF7FF',
      red: '#FF4E1E',
      redDisabled: '#FF4E1E7A',
      redActive: '#A63313',
      redHover: '#CC3E18',
      peach: '#FFDFD6',
      lightPeach: '#FFF5F5',
    },
    functional: {
      cream: '#FDF7EF',
      warmGray: {
        100: '#DDD7CF',
        300: '#9E9890',
        500: '#5F5B52',
        700: '#3F3B33',
        900: '#2A2820',
      },
      neutralGray: {
        200: '#E9ECF0',
        300: '#D5DAE0',
        400: '#B1B7C0',
        500: '#848B96',
      },
    },
  },
  fonts: {
    heading: `'Greycliff', 'Poppins', sans-serif`,
    body: `'Greycliff', 'Poppins', sans-serif`,
  },
  components: { Button: buttonTheme, Checkbox: checkboxTheme },
});

export default theme;
