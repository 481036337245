import { Box, Image, Text } from '@chakra-ui/react';
import { useGetPatientPrograms } from '../utils';
import { dayjs } from '../../../utils';
import { IPatient } from '../../../types';
import { BloodGlucose } from '../../../assets';
import bpCuff from '../../../assets/bp-cuff.svg';
import scale from '../../../assets/scale.svg';

const programIcons: Record<string, JSX.Element> = {
  hypertension: <Image src={bpCuff} />,
  diabetes: <BloodGlucose color="functional.warmGray.500" />,
  weight: <Image src={scale} />,
};

interface Props {
  patient: IPatient;
}

export default function Programs({ patient }: Props): JSX.Element {
  const patientPrograms = useGetPatientPrograms(patient);

  return (
    <Box>
      <Text
        color="functional.warmGray.500"
        fontSize="xs"
        fontWeight="semibold"
        mb={2}
        textTransform="uppercase"
      >
        Programs
      </Text>

      <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
        {patientPrograms.map(({ enrolledOn, name }) => (
          <Box key={name}>
            <Box
              alignItems="center"
              color="black"
              display="flex"
              gap={2}
              mb={1}
            >
              {programIcons[name]}
              <Text fontWeight="semibold" textTransform="capitalize">
                {name}
              </Text>
            </Box>

            <Text color="functional.warmGray.500" fontSize="xs">
              Enrolled on: {dayjs(enrolledOn.toDate()).format('MM-DD-YYYY')}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
