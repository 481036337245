import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Button, useToast } from '@chakra-ui/react';
import { captureException } from '../../../utils';
import { Download } from '../../../assets';
import { IPatient } from '../../../types';
import { functions } from '../../../firebase';

interface Props {
  patient: IPatient;
}

export default function DownloadPatientDataButton({
  patient,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  async function handleOnClick() {
    setIsLoading(true);

    try {
      const getPatientDataExportLink = httpsCallable<
        { patientUid: string },
        { url: string }
      >(functions, 'getPatientDataExportLink');

      const response = await getPatientDataExportLink({
        patientUid: patient.uid,
      });

      const { url } = response.data;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'patient-data.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast({
        title: 'Success',
        description: 'Your patient data has been downloaded.',
        status: 'success',
      });
    } catch (error) {
      console.error(error);
      captureException(error, 'critical');
      toast({
        title: 'Uh Oh',
        description: 'Failed to download patient data. Please try again.',
        status: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      leftIcon={<Download />}
      w="100%"
      variant="outlined"
      onClick={handleOnClick}
      isLoading={isLoading}
    >
      Download Your Data
    </Button>
  );
}
