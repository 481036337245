import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Box, Button, Image, Link, Text, useToast } from '@chakra-ui/react';
import { Events, mixpanel } from '../../analytics';
import { auth } from '../../firebase';
import logo from '../../assets/marley-logo-purple.svg';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../constants';

interface IProps {
  email: string;
}

export default function EmailResetSuccess({ email }: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const toast = useToast();

  async function handleResend() {
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);

      mixpanel.track(Events.PASSWORD_RESET_RESEND_REQUESTED);

      setShowSuccess(true);
      setIsLoading(false);
    } catch (error) {
      toast({
        description:
          'An error occurred resetting your password. Please try again.',
        duration: 10000,
        status: 'error',
        title: 'Uh Oh.',
      });
      setIsLoading(false);
    }
  }

  return (
    <Box
      bg="white"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxW={512} p={4} textAlign="center">
        <Image
          src={logo}
          alt="Marley Medical Logo"
          mb="72px"
          h="72px"
          w="72px"
        />

        <Box textAlign="left" mb={6}>
          <Box
            color="brand.purple"
            mb={2}
            fontSize={36}
            display="flex"
            flexWrap="wrap"
            alignItems="baseline"
            lineHeight="normal"
          >
            <Text fontFamily="Reckless" mr={2}>
              Check
            </Text>
            <Text fontWeight="bold">your email</Text>
          </Box>
          <Text>
            We sent an email to {email}. Click the link to set your password.
          </Text>
        </Box>

        {showSuccess ? (
          <Text color="green.500">Password reset email sent.</Text>
        ) : (
          <Button
            onClick={handleResend}
            isLoading={isLoading}
            color="gray.900"
            border="2px solid"
            borderColor="gray.100"
            bg="none"
            w="100%"
            rounded="3xl"
            _hover={{
              bg: 'gray.100',
            }}
          >
            Resend link
          </Button>
        )}

        <Box textAlign="center" fontSize="sm" fontWeight="light" mt={3}>
          <Text>If you have questions, please call us at</Text>
          <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
            {MARLEY_PATIENT_PHONE_NUMBER}
          </Link>
          .
        </Box>
      </Box>
    </Box>
  );
}
