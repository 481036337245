import { Box, Text } from '@chakra-ui/react';

export enum SelectedTab {
  ALL_MESSAGES = 'All Messages',
  RECENT_MESSAGES = 'Recent Messages',
}

interface IProps {
  selectedTab: SelectedTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<SelectedTab>>;
}

export default function MessagesTabs({ selectedTab, setSelectedTab }: IProps) {
  return (
    <Box
      cursor="pointer"
      display="flex"
      gap={4}
      fontSize="xl"
      fontWeight="bold"
      lineHeight={7}
      mb={6}
    >
      <Text
        onClick={() => setSelectedTab(SelectedTab.RECENT_MESSAGES)}
        pb={2}
        color={
          selectedTab === SelectedTab.RECENT_MESSAGES
            ? 'brand.darkPurple'
            : 'functional.warmGray.700'
        }
        borderBottom={
          selectedTab === SelectedTab.RECENT_MESSAGES ? '2px' : undefined
        }
        borderBottomColor="brand.purple"
      >
        {SelectedTab.RECENT_MESSAGES}
      </Text>
      <Text
        onClick={() => setSelectedTab(SelectedTab.ALL_MESSAGES)}
        pb={2}
        color={
          selectedTab === SelectedTab.ALL_MESSAGES
            ? 'brand.darkPurple'
            : 'functional.warmGray.700'
        }
        borderBottom={
          selectedTab === SelectedTab.ALL_MESSAGES ? '2px' : undefined
        }
        borderBottomColor="brand.purple"
      >
        {SelectedTab.ALL_MESSAGES}
      </Text>
    </Box>
  );
}
