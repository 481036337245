import { Box, Skeleton, Text } from '@chakra-ui/react';

interface IProps {
  isLoading?: boolean;
  title: string;
  text: string | number;
}

export default function HealthSummaryCardItem({
  isLoading = false,
  title,
  text,
}: IProps) {
  return (
    <Box>
      <Text fontWeight="medium" color="functional.warmGray.500">
        {title}
      </Text>
      <Skeleton isLoaded={!isLoading}>
        <Text fontSize="lg" fontWeight="bold" color="functional.warmGray.700">
          {text}
        </Text>
      </Skeleton>
    </Box>
  );
}
