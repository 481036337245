import { Box, Text } from '@chakra-ui/react';
import { Layout } from '../../components';
import BpTile from './BpTile';
import { usePatient } from '../../contexts';

import AllBPReadings from './AllBPReadings';
import { useNavigate } from 'react-router-dom';
import { useFetchBPReadings } from '../../hooks';
import formatReadingTimestamp, {
  getBPSummaryMetrics,
} from '../TrackMyHealthPage/utils';
import { useMemo } from 'react';
import dayjs from '../../utils/dayjs';

export default function TrackMyBpPage(): JSX.Element {
  const navigate = useNavigate();
  const { patient } = usePatient();
  const twoWeeksAgoTimestamp = useMemo(
    () => dayjs().subtract(2, 'week').toDate(),
    [],
  );
  const [twoWeekBPReadings, isLoading] = useFetchBPReadings({
    patientUid: patient?.uid,
    startTime: twoWeeksAgoTimestamp,
  });
  const { firstBP, lastBP, readingsAvg, readingsCount, bpGoal } =
    getBPSummaryMetrics({
      patient,
      bpReadings: twoWeekBPReadings,
    });

  return (
    <Layout
      nestedPage
      nestedPageTitle="Blood Pressure Readings"
      onNestedPageBack={() => navigate('/track-my-health')}
    >
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pt={4} pb={4}>
        Summary
      </Text>
      <Box pb={4}>
        <Box display="flex" justifyContent="center" flexWrap="wrap" mr={-4}>
          <BpTile
            isLoading={isLoading}
            title="First BP"
            description={formatReadingTimestamp(firstBP?.readingTime)}
            systolic={firstBP?.systolic}
            diastolic={firstBP?.diastolic}
          />
          <BpTile
            isLoading={isLoading}
            title="Avg. Now"
            tooltipMessage="Average is calculated based on readings from the past two weeks."
            description={
              readingsAvg
                ? `from ${readingsCount} reading${
                    readingsCount === 1 ? '' : 's'
                  }`
                : 'No recent BP data'
            }
            systolic={readingsAvg?.systolic}
            diastolic={readingsAvg?.diastolic}
          />
          <BpTile
            isLoading={isLoading}
            title="Last BP"
            description={formatReadingTimestamp(lastBP?.readingTime)}
            systolic={lastBP?.systolic}
            diastolic={lastBP?.diastolic}
          />
          <BpTile
            isLoading={isLoading}
            title="BP Goal"
            systolic={bpGoal?.systolic}
            diastolic={bpGoal?.diastolic}
          />
        </Box>
      </Box>
      <AllBPReadings />
    </Layout>
  );
}
