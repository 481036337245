import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Spinner,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Events, mixpanel } from '../../../../analytics';
import { IPatient } from '../../../../types';
import { functions } from '../../../../firebase';

interface Props {
  patient: IPatient;
}

export default function AutoCollectionSwitch({ patient }: Props): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { billing } = patient;
  const toast = useToast();

  useEffect(() => {
    setIsChecked(Boolean(billing.autoCollection));
  }, [billing.autoCollection]);

  async function handleChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    setIsLoading(true);

    const updateAutoCollection = httpsCallable<{
      patientUid: string;
      autoCollection: boolean;
    }>(functions, 'updateStripeAutoCollection');

    try {
      await updateAutoCollection({
        patientUid: patient.uid,
        autoCollection: currentTarget.checked,
      });

      setIsChecked(currentTarget.checked);

      mixpanel.track(Events.SETTINGS_UPDATED, {
        auto_pay_enabled: currentTarget.checked,
      });

      toast({
        title: 'Success',
        description: 'Auto-pay settings updated successfully.',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'Failed to update auto-pay settings. Please try again.',
        status: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FormControl>
      <FormLabel>Payment Auto-Pay</FormLabel>
      <Box display="flex" alignItems="center" gap={2}>
        <Switch
          isDisabled={isLoading}
          isChecked={isChecked}
          onChange={handleChange}
          colorScheme="purple"
        />
        {isLoading ? <Spinner size="sm" color="brand.purple" /> : null}
        {isChecked && !isLoading && (
          <Text color="gray.500" fontSize="sm">
            Auto-pay is enabled.
          </Text>
        )}
      </Box>
      <FormHelperText>
        Enroll in auto-pay to automatically pay future bills with your card. You
        can unenroll at any time.
      </FormHelperText>
    </FormControl>
  );
}
