const PROVIDER_NAME_BY_ID: Record<string, string> = {
  // production
  fFXmEZY5DQUOxfchyASZ3yzep6F2: 'Amanda Simone-Belin',
  xSq0vHXty4RBN7kw1EcBvCHeeiq1: 'Chelsea Biel',
  bCB5Mu9NpRVq3i2NcpxOEQXLwk53: 'Rachel Bennett',
  hitBVAyTEcZhJ9hT2RuY2FWFkDH3: 'Rebecca Liggon',
  // development
  '3tpBCtwDeLWKUO8FT1ZqqSrPAzw2': 'Amanda Simone-Belin',
  ok3f9KlYktbCQno9RNQfZCvnXAv2: 'Chelsea Biel',
  qmQ6cYmZLLTfAMjf79V9MRZb6uD2: 'Rachel Bennett',
  iNK17anxZFcKfO5Wr7hYOca6RlG3: 'Rebecca Liggon',
};

export default function getProviderNameById(id: string): string | undefined {
  const providerName = PROVIDER_NAME_BY_ID[id];

  return providerName || undefined;
}
