import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Heading,
} from '@chakra-ui/react';
import { ArrowLeft } from '../assets';

interface IProps {
  children: React.ReactNode;
  header?: string;
  isOpen: boolean;
  onClose: () => void;
  size?: string;
}

export default function Drawer({
  children,
  header,
  isOpen,
  onClose,
  size,
}: IProps): JSX.Element {
  return (
    <ChakraDrawer isOpen={isOpen} onClose={onClose} size={size}>
      <DrawerOverlay />
      <DrawerContent>
        <Box
          display="grid"
          alignItems="center"
          px={4}
          py={2}
          gridTemplateColumns="1fr auto 1fr"
        >
          <Box>
            <Box
              bg="brand.purple"
              h={8}
              w={8}
              rounded="2xl"
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={onClose}
            >
              <ArrowLeft color="white" />
            </Box>
          </Box>
          <Heading size="md">{header}</Heading>
          <Box></Box>
        </Box>

        <DrawerBody maxW={512} m="auto">
          {children}
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
}
