import { Box, Text } from '@chakra-ui/react';
import { IPatient } from '../../../../types';

interface Props {
  patient: IPatient;
}

export default function ReadingsOverview({ patient }: Props): JSX.Element {
  const { firstReadingWeight, lastReadingWeight } = patient;

  return (
    <>
      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          First Reading
        </Text>
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {firstReadingWeight ? `${firstReadingWeight.value} lbs` : '—'}
        </Text>
      </Box>

      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          Last Reading
        </Text>
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {lastReadingWeight ? `${lastReadingWeight.value} lbs` : '—'}
        </Text>
      </Box>

      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          Total Change
        </Text>
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {getTotalWeightChange(patient)}
        </Text>
      </Box>
    </>
  );
}

function getTotalWeightChange(patient: IPatient): string {
  const { firstReadingWeight, lastReadingWeight } = patient;

  if (!firstReadingWeight || !lastReadingWeight) {
    return '—';
  }

  const totalChange = Math.round(
    lastReadingWeight.value - firstReadingWeight.value,
  );

  return `${totalChange} lbs`;
}
