import { Box, Text, ChakraProps, Image } from '@chakra-ui/react';
import rightArrowIcon from '../../assets/right-arrow-icon.svg';

interface HealthAllReadingsCardProps extends ChakraProps {
  icon: React.ReactElement;
  title: string;
  onClick: () => void;
}

export default function HealthAllReadingsCard({
  icon,
  title,
  onClick,
  ...rest
}: HealthAllReadingsCardProps) {
  return (
    <Box
      p={4}
      onClick={onClick}
      {...rest}
      borderColor="functional.warmGray.100"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={3}>
          {icon}
          <Text fontWeight="bold" color="functional.warmGray.500">
            {title}
          </Text>
        </Box>
        <Image h={4} src={rightArrowIcon} />
      </Box>
    </Box>
  );
}
