import { useEffect, useState } from 'react';
import { Button, Link, useToast } from '@chakra-ui/react';
import { createStripeCustomerPortalUrl } from './utils';
import { Events, mixpanel } from '../../analytics';
import { usePatient } from '../../contexts';
import { ExternalLink } from '../../assets';

interface Props {
  buttonText: string;
  variant?: string;
}

export default function PayNowButton({
  buttonText,
  variant = 'primary',
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [href, setHref] = useState<string | undefined>();
  const { patient } = usePatient();
  const toast = useToast();

  async function getStripeUrl(): Promise<string> {
    const url = await createStripeCustomerPortalUrl();

    return url;
  }

  useEffect(() => {
    async function getHref() {
      if (!patient) {
        return;
      }

      try {
        const url = await getStripeUrl();
        setHref(url);

        setIsLoading(false);
      } catch (error) {
        toast({
          description:
            'An error occurred fetching your payment information. Please try again.',
          status: 'error',
          title: 'Uh Oh',
        });

        setIsLoading(false);
      }
    }

    getHref();
  }, [patient, toast]);

  function handleOnPayNowClick(event: React.SyntheticEvent) {
    mixpanel.track(Events.PAY_NOW_CLICKED);
  }

  return (
    <Button
      onClick={handleOnPayNowClick}
      variant={variant}
      rightIcon={<ExternalLink />}
      as={Link}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      isLoading={isLoading}
    >
      {buttonText}
    </Button>
  );
}
