import { Box, Text } from '@chakra-ui/react';
import { Events, mixpanel } from '../../analytics';

export enum AppointmentTabs {
  UPCOMING = 'Upcoming',
  COMPLETED = 'Completed',
}

interface Props {
  onChange: (tab: AppointmentTabs) => void;
  selectedTab: AppointmentTabs;
}

export default function Tabs({ onChange, selectedTab }: Props): JSX.Element {
  function handleOnChange(tab: AppointmentTabs) {
    if (tab === AppointmentTabs.COMPLETED) {
      mixpanel.track(Events.COMPLETED_APPOINTMENTS_VIEWED);
    }

    onChange(tab);
  }

  return (
    <Box
      cursor="pointer"
      display="flex"
      gap={4}
      fontSize="xl"
      fontWeight="bold"
      lineHeight={7}
      mb={6}
    >
      <Text
        onClick={() => handleOnChange(AppointmentTabs.UPCOMING)}
        pb={2}
        color={
          selectedTab === AppointmentTabs.UPCOMING
            ? 'brand.darkPurple'
            : 'functional.warmGray.700'
        }
        borderBottom={
          selectedTab === AppointmentTabs.UPCOMING ? '2px' : undefined
        }
        borderBottomColor="brand.purple"
      >
        {AppointmentTabs.UPCOMING}
      </Text>

      <Text
        onClick={() => handleOnChange(AppointmentTabs.COMPLETED)}
        pb={2}
        color={
          selectedTab === AppointmentTabs.COMPLETED
            ? 'brand.darkPurple'
            : 'functional.warmGray.700'
        }
        borderBottom={
          selectedTab === AppointmentTabs.COMPLETED ? '2px' : undefined
        }
        borderBottomColor="brand.purple"
      >
        {AppointmentTabs.COMPLETED}
      </Text>
    </Box>
  );
}
