import {
  Box,
  Button,
  Heading,
  Image,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Drawer, PayNowButton } from '../';
import { usePatient } from '../../contexts';
import { Events, mixpanel } from '../../analytics';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../constants';
import { personHoldingCreditCard } from '../../assets';

export default function PayBillButton(): JSX.Element | null {
  const { patient } = usePatient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const patientBalance = patient?.billing?.balance;

  if (!patientBalance || patientBalance === 0) {
    return null;
  }

  function handleOnDrawerOpen() {
    mixpanel.track(Events.PAY_BILL_VIEWED, {
      balance: patientBalance!,
    });
    onOpen();
  }

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size="full">
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          textAlign="center"
        >
          <Heading mb={8} fontWeight="medium" size="lg">
            It&apos;s time to pay your bill.
          </Heading>

          <Image
            alt="Person holding gigantic credit card"
            mb={8}
            src={personHoldingCreditCard}
          />

          <Text color="red" fontWeight="bold" mb={3} fontSize="lg">
            Total Balance Due: ${patientBalance}
          </Text>

          <Text mb={3} size="lg">
            Your Marley Medical bill is due. Click the Pay Now button below to
            pay via our secure payment portal.
          </Text>

          <Text mb={3} size="lg">
            Already paid? Thanks! This screen will update tomorrow morning to
            reflect your payment.
          </Text>

          <Text mb={10} size="lg">
            Need help? Call us at{' '}
            <Link
              color="blue.600"
              textDecoration="underline"
              href={`tel:+1${MARLEY_PATIENT_PHONE_NUMBER}`}
            >
              {MARLEY_PATIENT_PHONE_NUMBER}
            </Link>
            .
          </Text>

          <PayNowButton buttonText="Pay Now" />
        </Box>
      </Drawer>

      <Button
        color="red"
        size="xs"
        variant="outlined"
        onClick={handleOnDrawerOpen}
      >
        Pay bill
      </Button>
    </>
  );
}
