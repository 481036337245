import { Box, Text, Image, useToast, Avatar, Button } from '@chakra-ui/react';
import type {
  Source,
  Thread as ThreadResource,
  User,
} from '@source-health/client';
import { useEffect, useState } from 'react';
import { isThreadUnread, useSourceClient } from '../utils';
import MessageNotification from '../../../assets/message-notification.svg';
import { usePatient } from '../../../contexts';
import { dayjs, isPatientDeactivated } from '../../../utils';
import { IPatient } from '../../../types';

interface IProps {
  onThreadSelected?: any;
  thread: ThreadResource;
  isFirstItem: boolean;
  isLastItem: boolean;
}

function getClinicianNameForAvatar(clinician: User | null) {
  if (clinician?.first_name) {
    return clinician.first_name + clinician.last_name
      ? ` ${clinician.last_name}`
      : '';
  }
  return;
}

function getPatientNameForAvatar(patient: IPatient | null) {
  if (patient?.firstName) {
    return patient.firstName + (patient.lastName ? ` ${patient.lastName}` : '');
  }
  return;
}

export default function ThreadListItem({
  onThreadSelected,
  thread,
  isFirstItem,
  isLastItem,
}: IProps): JSX.Element {
  const [source, sourceLoading] = useSourceClient();
  const toast = useToast();
  const [clinician, setClinician] = useState<User | null>(null);
  const [clinicianLoading, setClinicianLoading] = useState(false);
  const { patient } = usePatient();
  const patientDeactivated = isPatientDeactivated(patient);
  const {
    last_message: lastMessage,
    member_last_read: memberLastRead,
    subject,
  } = thread;
  const { sent_at: sentAt, text, direction, sender } = lastMessage;
  const isClinicianMessage = direction === 'outbound';
  const senderName = !isClinicianMessage ? 'You' : clinician?.first_name ?? '';
  const sent = dayjs(sentAt).format('MMM D');
  const isUnread = isThreadUnread(
    memberLastRead ? new Date(memberLastRead) : null,
    new Date(sentAt),
  );
  const avatarName = isClinicianMessage
    ? getClinicianNameForAvatar(clinician)
    : getPatientNameForAvatar(patient);

  function handleClick() {
    onThreadSelected(thread);
  }

  function renderAvatar() {
    if (clinicianLoading) {
      return (
        // don't display anything (just blank placeholder) since loading time is quick
        <Box h={16} w={16} />
      );
    }

    return (
      <Box position="relative">
        {isUnread && (
          <Box zIndex="5" position="absolute" top={0} right={0}>
            <Image src={MessageNotification} />
          </Box>
        )}
        {/* @ts-ignore: User type isn't expecting expanded profile_image obj */}
        {clinician?.profile_image?.url ? (
          <Image
            // @ts-ignore
            src={clinician.profile_image.url}
            alt="clinician avatar"
            borderRadius={32}
            boxSize={16}
            objectFit="cover"
          />
        ) : (
          // if clinician doesn't have an avatar or message is from patient, display fallback avatar w/ initials
          <Avatar name={avatarName} bg="brand.sky" h={16} w={16} />
        )}
      </Box>
    );
  }

  useEffect(() => {
    async function getClinicianDetails(clinicianId: string, source: Source) {
      try {
        setClinicianLoading(true);
        const clinician = await source.users.retrieve(
          `${clinicianId}?expand=profile_image`,
        );
        setClinician(clinician);
      } catch (e) {
        toast({
          description: 'An error occurred when fetching clinician details.',
          status: 'error',
          title: 'Uh Oh',
        });
      } finally {
        setClinicianLoading(false);
      }
    }
    // fetch avatar and name if last_message sender is a clinician
    if (!sourceLoading && source && isClinicianMessage) {
      getClinicianDetails(sender as string, source);
    }
  }, [direction, toast, sender, source, sourceLoading, isClinicianMessage]);

  return (
    <Box
      cursor="pointer"
      borderTopRadius={isFirstItem ? 'md' : undefined}
      bg={isUnread ? 'white' : undefined}
      p={4}
      borderBottom={!isLastItem ? '1px' : undefined}
      borderColor="functional.warmGray.100"
      borderBottomRadius={isLastItem ? 'md' : undefined}
      onClick={handleClick}
      display="flex"
    >
      <Box display="flex" alignItems="center">
        {renderAvatar()}
      </Box>

      <Box flex={1} ml={3}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Text
            whiteSpace="pre-wrap"
            noOfLines={1}
            fontWeight="semibold"
            color="functional.warmGray.900"
          >
            {subject}
          </Text>

          <Text
            ml={1}
            whiteSpace="nowrap"
            fontWeight="medium"
            color="functional.warmGray.500"
            fontSize="sm"
          >
            {sent}
          </Text>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Text
            color="functional.warmGray.900"
            fontSize="sm"
            whiteSpace="pre-wrap"
            noOfLines={2}
          >
            <Text as="span" fontWeight="bold">
              {senderName ? `${senderName}: ` : ''}
            </Text>
            {text}
          </Text>

          {!patientDeactivated && (
            <Button
              size="xs"
              variant={isUnread ? 'primary' : 'outlined'}
              flexShrink={0}
            >
              Reply
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
