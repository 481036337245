import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import Programs from './Programs';
import { dayjs } from '../../../utils';
import { IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function PatientOverview({ patient }: Props): JSX.Element {
  const { dateOfBirth, firstName, lastName } = patient;

  const fullName = `${firstName} ${lastName}`;
  const dob = useMemo(
    () => dayjs(dateOfBirth).format('MM-DD-YYYY'),
    [dateOfBirth],
  );

  return (
    <Box>
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
        Patient Overview
      </Text>

      <Box
        bg="white"
        p={4}
        borderRadius="xl"
        border="2px solid"
        borderColor="functional.warmGray.100"
        mb={4}
      >
        <Box display="grid" gap={1} gridTemplateColumns="1fr 1fr" mb={4}>
          <Box>
            <Text
              color="functional.warmGray.500"
              fontSize="xs"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              Name
            </Text>
            <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
              {fullName}
            </Text>
          </Box>

          <Box>
            <Text
              color="functional.warmGray.500"
              fontSize="xs"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              Date of Birth
            </Text>
            <Text color="functional.warmGray.700" fontWeight="bold">
              {dob}
            </Text>
          </Box>
        </Box>

        <Programs patient={patient} />
      </Box>
    </Box>
  );
}
