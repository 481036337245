import {
  Box,
  Skeleton,
  SkeletonText,
  Text,
  Tooltip,
  Image,
} from '@chakra-ui/react';
import helpCircleIcon from '../../assets/help-circle.svg';

interface Props {
  description?: string;
  isLoading?: boolean;
  title: string;
  tooltipMessage?: string;
  value?: number | string | null;
}

export default function DataCard({
  description,
  isLoading = false,
  title,
  tooltipMessage,
  value,
}: Props) {
  return (
    <Box p={4} bg="white" rounded="3xl">
      <Skeleton
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        isLoaded={!isLoading}
        pb={4}
      >
        <Text
          fontWeight="bold"
          color="warmGray.500"
          lineHeight={6}
          letterSpacing={0.75}
        >
          {title.toUpperCase()}
        </Text>

        <Box>
          {tooltipMessage && (
            <Tooltip label={tooltipMessage} hasArrow rounded="md" size="lg">
              <Image src={helpCircleIcon} />
            </Tooltip>
          )}
        </Box>
      </Skeleton>

      <SkeletonText
        isLoaded={!isLoading}
        color={value ? 'blackAlpha.900' : 'warmGray.500'}
        fontWeight="bold"
        fontSize={value ? '4xl' : '2xl'}
        lineHeight={10}
        pb={4}
      >
        {value || '—'}
      </SkeletonText>

      {description && (
        <Text fontWeight="medium" lineHeight={6} color="warmGray.500">
          {description}
        </Text>
      )}
    </Box>
  );
}
