import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { DataCard } from '../../components';
import { useFetchGlucoseReadings } from '../../hooks';
import { dayjs } from '../../utils';
import { IPatient } from '../../types';
import formatReadingTimestamp from '../TrackMyHealthPage/utils';

interface Props {
  patient: IPatient;
}

export default function Summary({ patient }: Props): JSX.Element {
  const { firstReadingBloodGlucose, lastReadingBloodGlucose } = patient;
  const twoWeeksAgoDate = useMemo(
    () => dayjs().subtract(14, 'days').toDate(),
    [],
  );
  const [readings, isLoading] = useFetchGlucoseReadings({
    patientUid: patient.uid,
    startDate: twoWeeksAgoDate,
  });
  const twoWeekAverage = useMemo(() => {
    if (readings.length === 0) {
      return null;
    }

    const sum = readings.reduce((memo, reading) => memo + reading.value, 0);
    return Math.floor(sum / readings.length);
  }, [readings]);

  return (
    <>
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pt={4} pb={4}>
        Summary
      </Text>

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
        <DataCard
          description={formatReadingTimestamp(
            firstReadingBloodGlucose?.readingTime,
          )}
          title="First Reading"
          value={firstReadingBloodGlucose?.value}
        />

        <DataCard
          description={formatReadingTimestamp(
            lastReadingBloodGlucose?.readingTime,
          )}
          title="Last Reading"
          value={lastReadingBloodGlucose?.value}
        />

        <DataCard
          description={`From ${readings.length} reading${
            readings.length === 1 ? '' : 's'
          }`}
          isLoading={isLoading}
          title="Avg. Now"
          value={twoWeekAverage}
        />
      </Box>
    </>
  );
}
