import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Spinner } from '@chakra-ui/react';
import AppointmentDetails from './AppointmentDetails';
import RescheduleAppointment from './RescheduleAppointment';
import { Layout, QuestionsPrompt } from '../../components';
import { useFetchAppointment } from '../../hooks';
import { Events, mixpanel } from '../../analytics';

export default function AppointmentPage(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [appointment, isLoading] = useFetchAppointment(id);

  useEffect(() => {
    mixpanel.track(Events.APPOINTMENT_VIEWED);
  }, []);

  return (
    <Layout
      nestedPage
      nestedPageTitle="Appointment"
      onNestedPageBack={() => navigate('/appointments')}
    >
      <Box pt={4}>
        {isLoading && (
          <Box display="flex" justifyContent="center">
            <Spinner size="lg" color="brand.purple" />
          </Box>
        )}

        <Box mb={8}>
          {appointment && (
            <>
              <Box mb={8}>
                <AppointmentDetails appointment={appointment} />
              </Box>
              <RescheduleAppointment appointment={appointment} />
            </>
          )}
        </Box>

        <QuestionsPrompt />
      </Box>
    </Layout>
  );
}
