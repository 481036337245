import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const lg = defineStyle({
  fontSize: 'lg',
  height: 12,
  py: '10px',
  px: 6,
});

const sm = defineStyle({
  fontSize: 'sm',
  height: '32px',
  px: 4,
});

const xs = defineStyle({
  fontSize: 'xs',
  height: '30px',
  px: 3,
});

const primary = defineStyle({
  bg: 'brand.purple',
  color: 'white',
  rounded: '3xl',
  _hover: {
    bg: 'brand.darkPurple',
    _disabled: {
      bg: 'brand.darkPurple',
    },
  },
  _active: {
    bg: 'brand.purpleActive',
  },
  _disabled: {
    opacity: 0.48,
  },
});

const outlined = defineStyle({
  bg: 'white',
  border: '2px solid',
  borderColor: 'functional.warmGray.100',
  rounded: '3xl',
  _hover: {
    bg: 'functional.warmGray.100',
  },
  _active: {
    borderColor: 'functional.warmGray.300',
    bg: 'functional.warmGray.300',
  },
  _disabled: {
    opacity: 0.48,
  },
});

const alert = defineStyle({
  bg: 'brand.red',
  color: 'white',
  rounded: '3xl',
  _hover: {
    bg: 'brand.redActive',
  },
  _active: {
    bg: 'brand.redActive',
  },
  _disabled: {
    opacity: 0.48,
  },
});

const minimal = defineStyle({
  bg: 'transparent',
  textDecoration: 'underline',
  rounded: '3xl',
  _hover: {
    bg: 'functional.warmGray.100',
  },
  _active: {
    borderColor: 'functional.warmGray.300',
    bg: 'functional.warmGray.300',
  },
  _disabled: {
    opacity: 0.48,
  },
});

const buttonTheme = defineStyleConfig({
  sizes: {
    xs,
    sm,
    lg,
  },
  variants: { alert, minimal, outlined, primary },
});

export default buttonTheme;
