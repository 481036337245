import { Box, Text } from '@chakra-ui/react';
import Balance from './Balance';
import Billing from './Billing';
import MembershipCard from './MembershipCard';
import { BillingType, SubscriptionType } from '../../../types';
import { usePatient } from '../../../contexts';

export default function Account(): JSX.Element {
  const { patient } = usePatient();
  const { billing } = patient!;
  const { balance, subscriptionType, type } = billing;
  const showBalance = balance > 0;
  const showBilling =
    type === BillingType.SELF_PAY &&
    (subscriptionType === SubscriptionType.STANDARD ||
      subscriptionType === SubscriptionType.FINANCIAL_AID);

  return (
    <Box>
      {showBalance && (
        <Box mb={8}>
          <Balance balance={balance} />
        </Box>
      )}

      <Text textTransform="uppercase" fontWeight="bold" color="gray.600" mb={2}>
        Your Plan
      </Text>

      <Box mb={6}>
        <MembershipCard />
      </Box>

      {showBilling && <Billing patient={patient!} />}
    </Box>
  );
}
