import { Box, Text } from '@chakra-ui/react';
import { PayNowButton } from '../../../components';

interface IProps {
  balance?: number;
}

export default function Balance({ balance }: IProps): JSX.Element {
  return (
    <Box
      alignItems="center"
      border="2px solid"
      borderColor="brand.red"
      bg="brand.peach"
      display="flex"
      justifyContent="space-evenly"
      p={4}
      rounded="xl"
    >
      <Box textAlign="center">
        <Text fontSize="lg" fontWeight="bold" lineHeight={1}>
          Balance
        </Text>
        <Text color="brand.red" fontSize="4xl" fontWeight="bold" lineHeight={1}>
          ${balance}
        </Text>
      </Box>

      <PayNowButton buttonText="Pay my bill" variant="alert" />
    </Box>
  );
}
