import { useMemo } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useFetchBPReadings } from '../../../../hooks';
import { getBPSummaryMetrics } from '../../../TrackMyHealthPage/utils';
import { IPatient } from '../../../../types';

interface Props {
  patient: IPatient;
}

export default function ReadingsOverview({ patient }: Props): JSX.Element {
  const [readings, isLoading] = useFetchBPReadings({
    patientUid: patient.uid,
  });

  const difference = useMemo(
    () => getFirstLastReadingsDifference(patient),
    [patient],
  );
  const { firstBP, lastBP } = getBPSummaryMetrics({
    patient,
    bpReadings: readings,
  });

  return (
    <>
      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          First Reading
        </Text>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <Spinner color="purple" />
          </Box>
        ) : (
          <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
            {firstBP ? `${firstBP.systolic} / ${firstBP.diastolic}` : '—/—'}
          </Text>
        )}
      </Box>

      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          Last Reading
        </Text>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <Spinner color="purple" />
          </Box>
        ) : (
          <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
            {lastBP ? `${lastBP.systolic} / ${lastBP.diastolic}` : '—/—'}
          </Text>
        )}
      </Box>

      <Box>
        <Text
          color="functional.warmGray.500"
          fontSize="xs"
          fontWeight="semibold"
          mb={1}
          textTransform="uppercase"
        >
          Total Change
        </Text>
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {difference ?? '—'}
        </Text>
      </Box>
    </>
  );
}

function getFirstLastReadingsDifference({
  firstReadingBloodPressure,
  lastReadingBloodPressure,
}: IPatient): string | null {
  if (!firstReadingBloodPressure || !lastReadingBloodPressure) return null;

  const { systolic: firstSystolic, diastolic: firstDiastolic } =
    firstReadingBloodPressure;
  const { systolic: lastSystolic, diastolic: lastDiastolic } =
    lastReadingBloodPressure;

  return `${lastSystolic - firstSystolic} / ${lastDiastolic - firstDiastolic}`;
}
