import { Appointment } from '../types';

export default function isAppointmentComplete(
  appointment: Appointment,
): boolean {
  const { completed, datetime } = appointment;

  if (completed) {
    return true;
  }

  const now = new Date();
  const appointmentDate = new Date(datetime);

  return now > appointmentDate;
}
