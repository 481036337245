import { useEffect, useState } from 'react';
import { Box, Spinner, Switch, Text, useToast } from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import { IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function SMSReminders({ patient }: Props): JSX.Element | null {
  const [smsReminderEnabled, setSmsReminderEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    if (patient.hasDailyReadingReminders) {
      setSmsReminderEnabled(true);
    }
  }, [patient]);

  async function handleOnChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    setIsLoading(true);

    const { checked } = currentTarget;

    const updatePatientNotifications = httpsCallable<{
      hasDailyReadingReminders: boolean;
    }>(functions, 'updatePatientNotifications');

    try {
      await updatePatientNotifications({
        hasDailyReadingReminders: checked,
      });

      setSmsReminderEnabled(currentTarget.checked);

      toast({
        description: 'Successfully updated your SMS reminders.',
        status: 'success',
        title: 'Success',
      });
    } catch (err) {
      toast({
        description: 'An error occurred while updating your SMS reminders.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box px={4} py={6}>
      <Text textTransform="uppercase" fontWeight="bold" color="gray.600" mb={4}>
        SMS Reminders
      </Text>

      <Box display="flex" gap={4} justifyContent="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Blood Pressure Reading
          </Text>
          <Text fontSize="sm">
            Receive a daily SMS message reminding you to take a reading.
          </Text>
        </Box>
        <Box minWidth="50px">
          {isLoading ? (
            <Spinner color="brand.purple" />
          ) : (
            <Switch
              colorScheme="green"
              size="lg"
              isChecked={smsReminderEnabled}
              onChange={handleOnChange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
