import '@source-health/elements-react/dist/elements-react.css';
import './styles.css';
import { Box, Spinner, useToast, Text, Button } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import type {
  Source,
  ThreadListResponse,
  Thread as ThreadResource,
} from '@source-health/client';
import { SourceElements } from '@source-health/elements-react';
import { useSourceClient } from './utils';
import { usePatient } from '../../contexts';
import { Layout } from '../../components';
import CreateThreadButton from './CreateThreadButton';
import Thread from './Thread';
import ThreadList from './ThreadList';
import PatientGreeting from './PatientGreeting';
import MessagesTabs, { SelectedTab } from './MessagesTabs';
import { captureException } from '../../utils';
import { Link } from 'react-router-dom';

export default function MessagesPage() {
  const toast = useToast();
  const { patient } = usePatient();
  const [selectedThread, setSelectedThread] =
    useState<ThreadResource | undefined>(undefined);
  const [threads, setThreads] = useState<ThreadResource[]>([]);
  const [threadsLoading, setThreadsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(SelectedTab.RECENT_MESSAGES);
  const [source, sourceLoading] = useSourceClient();

  function clearSelectedThread() {
    setSelectedThread(undefined);
  }

  useEffect(() => {
    async function getPatientThreads({
      source,
      sourceId,
    }: {
      source: Source;
      sourceId: string;
    }) {
      const threads = [];
      let hasMore = true;
      let startingAfter;

      // TECH-2232 explicitly always reset state and load
      // b/c navigation from a create to back arrow makes
      // it look like the message did not save for patients
      // with a lot of threads/messages taking a long bind time
      setThreads([]);
      setThreadsLoading(true);

      while (hasMore === true) {
        try {
          const { data, has_more }: ThreadListResponse =
            await source.communications.threads.list({
              member: sourceId,
              limit: 100,
              starting_after: startingAfter,
            });

          hasMore = has_more;

          if (data.length) {
            startingAfter = data[data.length - 1].id;
            threads.push(...data);
          }
        } catch (error) {
          hasMore = false;
          toast({
            description: 'An error occurred when fetching threads.',
            status: 'error',
            title: 'Uh Oh',
          });
          setThreadsLoading(false);
          captureException(error, 'critical');
        }
      }

      // sort and store threads based on time of last message sent
      const sortedThreads = threads.sort((a, b) =>
        new Date(a.last_message.sent_at) > new Date(b.last_message.sent_at)
          ? -1
          : 1,
      );
      setThreads(sortedThreads);
      setThreadsLoading(false);
    }

    if (!sourceLoading && source && patient?.sourceId) {
      getPatientThreads({
        source,
        sourceId: patient.sourceId,
      });
    }
  }, [source, patient, toast, sourceLoading]);

  return (
    <Layout>
      <Box display="flex" flexDirection="column" h="inherit">
        <PatientGreeting />

        <Button as={Link} mb={4} variant="primary" to="/summary">
          View Your Patient Summary
        </Button>

        {sourceLoading ? (
          <Box
            h="inherit"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="lg" color="brand.purple" />
          </Box>
        ) : source ? (
          // @ts-ignore
          <SourceElements client={source}>
            {selectedThread ? (
              <Thread onClose={clearSelectedThread} thread={selectedThread} />
            ) : (
              <Box display="flex" flexDirection="column">
                {patient && (
                  <CreateThreadButton
                    onSubmit={setSelectedThread}
                    patient={patient}
                  />
                )}

                <MessagesTabs
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />

                {threadsLoading ? (
                  <Box alignSelf="center">
                    <Spinner mt={4} size="lg" color="brand.purple" />
                  </Box>
                ) : (
                  <ThreadList
                    selectedTab={selectedTab}
                    threads={threads}
                    onThreadSelected={(thread) => setSelectedThread(thread)}
                  />
                )}
              </Box>
            )}
          </SourceElements>
        ) : (
          <Text mt={8}>Unable to load source messaging data.</Text>
        )}
      </Box>
    </Layout>
  );
}
