const developmentFirebaseURL =
  'http://127.0.0.1:5001/marley-medical-patient-ui-dev/us-central1';
const stagingFirebaseURL =
  'https://us-central1-marley-medical-patient-ui-dev.cloudfunctions.net';
const productionFirebaseURL =
  'https://us-central1-marley-medical-patient-ui.cloudfunctions.net';

export default function getFirebaseURL(path: string): string {
  if (process.env.NODE_ENV === 'development') {
    return `${developmentFirebaseURL}/${path}`;
  }

  if (Boolean(process.env.REACT_APP_PREVIEW_ENVIRONMENT)) {
    return `${stagingFirebaseURL}/${path}`;
  }

  return `${productionFirebaseURL}/${path}`;
}
