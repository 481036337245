import { Timestamp } from 'firebase/firestore';
import {
  IBloodPressureSummaryMetrics,
  IBloodPressureReading,
  IPatient,
  IReadingBloodPressure,
} from '../../types';
import dayjs from '../../utils/dayjs';

export function isSameDay(
  timestamp1: Timestamp,
  timestamp2: Timestamp,
): boolean {
  return dayjs(timestamp1.toDate()).isSame(timestamp2.toDate(), 'day');
}

export default function formatReadingTimestamp(timestamp?: Timestamp) {
  if (!timestamp) {
    return;
  }
  return `on ${dayjs(timestamp.toDate()).format('MMM D, YYYY')}`;
}

export function calculateAverageBloodPressure(
  readings: IReadingBloodPressure[],
): IBloodPressureReading | null {
  if (readings.length === 0) {
    return null;
  }

  const sumOfBPReadings = readings.reduce(
    (memo, reading) => {
      return {
        systolic: memo.systolic + reading.systolic,
        diastolic: memo.diastolic + reading.diastolic,
      };
    },
    {
      systolic: 0,
      diastolic: 0,
    },
  );

  return {
    systolic: Math.round(sumOfBPReadings.systolic / readings.length),
    diastolic: Math.round(sumOfBPReadings.diastolic / readings.length),
  };
}

export function getBPSummaryMetrics({
  patient,
  bpReadings,
}: {
  patient: IPatient | null;
  bpReadings: IReadingBloodPressure[];
}) {
  const {
    firstReadingBloodPressure,
    lastReadingBloodPressure,
    bloodPressureGoals,
  } = patient || {};

  return {
    firstBP: firstReadingBloodPressure,
    lastBP: lastReadingBloodPressure,
    readingsAvg: calculateAverageBloodPressure(bpReadings),
    readingsCount: bpReadings.length,
    bpGoal: bloodPressureGoals?.[0] ?? null,
  } as IBloodPressureSummaryMetrics;
}

export function getPatientWeightMetrics(patient: IPatient | null) {
  const { firstReadingWeight, lastReadingWeight } = patient || {};

  const firstWeight = firstReadingWeight
    ? Math.round(firstReadingWeight.value)
    : null;
  const lastWeight = lastReadingWeight
    ? Math.round(lastReadingWeight.value)
    : null;

  const firstWeightStat = firstWeight ? firstWeight + ' lbs' : '-';
  const lastWeightStat = lastWeight ? lastWeight + ' lbs' : '-';

  const weightDeltaValue = calculateTotalWeightDelta({
    firstWeight,
    lastWeight,
  });
  const weightDeltaStat = formatWeightDeltaStat(weightDeltaValue);
  const weightDeltaPercentStat = calculateWeightDeltaPercentStat({
    firstWeight,
    lastWeight,
  });

  const weightDeltaDescription = formatWeightDeltaDescription({
    firstWeightDate: firstReadingWeight?.readingTime.toDate(),
    lastWeightDate: lastReadingWeight?.readingTime.toDate(),
  });

  return {
    firstWeight: firstWeightStat,
    firstWeightTime: firstReadingWeight?.readingTime,
    lastWeight: lastWeightStat,
    lastWeightTime: lastReadingWeight?.readingTime,
    weightDelta: weightDeltaStat,
    weightDeltaPercent: weightDeltaPercentStat,
    weightDeltaDescription,
  };
}

export function calculateTotalWeightDelta({
  firstWeight,
  lastWeight,
}: {
  firstWeight: number | null;
  lastWeight: number | null;
}) {
  if (!firstWeight || !lastWeight) {
    return null;
  }
  // positive int will indicate weight gain, negative int will indicate weight loss
  return lastWeight - firstWeight;
}

export function calculateWeightDeltaPercentStat({
  firstWeight,
  lastWeight,
}: {
  firstWeight: number | null;
  lastWeight: number | null;
}) {
  const weightDelta = calculateTotalWeightDelta({
    firstWeight,
    lastWeight,
  });
  if (weightDelta === null) {
    return '-';
  }

  const percent = Math.round((weightDelta / firstWeight!) * 100);

  if (percent > 0) {
    return `+${percent}%`;
  }

  return `${percent}%`;
}

export function formatWeightDeltaStat(stat: number | null) {
  if (stat === null) return '-';

  if (stat > 0) {
    return `+${stat} lbs`;
  }
  return `${stat} lbs`;
}

export function formatWeightDeltaDescription({
  firstWeightDate,
  lastWeightDate,
}: {
  firstWeightDate?: Date;
  lastWeightDate?: Date;
}) {
  if (!firstWeightDate || !lastWeightDate) {
    return undefined;
  }

  const timeDiff = dayjs.duration(dayjs(lastWeightDate).diff(firstWeightDate));
  const yearDiff = timeDiff.years();
  const monthDiff = timeDiff.months();
  const dayDiff = timeDiff.days();

  const yearMetricExists = yearDiff > 0;
  const monthMetricExists = monthDiff > 0;
  const dayMetricExists = dayDiff > 0;
  const showYearAndMonthMetrics = yearMetricExists && monthMetricExists;
  // don't show day metric is year and month are both already displayed
  const showDayMetric = dayMetricExists && !showYearAndMonthMetrics;

  if (!(yearMetricExists || monthMetricExists || dayMetricExists)) {
    return 'Over 0 days';
  }

  // to keep subtext succinct, if > a year, only years/months or years/days will display, otherwise months/days will display
  return `Over ${
    yearMetricExists ? `${yearDiff} year${yearDiff === 1 ? '' : 's'}` : ''
  }${
    monthMetricExists
      ? `${
          // only prefix month with 'and' if displaying only years + months
          showYearAndMonthMetrics ? ' & ' : ''
        }${monthDiff} month${monthDiff === 1 ? '' : 's'}`
      : ''
  }${
    showDayMetric
      ? `${yearDiff > 0 || monthDiff > 0 ? ' & ' : ''}${dayDiff} day${
          dayDiff === 1 ? '' : 's'
        }`
      : ''
  }`;
}
