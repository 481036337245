import { Box, Link } from '@chakra-ui/react';
import type { MessageAttachment } from '@source-health/client';
import { Paperclip } from '../../../assets';

interface IProps {
  attachment: MessageAttachment;
  isMemberAttachment: boolean;
}

export default function Attachment({
  attachment,
  isMemberAttachment,
}: IProps): JSX.Element {
  const url = attachment.url;
  const name = attachment.description ?? url;

  return (
    <Box
      display="flex"
      alignItems="center"
      bg={isMemberAttachment ? '#EDF7FF' : '#FFF'}
      gap={2}
      p={3}
      mb={3}
      rounded="md"
      shadow="sm"
    >
      <Paperclip />

      <Link
        href={url}
        target="_blank"
        rel="noreferrer"
        download
        color="blue.500"
      >
        {name}
      </Link>
    </Box>
  );
}
