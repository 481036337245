const firebaseConfigs = {
  production: {
    apiKey: 'AIzaSyAaYxKUnAAG8cEDEJjpWKleMqoPlIlrL8A',
    authDomain: 'marley-medical-patient-ui.firebaseapp.com',
    projectId: 'marley-medical-patient-ui',
    storageBucket: 'marley-medical-patient-ui.appspot.com',
    messagingSenderId: '234209457192',
    appId: '1:234209457192:web:f8ed7216fe8892df44711d',
    measurementId: 'G-6J1WVZYS8X',
    reCaptchaV3SiteId: '6Lf6up0jAAAAAOR-aBe4C1DfmalIbgHp5TB9-JNT',
  },
  staging: {
    apiKey: 'AIzaSyBs9jhvClx9KieDtburHojsGzplBY72N78',
    authDomain: 'marley-medical-patient-ui-dev.firebaseapp.com',
    projectId: 'marley-medical-patient-ui-dev',
    storageBucket: 'marley-medical-patient-ui-dev.appspot.com',
    messagingSenderId: '889756058935',
    appId: '1:889756058935:web:ee4927f854cc536f56c6b2',
    reCaptchaV3SiteId: '6LcGip0jAAAAALujlzBBZnIHl5qPn6TpjE7x7xuj',
  },
};

export default function getFirebaseConfig() {
  if (Boolean(process.env.REACT_APP_PREVIEW_ENVIRONMENT)) {
    console.warn('🚀 Working within the STAGING environment');
    return firebaseConfigs.staging;
  }

  if (process.env.NODE_ENV === 'development') {
    console.warn('🛠 Working within the DEVELOPMENT environment');
    return firebaseConfigs.staging;
  }

  return firebaseConfigs.production;
}
