import { Box, Text } from '@chakra-ui/react';

interface IWeightTileProps {
  title: string;
  value: string;
  description?: string;
}

export default function WeightTile({
  title,
  value,
  description,
}: IWeightTileProps) {
  const hasStat = value !== '-';
  return (
    <Box minW={40} p={4} bg="white" flex="1" mr={4} mb={4} borderRadius={24}>
      <Text
        fontWeight="bold"
        color="warmGray.500"
        lineHeight={6}
        letterSpacing={0.75}
        pb={4}
      >
        {title.toUpperCase()}
      </Text>
      <Text
        color={hasStat ? 'blackAlpha.900' : 'warmGray.500'}
        fontWeight="bold"
        fontSize={hasStat ? '4xl' : '2xl'}
        lineHeight={10}
        pb={4}
      >
        {value}
      </Text>
      {description && (
        <Text fontWeight="medium" lineHeight={6} color="warmGray.500">
          {description}
        </Text>
      )}
    </Box>
  );
}
