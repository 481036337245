import { Box, Button, Image, Link, Text } from '@chakra-ui/react';
import DocumentChecklist from './DocumentChecklist';
import logo from '../../assets/marley-logo-purple.svg';
import patientImg from '../../assets/legaldocs-patient.png';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../constants';

interface IProps {
  isComplete: boolean;
  isSubmitting: boolean;
  onClick: () => void;
}

const BOTTOM_BAR_HEIGHT = '130px';

export default function Overview({
  isComplete,
  isSubmitting,
  onClick,
}: IProps): JSX.Element {
  return (
    <>
      <Box display="flex" flexDirection="column" mb={BOTTOM_BAR_HEIGHT}>
        <Image
          src={patientImg}
          position="absolute"
          right={0}
          top={0}
          alt="Patient photo"
          h={['227px', '252px', '378px', '441px']}
          w={['176px', '195px', '292px', '341px']}
        />
        <Box alignSelf="center" maxW={512} p={6}>
          <Image
            src={logo}
            alt="Marley Medical Logo"
            mt={10}
            mb={16}
            h="72px"
            w="72px"
          />

          <Box textAlign="left" mb={6}>
            <Box
              color="brand.purple"
              mb={4}
              fontSize={30}
              lineHeight="36px"
              maxWidth={250}
            >
              <Text fontWeight="bold" as="span">
                We take your
              </Text>{' '}
              <Text fontFamily="Reckless" fontStyle="italic" as="span">
                healthcare
              </Text>{' '}
              <Text fontWeight="bold" as="span">
                and
              </Text>{' '}
              <Text fontFamily="Reckless" fontStyle="italic" as="span">
                privacy
              </Text>{' '}
              <Text fontWeight="bold" as="span">
                seriously.
              </Text>
            </Box>

            <Text fontSize="lg">
              {isComplete
                ? 'Thank you for reviewing each document. Click "Complete" to proceed to the My Marley application.'
                : 'The following 4 documents describe your relationship with Marley Medical. Click "Start" to review and agree to each of the documents.'}
            </Text>
          </Box>

          <DocumentChecklist
            onClick={isComplete ? undefined : onClick}
            isComplete={isComplete}
          />
        </Box>
      </Box>

      <Box
        bg="functional.cream"
        borderTop="1px solid"
        borderColor="functional.warmGray.100"
        boxShadow="0 -1px 2px var(--chakra-colors-functional-warmGray-100)"
        display="flex"
        w="100%"
        h={BOTTOM_BAR_HEIGHT}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        bottom={0}
      >
        <Box p={6} maxW={512}>
          <Button
            bg="brand.purple"
            mb={3}
            borderRadius={20}
            color="white"
            width="100%"
            type="submit"
            _hover={{
              backgroundColor: 'brand.darkPurple',
            }}
            onClick={onClick}
            isLoading={isSubmitting}
          >
            {isComplete ? 'Complete' : 'Start'}
          </Button>

          <Box textAlign="center" fontSize="sm" fontWeight="light">
            <Text>If you have questions, please call us at</Text>
            <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
              {MARLEY_PATIENT_PHONE_NUMBER}
            </Link>
            .
          </Box>
        </Box>
      </Box>
    </>
  );
}
