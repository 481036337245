import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { Events, mixpanel } from '../analytics';
import { Appointment } from '../types';
import { usePatient } from '../contexts';

export default function useFetchAppointments(): [Appointment[], boolean] {
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const { patient } = usePatient();
  const toast = useToast();

  useEffect(() => {
    async function fetchAppointments() {
      try {
        if (!patient) {
          return;
        }

        setIsLoading(true);

        const appointmentsCollection = collection(db, 'appointments');
        const q = query(
          appointmentsCollection,
          where('patientUid', '==', patient.uid),
          orderBy('datetime', 'desc'),
        );
        const querySnapshot = await getDocs(q);
        const appointments: Appointment[] = [];

        querySnapshot.forEach((doc) =>
          appointments.push({ id: doc.id, ...doc.data() } as Appointment),
        );

        setAppointments(appointments);
      } catch (error: any) {
        mixpanel.track(Events.ERROR_ENCOUNTERED, {
          error: 'Failed to fetch appointments',
          message: error?.message,
        });

        toast({
          description: 'An error occurred fetching your appointments.',
          status: 'error',
          title: 'Uh Oh.',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchAppointments();
  }, [patient, toast]);

  return [appointments, isLoading];
}
