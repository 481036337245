import { CommunicationPreference, LegalDocumentTitle } from '../types';

export enum Events {
  AGREEMENT_SIGNED = 'Agreement Signed',
  AGREEMENT_VIEWED = 'Agreement Viewed',
  APPOINTMENT_CONFIRMATION_PAGE_VIEWED = 'Appointment Confirmation Page Opened',
  APPOINTMENT_URL_VIEWED = 'Appointment Location Opened',
  APPOINTMENT_VIEWED = 'Appointment Viewed',
  AGREEMENTS_COMPLETED = 'Agreements Completed',
  COMPLETED_APPOINTMENTS_VIEWED = 'Completed Appointments Viewed',
  DATA_EXPORTED = 'Data Exported',
  ERROR_ENCOUNTERED = 'Error Encountered',
  LOGIN_WITH_MAGIC_LINK_REQUESTED = 'Login With Magic Link Requested',
  LOGIN_WITH_MAGIC_LINK_VIEWED = 'Login With Magic Link Viewed',
  LOGIN_WITH_MAGIC_LINK_VERIFICATION_FAILED = 'Login With Magic Link Verification Failed',
  LOGIN_WITH_MAGIC_LINK_VERIFICATION_SUCCEEDED = 'Login With Magic Link Verification Succeeded',
  LOGIN_WITH_MAGIC_LINK_VERIFICATION_VIEWED = 'Login With Magic Link Verification Viewed',
  MESSAGE_SENT = 'Message Sent',
  NOTIFICATIONS_MODAL_CLOSED = 'Notifications Modal Closed',
  NOTIFICATIONS_MODAL_VIEWED = 'Notifications Modal Viewed',
  PASSWORD_RESET_REQUESTED = 'Password Reset Requested',
  PASSWORD_RESET_RESEND_REQUESTED = 'Password Reset Resend Requested',
  PASSWORD_RESET_SUCCESSFUL = 'Password Reset Successful',
  PAY_BILL_VIEWED = 'Pay Bill Viewed',
  PAY_NOW_CLICKED = 'Pay Now Clicked',
  SCHEDULE_APPOINTMENT_CLICKED = 'Schedule Appointment Clicked',
  SETTINGS_UPDATED = 'Settings Updated',
  THREAD_CREATED = 'Thread Created',
  THREAD_VIEWED = 'Thread Viewed',
  USER_LOGGED_IN = 'User Logged In',
  USER_LOGGED_OUT = 'User Logged Out',
}

export interface EventProperties {
  [Events.AGREEMENT_SIGNED]: {
    agreement_name: LegalDocumentTitle;
  };
  [Events.AGREEMENT_VIEWED]: {
    agreement_name: LegalDocumentTitle;
  };
  [Events.AGREEMENTS_COMPLETED]: null;
  [Events.APPOINTMENT_CONFIRMATION_PAGE_VIEWED]: null;
  [Events.APPOINTMENT_URL_VIEWED]: null;
  [Events.APPOINTMENT_VIEWED]: null;
  [Events.COMPLETED_APPOINTMENTS_VIEWED]: null;
  [Events.DATA_EXPORTED]: {
    export_name:
      | 'All Blood Glucose Readings'
      | 'All Blood Pressure Readings'
      | 'All Weight Readings';
  };
  [Events.MESSAGE_SENT]: {
    has_attachment: boolean;
  };
  [Events.LOGIN_WITH_MAGIC_LINK_REQUESTED]: null;
  [Events.LOGIN_WITH_MAGIC_LINK_VIEWED]: null;
  [Events.LOGIN_WITH_MAGIC_LINK_VERIFICATION_FAILED]: {
    error: 'Invalid Code' | 'No Email';
  };
  [Events.LOGIN_WITH_MAGIC_LINK_VERIFICATION_VIEWED]: null;
  [Events.LOGIN_WITH_MAGIC_LINK_VERIFICATION_SUCCEEDED]: null;
  [Events.NOTIFICATIONS_MODAL_CLOSED]: null;
  [Events.NOTIFICATIONS_MODAL_VIEWED]: {
    notification_type: 'no_bp_readings';
  };
  [Events.ERROR_ENCOUNTERED]: {
    error: string;
    message?: string;
  };
  [Events.PASSWORD_RESET_REQUESTED]: null;
  [Events.PASSWORD_RESET_RESEND_REQUESTED]: null;
  [Events.PASSWORD_RESET_SUCCESSFUL]: null;
  [Events.PAY_BILL_VIEWED]: {
    balance: number;
  };
  [Events.PAY_NOW_CLICKED]: null;
  [Events.SCHEDULE_APPOINTMENT_CLICKED]: {
    care_lead_id: string;
  };
  [Events.SETTINGS_UPDATED]: {
    auto_pay_enabled?: boolean;
    communication_preference?: CommunicationPreference;
  };
  [Events.THREAD_CREATED]: null;
  [Events.THREAD_VIEWED]: null;
  [Events.USER_LOGGED_IN]: null;
  [Events.USER_LOGGED_OUT]: null;
}
