import { Box, Skeleton, Stack } from '@chakra-ui/react';

export default function ListSkeletonLoader(): JSX.Element {
  return (
    <Stack borderRadius={24} bg="white" py={3}>
      <Box py={2} px={4} display="flex" alignItems="center" gap={4}>
        <Skeleton h={2} w="100%" bgColor="brand.purple" />
      </Box>

      <Box py={2} px={4} display="flex" alignItems="center" gap={4}>
        <Skeleton h={2} w="100%" />
      </Box>

      <Box py={2} px={4} display="flex" alignItems="center" gap={4}>
        <Skeleton h={2} w="100%" />
      </Box>

      <Box py={2} px={4} display="flex" alignItems="center" gap={4}>
        <Skeleton h={2} w="100%" />
      </Box>

      <Box py={2} px={4} display="flex" alignItems="center" gap={4}>
        <Skeleton h={2} w="100%" />
      </Box>
    </Stack>
  );
}
