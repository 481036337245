import { Box, Text, Image } from '@chakra-ui/react';
import rightArrowIcon from '../../assets/right-arrow-icon.svg';

interface IProps {
  title: string;
  icon: React.ReactElement;
  children: React.ReactElement;
  onClick: () => void;
}

export default function HealthSummaryCard({
  title,
  icon,
  onClick,
  children,
}: IProps) {
  return (
    <Box
      bg="white"
      p={4}
      borderRadius="xl"
      border="2px solid"
      borderColor="functional.warmGray.100"
      mb={4}
      onClick={onClick}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={4}
      >
        <Box
          display="flex"
          alignItems="center"
          color="functional.warmGray.500"
          gap={2}
        >
          {icon}
          <Text fontWeight="bold" color="functional.warmGray.500">
            {title}
          </Text>
        </Box>
        <Image h={4} src={rightArrowIcon} />
      </Box>
      {children}
    </Box>
  );
}
