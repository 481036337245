import { Box, Text } from '@chakra-ui/react';
import { Layout } from '../../components';
import WeightTile from './WeightTile';
import { usePatient } from '../../contexts';

import { useNavigate } from 'react-router-dom';
import { getPatientWeightMetrics } from '../TrackMyHealthPage/utils';
import AllWeightReadings from './AllWeightReadings';
import dayjs from '../../utils/dayjs';

export default function TrackMyWeightPage(): JSX.Element {
  const navigate = useNavigate();
  const { patient } = usePatient();
  const {
    firstWeight,
    firstWeightTime,
    lastWeight,
    lastWeightTime,
    weightDelta,
    weightDeltaDescription,
    weightDeltaPercent,
  } = getPatientWeightMetrics(patient);

  return (
    <Layout
      nestedPage
      nestedPageTitle="Weight Readings"
      onNestedPageBack={() => navigate('/track-my-health')}
    >
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pt={4} pb={4}>
        Summary
      </Text>
      <Box pb={4}>
        <Box display="flex" justifyContent="center" flexWrap="wrap" mr={-4}>
          <WeightTile
            title="First Weight"
            value={firstWeight}
            description={
              firstWeightTime
                ? `on ${dayjs(firstWeightTime.toDate()).format('MMM D, YYYY')}`
                : undefined
            }
          />
          <WeightTile
            title="Last Weight"
            value={lastWeight}
            description={
              lastWeightTime
                ? `on ${dayjs(lastWeightTime.toDate()).format('MMM D, YYYY')}`
                : undefined
            }
          />
          <WeightTile
            title="Wt Change"
            value={weightDelta}
            description={weightDeltaDescription}
          />
          <WeightTile
            title="% Wt Change"
            value={weightDeltaPercent}
            description={weightDeltaDescription}
          />
        </Box>
      </Box>
      <AllWeightReadings />
    </Layout>
  );
}
