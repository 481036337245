import { Box, Heading } from '@chakra-ui/react';
import { useState } from 'react';
import ScheduleAppointmentButton from './ScheduleAppointmentButton';
import Tabs, { AppointmentTabs } from './Tabs';
import AppointmentsList from './AppointmentsList';
import { Layout } from '../../components';

export default function AppointmentsPage() {
  const [selectedTab, setSelectedTab] = useState<AppointmentTabs>(
    AppointmentTabs.UPCOMING,
  );

  return (
    <Layout>
      <Heading
        lineHeight={10}
        fontSize="4xl"
        pt={8}
        mb={4}
        color="functional.warmGray.900"
      >
        Appointments
      </Heading>

      <Box mb={8}>
        <ScheduleAppointmentButton />
      </Box>

      <Box mb={6}>
        <Tabs onChange={setSelectedTab} selectedTab={selectedTab} />
      </Box>

      <AppointmentsList selectedTab={selectedTab} />
    </Layout>
  );
}
