import { useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithEmailLink } from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Spinner, Text } from '@chakra-ui/react';
import InvalidCode from './InvalidCode';
import { Events, mixpanel } from '../../analytics';
import { auth } from '../../firebase';

export default function VerifyMagicLinkPage(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    mixpanel.track(Events.LOGIN_WITH_MAGIC_LINK_VERIFICATION_VIEWED);
  }, []);

  useEffect(() => {
    const emailFromLocalStorage = window.localStorage.getItem('loginEmail');

    if (emailFromLocalStorage) {
      setEmail(emailFromLocalStorage);
    } else {
      mixpanel.track(Events.LOGIN_WITH_MAGIC_LINK_VERIFICATION_FAILED, {
        error: 'No Email',
      });
    }

    setIsReady(true);
  }, []);

  useEffect(() => {
    async function verifyMagicLink() {
      if (!isReady) {
        return;
      }

      try {
        await signInWithEmailLink(auth, email, window.location.href);

        localStorage.removeItem('loginEmail');
        mixpanel.track(Events.LOGIN_WITH_MAGIC_LINK_VERIFICATION_SUCCEEDED);

        navigate('/');
      } catch (error) {
        setHasError(true);
        mixpanel.track(Events.LOGIN_WITH_MAGIC_LINK_VERIFICATION_FAILED, {
          error: 'Invalid Code',
        });
      }
    }

    verifyMagicLink();
  }, [email, isReady, navigate, oobCode]);

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/messages');
      }
    });

    return subscriber();
  }, [navigate]);

  if (hasError) {
    return <InvalidCode />;
  }

  return (
    <Box alignItems="center" display="flex" h="100vh" justifyContent="center">
      <Box
        alignItems="center"
        display="flex"
        flexDir="column"
        gap={4}
        justifyContent="center"
      >
        <Spinner color="brand.purple" size="lg" speed="0.85s" />
        <Text fontSize="lg">Loading...</Text>
      </Box>
    </Box>
  );
}
