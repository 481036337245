import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { AppointmentTabs } from '../Tabs';
import { ProviderAvatar } from '../../../components';
import { dayjs, getProviderNameById } from '../../../utils';
import { ArrowRight, Calendar, Clock } from '../../../assets';
import { Appointment as IAppointment } from '../../../types';

interface Props {
  appointment: IAppointment;
  selectedTab: AppointmentTabs;
}

function formatTimezone(timezone: string) {
  const [, city] = timezone.split('/');

  return city.replace('_', ' ');
}

export default function Appointment({ appointment, selectedTab }: Props) {
  const providerName = useMemo(
    () => getProviderNameById(appointment.adminId),
    [appointment.adminId],
  );
  const date = useMemo(() => {
    if (selectedTab === AppointmentTabs.UPCOMING) {
      return dayjs(appointment.datetime).format(`dddd, MMMM D`);
    }

    return dayjs(appointment.datetime).format(`MMMM D, YYYY`);
  }, [appointment.datetime, selectedTab]);
  const time = useMemo(
    () => dayjs(appointment.datetime).format('h:mm A'),
    [appointment.datetime],
  );
  const timezone = useMemo(
    () => formatTimezone(appointment.timezone),
    [appointment.timezone],
  );

  return (
    <Box p={2} py={4}>
      <Box alignItems="flex-start" display="flex" gap={3}>
        <ProviderAvatar id={appointment.adminId} size="md" />

        <Box w="full">
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box>
              <Text fontWeight="semibold">{appointment.type.name}</Text>

              <Text color="functional.warmGray.500" fontSize="sm">
                {providerName}
              </Text>
            </Box>

            <ArrowRight fontSize="2xl" />
          </Box>

          <Box
            color="functional.warmGray.500"
            display="flex"
            gap={4}
            fontSize="xs"
          >
            <Box alignItems="center" display="flex" gap={1.5}>
              <Calendar />
              <Text>{date}</Text>
            </Box>

            <Box alignItems="center" display="flex" gap={1.5}>
              <Clock />
              <Text>
                {time}, {timezone}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
