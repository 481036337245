import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowLeftCircle(props: IconProps) {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1942 9.71209C16.5603 10.0782 16.5603 10.6718 16.1942 11.0379L12.1696 15.0625H22.0938C22.6115 15.0625 23.0312 15.4822 23.0312 16C23.0312 16.5178 22.6115 16.9375 22.0938 16.9375H12.1696L16.1942 20.9621C16.5603 21.3282 16.5603 21.9218 16.1942 22.2879C15.828 22.654 15.2345 22.654 14.8683 22.2879L9.24334 16.6629C8.87722 16.2968 8.87722 15.7032 9.24334 15.3371L14.8683 9.71209C15.2345 9.34597 15.828 9.34597 16.1942 9.71209Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}
