import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { Trash } from '../../../assets';

interface IProps {
  fileName: string;
  onRemove: () => void;
}

export default function ThreadAttachment({
  fileName,
  onRemove,
}: IProps): JSX.Element {
  return (
    <Box
      alignItems="center"
      border="1px solid"
      borderColor="gray.200"
      bg="white"
      display="flex"
      justifyContent="space-between"
      mb={2}
      pl={4}
      pr={1}
      py={2}
      rounded="md"
    >
      <Box w="80%">
        <Text color="gray.500" fontSize="xs">
          Attachment
        </Text>
        <Text
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontSize="sm"
        >
          {fileName}
        </Text>
      </Box>

      <Button variant="ghost" onClick={onRemove}>
        <Icon as={Trash} />
      </Button>
    </Box>
  );
}
