import { Box, Text } from '@chakra-ui/react';
import { usePatient } from '../../contexts';

export default function PatientGreeting() {
  const { patient } = usePatient();

  if (!patient) {
    return null;
  }
  return (
    <Box
      mt={8}
      mb={4}
      display="flex"
      flexWrap="wrap"
      alignItems="baseline"
      fontSize="4xl"
      color="functional.warmGray.900"
    >
      <Text fontStyle="italic" fontFamily="Reckless" pr={2}>
        Hello,
      </Text>
      <Text fontWeight="bold">{patient?.firstName}</Text>
    </Box>
  );
}
