import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useFetchBPReadings } from '../../../../hooks';
import { dayjs } from '../../../../utils';

interface Props {
  patientUid: string;
}

const threeMonthsAgoTimestamp = dayjs().subtract(3, 'month').toDate();

export default function HypertensionChart({
  patientUid,
}: Props): JSX.Element | null {
  const [readings, isLoading] = useFetchBPReadings({
    patientUid: patientUid,
    startTime: threeMonthsAgoTimestamp,
  });

  const data = useMemo(() => {
    return readings
      .map((reading) => ({
        date: dayjs(reading.readingTime.toDate()).format('MM-DD-YYYY'),
        systolic: reading.systolic,
        diastolic: reading.diastolic,
      }))
      .reverse();
  }, [readings]);

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <Text
        color="functional.warmGray.500"
        fontSize="xs"
        fontWeight="semibold"
        mb={4}
        textTransform="uppercase"
      >
        Readings Since {dayjs().subtract(3, 'month').format('MM-DD-YYYY')}
      </Text>

      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data} margin={{ left: -20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" hide />
          <YAxis type="number" domain={[40, 'auto']} allowDataOverflow />
          <Tooltip />
          <Legend
            wrapperStyle={{ bottom: '-6px', textTransform: 'capitalize' }}
          />
          <Line type="monotone" dataKey="systolic" stroke="#5A38B2" />
          <Line type="monotone" dataKey="diastolic" stroke="#2A2820" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
