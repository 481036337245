import { Thread as ThreadResource } from '@source-health/client';
import ThreadListItem from './ThreadListItem';
import { Box, Image, Text } from '@chakra-ui/react';
import { SelectedTab } from '../MessagesTabs';
import MarleyHeart from '../../../assets/marley-heart.svg';
import { isThreadUnread } from '../utils';

function partitionByReadState(allThreads: ThreadResource[]) {
  const unreadThreads: ThreadResource[] = [];
  const readThreads: ThreadResource[] = [];
  allThreads.forEach((t: ThreadResource) => {
    const unread = isThreadUnread(
      t.member_last_read ? new Date(t.member_last_read) : null,
      new Date(t.last_message.sent_at),
    );
    if (unread) {
      unreadThreads.push(t);
    } else {
      readThreads.push(t);
    }
  });

  return [unreadThreads, readThreads];
}

function filterRecentMessages(threads: ThreadResource[]) {
  return threads.filter((t: ThreadResource, i) => {
    const isUnread = isThreadUnread(
      t.member_last_read ? new Date(t.member_last_read) : null,
      new Date(t.last_message.sent_at),
    );
    const isOpen = t.status !== 'closed';
    // threads passed into ThreadList are sorted by time of last message sent
    const isWithinThreeMostRecentSent = i < 3;

    return isUnread || isOpen || isWithinThreeMostRecentSent;
  });
}

export default function ThreadList({
  selectedTab,
  threads,
  onThreadSelected,
}: {
  selectedTab: SelectedTab;
  threads: ThreadResource[];
  onThreadSelected: (t: ThreadResource) => void;
}) {
  const threadsToDisplay =
    selectedTab === SelectedTab.RECENT_MESSAGES
      ? filterRecentMessages(threads)
      : threads;

  if (!threadsToDisplay.length) {
    return selectedTab === SelectedTab.RECENT_MESSAGES ? (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box bg="brand.sky" borderRadius={32} p={4}>
          <Image src={MarleyHeart} alt="heart icon" h={8} w={8} />
        </Box>
        <Text
          color="functional.warmGray.900"
          mt={4}
          fontSize="2xl"
          fontWeight="bold"
        >
          You're all caught up
        </Text>
        <Text mt={2} fontSize={18} textAlign="center">
          All recent messages have been answered by your care team. To see all
          your messages, click the “All threads" tab above. If you have another
          topic to discuss, send us a new message.
        </Text>
      </Box>
    ) : (
      <Box mt={8} fontSize={18} textAlign="center">
        No threads found.
      </Box>
    );
  }

  const [unreadThreads, readThreads] = partitionByReadState(threadsToDisplay);

  const listHasReadThreads = readThreads.length > 0;
  const listHasUnreadThreads = unreadThreads.length > 0;

  // sort threads with all unread messages listed first
  // within read/unread groups, messages are sorted by most recent sent_at time
  return (
    <Box
      borderRadius="md"
      border="2px"
      borderColor="functional.warmGray.100"
      mb={4}
    >
      {unreadThreads.map((t, i) => {
        const isFirstItem = i === 0;
        const isLastItem =
          !listHasReadThreads && i === unreadThreads.length - 1;
        return (
          <ThreadListItem
            key={t.id}
            onThreadSelected={onThreadSelected}
            thread={t}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
          />
        );
      })}

      {readThreads.map((t, i) => {
        const isFirstItem = !listHasUnreadThreads && i === 0;
        const isLastItem = i === readThreads.length - 1;
        return (
          <ThreadListItem
            key={t.id}
            onThreadSelected={onThreadSelected}
            thread={t}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
          />
        );
      })}
    </Box>
  );
}
