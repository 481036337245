import { useMemo } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AppointmentTabs } from '../Tabs';
import Appointment from './Appointment';
import EmptyState from './EmptyState';
import { useFetchAppointments } from '../../../hooks';

interface Props {
  selectedTab: AppointmentTabs;
}

export default function AppointmentsList({ selectedTab }: Props): JSX.Element {
  const [appointments, isLoading] = useFetchAppointments();
  const filteredAppointments = useMemo(() => {
    return appointments
      .filter(({ canceled, completed, datetime }) => {
        if (canceled) {
          return false;
        }

        const currentDate = new Date();
        const appointmentDate = new Date(datetime);

        if (selectedTab === AppointmentTabs.UPCOMING) {
          return !completed && appointmentDate > currentDate;
        }

        if (selectedTab === AppointmentTabs.COMPLETED) {
          return completed || appointmentDate < currentDate;
        }

        return false;
      })
      .sort((a, b) => {
        if (selectedTab === AppointmentTabs.UPCOMING) {
          return new Date(a.datetime) < new Date(b.datetime) ? -1 : 1;
        }

        return 1;
      });
  }, [appointments, selectedTab]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner size="lg" color="brand.purple" />
      </Box>
    );
  }

  if (!filteredAppointments.length) {
    return <EmptyState selectedTab={selectedTab} />;
  }

  return (
    <Box
      border="2px solid"
      borderColor="functional.warmGray.100"
      bg="white"
      rounded="xl"
    >
      {filteredAppointments.map((appointment, idx) => (
        <Link key={appointment.id} to={`/appointments/${appointment.id}`}>
          <Box
            borderBottom={
              idx !== filteredAppointments.length - 1 ? '2px solid' : undefined
            }
            borderColor="functional.warmGray.100"
          >
            <Appointment appointment={appointment} selectedTab={selectedTab} />
          </Box>
        </Link>
      ))}
    </Box>
  );
}
