import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import logo from '../../assets/marley-logo-purple.svg';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import {
  Box,
  Image,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import SendMagicLink from './SendMagicLink';
import { PasswordInput } from '../../components';
import { auth, functions } from '../../firebase';
import { Events, mixpanel } from '../../analytics';
import { captureException, captureMessage } from '../../utils';
import patientImg from '../../assets/legaldocs-patient.png';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../constants';
import RequiredAsterisk from '../../components/RequiredAsterisk';

const errorMessages = {
  missingEmail: 'Email is required.',
  missingPassword: 'Password is required.',
  authError: 'Incorrect email or password.',
};

export default function LoginPage(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showMagicLink, setShowMagicLink] = useState<boolean>(false);
  const navigate = useNavigate();

  async function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    const trimmedEmail = email.trim();

    setErrorMessage(null);

    if (!trimmedEmail) {
      setErrorMessage(errorMessages.missingEmail);
      return;
    }

    if (!password) {
      setErrorMessage(errorMessages.missingPassword);
      return;
    }

    setIsLoading(true);

    try {
      const onPatientLogin = httpsCallable(functions, 'onPatientLogin');

      await signInWithEmailAndPassword(auth, trimmedEmail, password);

      onPatientLogin();

      mixpanel.registerOnce({
        portal: 'Patient',
      });
      mixpanel.track(Events.USER_LOGGED_IN);

      navigate('/messages');
    } catch (error: any) {
      captureException(error, 'critical');
      captureMessage('An error occurred logging in');
      setIsLoading(false);
      setErrorMessage(errorMessages.authError);

      mixpanel.track(Events.ERROR_ENCOUNTERED, {
        error: 'Login failed',
        message: error?.message,
      });
    }
  }

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/messages');
      }
    });

    return subscriber();
  }, [navigate]);

  if (showMagicLink) {
    return (
      <SendMagicLink onShowPasswordLogin={() => setShowMagicLink(false)} />
    );
  }

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={patientImg}
        position="absolute"
        right={0}
        top={[-2, 0]}
        alt="Patient photo"
        h={['194px', '252px', '378px', '441px']}
        w={['143px', '195px', '292px', '341px']}
      />
      <Box w="100%" maxW={512} p={4}>
        <Image
          src={logo}
          alt="Marley Medical Logo"
          my={[8, 16]}
          h={['48px', '72px']}
          w={['48px', '72px']}
        />
        <Box textAlign="left" mb={[4, 6]}>
          <Box
            color="brand.purple"
            mb={[1, 2]}
            fontSize={[32, 36]}
            display="flex"
            gap="6px"
            alignItems="baseline"
          >
            <Text
              fontFamily="Reckless"
              fontStyle="italic"
              fontWeight="normal"
              mr={1}
            >
              Welcome
            </Text>
            <Text fontWeight="bold">back</Text>
          </Box>
          <Text>Enter your email and password to log in.</Text>
        </Box>

        <Box mb={[4, 6]}>
          <form onSubmit={handleSubmit}>
            <FormControl
              mb={4}
              isInvalid={errorMessage === errorMessages.missingEmail}
            >
              <FormLabel fontWeight="bold">
                Email address
                <RequiredAsterisk />
              </FormLabel>
              <Input
                w="100%"
                border="2px solid"
                borderColor="gray.300"
                rounded="lg"
                height={12}
                _placeholder={{
                  color: 'gray.500',
                }}
                px={5}
                placeholder="you@example.com"
                value={email}
                onChange={({ currentTarget }) => setEmail(currentTarget.value)}
              />
              {errorMessage === errorMessages.missingEmail && (
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              mb={[2, 4]}
              isInvalid={errorMessage === errorMessages.missingPassword}
            >
              <FormLabel fontWeight="bold">
                Password
                <RequiredAsterisk />
              </FormLabel>
              <PasswordInput
                value={password}
                onChange={({ currentTarget }) =>
                  setPassword(currentTarget.value)
                }
              />
              {errorMessage === errorMessages.missingPassword && (
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
              )}
              {errorMessage === errorMessages.authError && (
                <Text fontSize="sm" color="red.500" mt={2}>
                  {errorMessage}
                </Text>
              )}
            </FormControl>

            <Box
              textAlign="center"
              fontSize="sm"
              fontWeight="light"
              textDecoration="underline"
              color="blue.500"
              mb={[4, 6]}
            >
              <Link as={ReactRouterLink} to="/password-reset">
                Forgot your password?
              </Link>
            </Box>

            <Button
              width="100%"
              type="submit"
              isLoading={isLoading}
              variant="primary"
            >
              Continue
            </Button>
          </form>
        </Box>

        <Box mb={6}>
          <Box
            color="functional.warmGray.100"
            display="flex"
            alignItems="center"
            gap={4}
            mb={[4, 6]}
          >
            <Box h="2px" bg="functional.warmGray.100" flex={1} />
            <Text>or</Text>
            <Box h="2px" bg="functional.warmGray.100" flex={1} />
          </Box>

          <Button
            variant="outlined"
            w="full"
            onClick={() => setShowMagicLink(true)}
          >
            Send Login Link to My Email
          </Button>
        </Box>

        <Box textAlign="center" fontSize="sm" fontWeight="light">
          <Text>If you have questions, please call us at</Text>
          <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
            {MARLEY_PATIENT_PHONE_NUMBER}
          </Link>
          .
        </Box>
      </Box>
    </Box>
  );
}
