import { Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import PayBillButton from './PayBillButton';
import logo from '../../assets/marley-logo-purple.svg';
import settingsIcon from '../../assets/settings-icon.svg';

export default function Header(): JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={4}
    >
      <Link to="/messages">
        <Image src={logo} h={8} w={8} />
      </Link>

      <Box display="flex" gap={2}>
        <PayBillButton />

        <Link to="/settings">
          <Image src={settingsIcon} h={8} w={8} />
        </Link>
      </Box>
    </Box>
  );
}
