import { Route, Routes, useLocation } from 'react-router-dom';
import {
  AppointmentPage,
  AppointmentsPage,
  LegalAgreementsPage,
  LoginPage,
  MessagesPage,
  PasswordResetPage,
  PatientSummaryPage,
  ProfilePage,
  ResetPasswordPage,
  SettingsPage,
  TrackMyBpPage,
  TrackMyGlucosePage,
  TrackMyHealthPage,
  TrackMyWeightPage,
  VerifyMagicLinkPage,
} from './pages';
import { ProtectedRoute, NotificationsModal } from './components';
import { useAutoLogout, useOnLocationChange } from './hooks';

export default function App(): JSX.Element {
  const location = useLocation();
  useAutoLogout();
  useOnLocationChange();

  return (
    <>
      <NotificationsModal />

      <Routes location={location} key={location.pathname}>
        <Route path="login" element={<LoginPage />} />
        <Route path="password-reset" element={<PasswordResetPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route path="welcome" element={<PasswordResetPage />} />
        <Route path="verify-magic-link" element={<VerifyMagicLinkPage />} />
        <Route
          path="messages"
          element={
            <ProtectedRoute>
              <MessagesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="appointments"
          element={
            <ProtectedRoute>
              <AppointmentsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="appointments/:id"
          element={
            <ProtectedRoute>
              <AppointmentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="summary"
          element={
            <ProtectedRoute>
              <PatientSummaryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="track-my-health"
          element={
            <ProtectedRoute>
              <TrackMyHealthPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="track-my-bp"
          element={
            <ProtectedRoute>
              <TrackMyBpPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="track-my-glucose"
          element={
            <ProtectedRoute>
              <TrackMyGlucosePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="track-my-weight"
          element={
            <ProtectedRoute>
              <TrackMyWeightPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        >
          <Route path=":tab" element={<SettingsPage />} />
        </Route>
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="legal-agreements"
          element={
            <ProtectedRoute>
              <LegalAgreementsPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </>
  );
}
