import { SyntheticEvent, useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import {
  Box,
  Image,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import Success from './Success';
import { Events, mixpanel } from '../../../analytics';
import { functions } from '../../../firebase';
import RequiredAsterisk from '../../../components/RequiredAsterisk';
import patientImg from '../../../assets/legaldocs-patient.png';
import logo from '../../../assets/marley-logo-purple.svg';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../../constants';

interface Props {
  onShowPasswordLogin: () => void;
}

export default function SendMagicLink({
  onShowPasswordLogin,
}: Props): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    mixpanel.track(Events.LOGIN_WITH_MAGIC_LINK_VIEWED);
  }, []);

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    const trimmedEmail = email.trim();

    if (!trimmedEmail) {
      return;
    }

    setIsLoading(true);

    const emailMagicLink = httpsCallable<{ email: string }>(
      functions,
      'emailMagicLink',
    );

    try {
      await emailMagicLink({ email: trimmedEmail });

      window.localStorage.setItem('loginEmail', email);

      mixpanel.track(Events.LOGIN_WITH_MAGIC_LINK_REQUESTED);

      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
    }
  }

  if (isSuccess) {
    return <Success email={email} />;
  }

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={patientImg}
        position="absolute"
        right={0}
        top={0}
        alt="Patient photo"
        h={['226px', '252px', '378px', '441px']}
        w={['175px', '195px', '292px', '341px']}
      />
      <Box w="100%" maxW={512} p={4}>
        <Image src={logo} alt="Marley Medical Logo" my={16} h="72px" w="72px" />
        <Box textAlign="left" mb={6}>
          <Box
            color="brand.purple"
            mb={2}
            fontSize={36}
            display="flex"
            gap="6px"
            alignItems="baseline"
          >
            <Text
              fontFamily="Reckless"
              fontStyle="italic"
              fontWeight="normal"
              mr={1}
            >
              Welcome
            </Text>
            <Text fontWeight="bold">back</Text>
          </Box>
          <Text>Enter your email to receive a magic link.</Text>
        </Box>

        <Box mb={6}>
          <form onSubmit={handleSubmit}>
            <FormControl mb={4}>
              <FormLabel fontWeight="bold">
                Email address
                <RequiredAsterisk />
              </FormLabel>
              <Input
                w="100%"
                border="2px solid"
                borderColor="gray.300"
                rounded="lg"
                height={12}
                _placeholder={{
                  color: 'gray.500',
                }}
                px={5}
                placeholder="you@example.com"
                value={email}
                onChange={({ currentTarget }) => setEmail(currentTarget.value)}
              />
            </FormControl>

            <Button
              width="100%"
              type="submit"
              isLoading={isLoading}
              variant="primary"
            >
              Send Login Link to My Email
            </Button>
          </form>
        </Box>

        <Box
          color="functional.warmGray.100"
          display="flex"
          alignItems="center"
          gap={4}
          mb={6}
        >
          <Box h="2px" bg="functional.warmGray.100" flex={1} />
          <Text fontSize="lg">or</Text>
          <Box h="2px" bg="functional.warmGray.100" flex={1} />
        </Box>

        <Button
          variant="outlined"
          w="full"
          onClick={onShowPasswordLogin}
          mb={6}
        >
          Login With Password
        </Button>

        <Box textAlign="center" fontSize="sm" fontWeight="light">
          <Text>If you have questions, please call us at</Text>
          <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
            {MARLEY_PATIENT_PHONE_NUMBER}
          </Link>
          .
        </Box>
      </Box>
    </Box>
  );
}
