import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

function getEnvironment() {
  if (Boolean(process.env.REACT_APP_PREVIEW_ENVIRONMENT)) {
    return 'staging';
  }

  if (process.env.NODE_ENV === 'production') {
    return 'production';
  }

  if (process.env.NODE_ENV === 'development') {
    return 'development';
  }
}

export default function initializeSentry() {
  const environment = getEnvironment();

  if (environment === 'development') {
    return;
  }

  Sentry.init({
    dsn: 'https://b44a8836176642cf9cb33e38dd6b477b@o4504447929155584.ingest.sentry.io/4504482738536448',
    environment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

type TLevel =
  | 'fatal'
  | 'critical'
  | 'error'
  | 'warning'
  | 'log'
  | 'info'
  | 'debug';

export function captureException(error: any, level?: TLevel) {
  Sentry.captureException(error);
}

export function captureMessage(message: string, level?: TLevel) {
  Sentry.captureMessage(message);
}
