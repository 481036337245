import { Box, Heading } from '@chakra-ui/react';
import PatientOverview from './PatientOverview';
import LatestVisit from './LatestVisit';
import ReadingsData from './ReadingsData';
import DownloadPatientDataButton from './DownloadPatientDataButton';
import { Layout } from '../../components';
import { usePatient } from '../../contexts';

export default function PatientSummaryPage(): JSX.Element {
  const { patient } = usePatient();

  return (
    <Layout>
      <Heading lineHeight={10} fontSize="4xl" py={8} color="warmGray.900">
        Patient Summary
      </Heading>

      {patient && (
        <>
          <Box mb={4}>
            <DownloadPatientDataButton patient={patient} />
          </Box>

          <PatientOverview patient={patient} />

          <LatestVisit patient={patient} />

          <ReadingsData patient={patient} />
        </>
      )}
    </Layout>
  );
}
