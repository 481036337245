import { Avatar, AvatarProps } from '@chakra-ui/react';
import practitionerRL from '../../assets/practitioner-rl.png';
import practitionerCB from '../../assets/practitioner-cb.png';
import practitionerRB from '../../assets/practitioner-rb.png';
import practitionerASB from '../../assets/practitioner-asb.png';

const PROVIDER_IMAGE_BY_ID: Record<string, string> = {
  // production
  fFXmEZY5DQUOxfchyASZ3yzep6F2: practitionerASB,
  xSq0vHXty4RBN7kw1EcBvCHeeiq1: practitionerCB,
  bCB5Mu9NpRVq3i2NcpxOEQXLwk53: practitionerRB,
  hitBVAyTEcZhJ9hT2RuY2FWFkDH3: practitionerRL,
  // development
  '3tpBCtwDeLWKUO8FT1ZqqSrPAzw2': practitionerASB,
  ok3f9KlYktbCQno9RNQfZCvnXAv2: practitionerCB,
  qmQ6cYmZLLTfAMjf79V9MRZb6uD2: practitionerRB,
  iNK17anxZFcKfO5Wr7hYOca6RlG3: practitionerRL,
};

interface Props extends AvatarProps {
  id?: string;
  name?: string;
}

export default function ProviderAvatar({
  id,
  name,
  ...rest
}: Props): JSX.Element {
  return (
    <Avatar
      name={name}
      src={id ? PROVIDER_IMAGE_BY_ID[id] : undefined}
      {...rest}
    />
  );
}
