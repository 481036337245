import { Box, Button, Image, Link, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/marley-logo-purple.svg';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../constants';

export default function InvalidCode(): JSX.Element {
  const navigate = useNavigate();

  function handleClick() {
    navigate('/password-reset');
  }

  return (
    <Box
      bg="white"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box w="100%" maxW={512} p={4} textAlign="center">
        <Image
          src={logo}
          alt="Marley Medical Logo"
          mb={[4, '72px']}
          h="72px"
          w="72px"
        />

        <Box textAlign="left" mb={6}>
          <Box
            color="brand.purple"
            mb={2}
            fontSize={36}
            display="flex"
            flexWrap="wrap"
            alignItems="baseline"
            lineHeight="normal"
          >
            <Text fontFamily="Reckless" mr={2}>
              New link
            </Text>
            <Text fontWeight="bold">required</Text>
          </Box>
          <Text>
            The link used to validate your password reset has expired. Request a
            new link below to set your password and activate your account.
          </Text>
        </Box>

        <Button
          bg="brand.purple"
          borderRadius={20}
          color="white"
          width="100%"
          type="submit"
          _hover={{
            backgroundColor: 'brand.darkPurple',
          }}
          onClick={handleClick}
        >
          Request Password Reset
        </Button>

        <Box textAlign="center" fontSize="sm" fontWeight="light" mt={3}>
          <Text>If you have questions, please call us at</Text>
          <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
            {MARLEY_PATIENT_PHONE_NUMBER}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
