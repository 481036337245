import { Avatar, AvatarGroup, Box, Heading, Text } from '@chakra-ui/react';
import practitionerRL from '../../../assets/practitioner-rl.png';
import practitionerCB from '../../../assets/practitioner-cb.png';
import practitionerRB from '../../../assets/practitioner-rb.png';
import practitionerASB from '../../../assets/practitioner-asb.png';

export default function CareTeam(): JSX.Element {
  return (
    <Box textAlign="center" display="flex" flexDirection="column" gap={4}>
      <Heading color="brand.purple" size="md">
        Message your care team
      </Heading>

      <AvatarGroup margin="auto">
        <Avatar src={practitionerRL} size="lg" />
        <Avatar src={practitionerCB} size="lg" />
        <Avatar src={practitionerRB} size="lg" />
        <Avatar src={practitionerASB} size="lg" />
      </AvatarGroup>

      <Box color="blackAlpha.800">
        <Text>We typically respond within 24 hours.</Text>
        <Text>If this is an emergency, dial 911.</Text>
      </Box>
    </Box>
  );
}
