import { useEffect, useState } from 'react';
import { Box, Spinner, Text, useToast } from '@chakra-ui/react';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { ProviderAvatar } from '../../../components';
import { dayjs, getProviderNameById } from '../../../utils';
import { Appointment, IPatient } from '../../../types';
import { db } from '../../../firebase';
import { Calendar } from '../../../assets';

interface Props {
  patient: IPatient;
}

export default function LatestVisit({ patient }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const toast = useToast();

  useEffect(() => {
    async function fetchLatestVisit() {
      const appointmentsCollection = collection(db, 'appointments');
      const q = query(
        appointmentsCollection,
        where('patientUid', '==', patient.uid),
        where('completed', '==', true),
        where('type.isOfficeVisit', '==', true),
        orderBy('datetime', 'desc'),
        limit(1),
      );

      try {
        const querySnapshot = await getDocs(q);
        const appointmentDoc = querySnapshot.docs[0];

        if (appointmentDoc) {
          setAppointment({
            id: appointmentDoc.id,
            ...appointmentDoc.data(),
          } as Appointment);
        }

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        toast({
          description: 'Unable to fetch visits history. Please try again.',
          status: 'error',
          title: 'Uh Oh',
        });
      }
    }

    fetchLatestVisit();
  }, [patient, toast]);

  return (
    <Box>
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
        Latest Visit
      </Text>

      <Box
        bg="white"
        p={4}
        borderRadius="xl"
        border="2px solid"
        borderColor="functional.warmGray.100"
        mb={4}
      >
        {isLoading && (
          <Box display="flex" justifyContent="center">
            <Spinner color="purple" />
          </Box>
        )}
        {appointment && (
          <Box alignItems="center" display="flex" gap={3}>
            <ProviderAvatar id={appointment.adminId} size="md" />

            <Box>
              <Text fontWeight="semibold">{appointment.type.name}</Text>

              <Text color="functional.warmGray.500" fontSize="sm">
                {getProviderNameById(appointment.adminId)}
              </Text>

              <Box
                alignItems="center"
                color="functional.warmGray.500"
                display="flex"
                fontSize="xs"
                gap={1.5}
              >
                <Calendar />
                <Text>
                  {dayjs(appointment.datetime).format(`dddd, MMMM D`)}
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
