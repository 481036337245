import React, { useState } from 'react';
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export default function PasswordInput({ onChange, value }: IProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  function handleClick() {
    setShowPassword(!showPassword);
  }

  return (
    <InputGroup>
      <Input
        border="2px solid"
        borderColor="gray.300"
        rounded="lg"
        height={12}
        _placeholder={{
          color: 'gray.500',
        }}
        px={5}
        placeholder="Password"
        value={value}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
      />
      <InputRightElement top=".25rem" right=".75rem">
        <Button size="xs" onClick={handleClick}>
          {showPassword ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
