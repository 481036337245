import { useEffect, useState } from 'react';
import { Box, Button, Image, Text } from '@chakra-ui/react';
import { Events, mixpanel } from '../../analytics';
import bpCuff from '../../assets/bp-cuff-circle.svg';
import { usePatient } from '../../contexts';

const noReadingsSegmentId = process.env
  .REACT_APP_CIO_NO_BP_READINGS_SEGMENT_ID as string;

export default function NotificationsModal(): JSX.Element | null {
  const { patient } = usePatient();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!patient) {
      return;
    }

    const { segments } = patient;

    if (segments.includes(noReadingsSegmentId)) {
      mixpanel.track(Events.NOTIFICATIONS_MODAL_VIEWED, {
        notification_type: 'no_bp_readings',
      });

      setShowModal(true);
    }
  }, [patient]);

  if (!showModal) {
    return null;
  }

  function handleOnClick() {
    mixpanel.track(Events.NOTIFICATIONS_MODAL_CLOSED);

    setShowModal(false);
  }

  return (
    <Box
      alignItems="center"
      bottom={0}
      bg="blackAlpha.500"
      display="flex"
      justifyContent="center"
      left={0}
      p={4}
      position="absolute"
      right={0}
      top={0}
      zIndex={1000}
    >
      <Box
        alignItems="center"
        bg="white"
        display="flex"
        h="65vh"
        flexDirection="column"
        justifyContent="center"
        maxW={450}
        p={8}
        rounded="md"
        shadow="sm"
        w="100%"
      >
        <Image src={bpCuff} mb={8} />

        <Text fontWeight="medium" fontSize="xl" mb={6}>
          We Need to Hear From You!
        </Text>

        <Box color="gray.500" textAlign="center" mb={12}>
          <Text mb={4}>
            Your care team is waiting to review your blood pressure but they
            need at least 3 readings to do this.
          </Text>

          <Text>
            Please check your blood pressure and we will review it for you.
          </Text>
        </Box>

        <Button w="100%" variant="primary" onClick={handleOnClick}>
          Okay
        </Button>
      </Box>
    </Box>
  );
}
