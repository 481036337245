import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  checkActionCode,
  confirmPasswordReset,
  onAuthStateChanged,
} from 'firebase/auth';
import { Events, mixpanel } from '../../analytics';
import { PasswordInput } from '../../components';
import { captureException, captureMessage } from '../../utils';
import PasswordRequirements from './PasswordRequirements';
import Success from './Success';
import InvalidCode from './InvalidCode';
import { passwordMeetsRequirements } from './utils';
import { auth } from '../../firebase';
import logo from '../../assets/marley-logo-purple.svg';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../constants';

export default function ResetPasswordPage(): JSX.Element {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [hasPasswordInequality, setHasPasswordInequality] =
    useState<boolean>(false);
  const [isInvalidCode, setIsInvalidCode] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const code = searchParams.get('oobCode');

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/messages');
      }
    });

    return subscriber();
  }, [navigate]);

  useEffect(() => {
    async function validateCode() {
      if (!code) {
        return;
      }

      try {
        await checkActionCode(auth, code);
      } catch (error: any) {
        captureException(error, 'error');
        captureMessage(`A password reset token was invalid ${error?.code}`);
        setIsInvalidCode(true);

        mixpanel.track(Events.ERROR_ENCOUNTERED, {
          error: 'Password reset failed',
          message: 'Invalid password reset token',
        });
      }
    }

    validateCode();
  }, [code]);

  async function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setHasError(null);
    setHasPasswordInequality(false);

    if (password !== confirmPassword) {
      setHasPasswordInequality(true);

      mixpanel.track(Events.ERROR_ENCOUNTERED, {
        error: 'Password reset failed',
        message: 'Passwords do not match',
      });

      return;
    }

    if (!passwordMeetsRequirements(password)) {
      setHasError('Password requirements not met.');

      mixpanel.track(Events.ERROR_ENCOUNTERED, {
        error: 'Password reset failed',
        message: 'Password requirements not met',
      });

      return;
    }

    setIsLoading(true);

    try {
      const code = searchParams.get('oobCode')!;

      await confirmPasswordReset(auth, code, password);

      mixpanel.track(Events.PASSWORD_RESET_SUCCESSFUL);

      setShowSuccess(true);
    } catch (error: any) {
      toast({
        description:
          'An error occurred while resetting your password. Please try again.',
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh',
        variant: 'left-accent',
      });

      mixpanel.track(Events.ERROR_ENCOUNTERED, {
        error: 'Password reset failed',
        message: error?.message,
      });

      captureException(error, 'critical');
      captureMessage(`An error occurred resetting a password: ${error?.code}`);
      setIsLoading(false);
    }
  }

  if (showSuccess) {
    return <Success />;
  }

  if (isInvalidCode) {
    return <InvalidCode />;
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box w="100%" maxW={512} p={4}>
        <Image
          src={logo}
          alt="Marley Medical Logo"
          mb={[4, '72px']}
          h="72px"
          w="72px"
        />

        <Box textAlign="left" mb={4}>
          <Box
            color="brand.purple"
            mb={2}
            fontSize={36}
            display="flex"
            gap="6px"
            alignItems="baseline"
          >
            <Text fontFamily="Reckless">Reset</Text>
            <Text fontWeight="bold">password</Text>
          </Box>
          <Text>
            Please create and confirm a new password for your account.
          </Text>
        </Box>

        <Box mb={4}>
          <PasswordRequirements password={password} />
        </Box>

        <form onSubmit={handleSubmit}>
          <FormControl isRequired mb={4} isInvalid={Boolean(hasError)}>
            <FormLabel fontWeight="bold">Password</FormLabel>
            <PasswordInput
              value={password}
              onChange={({ currentTarget }) => setPassword(currentTarget.value)}
            />
            {Boolean(hasError) && (
              <FormErrorMessage>{hasError}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired mb={6} isInvalid={hasPasswordInequality}>
            <FormLabel fontWeight="bold">Confirm password</FormLabel>
            <PasswordInput
              value={confirmPassword}
              onChange={({ currentTarget }) =>
                setConfirmPassword(currentTarget.value)
              }
            />
            {hasPasswordInequality && (
              <FormErrorMessage>Passwords do not match.</FormErrorMessage>
            )}
          </FormControl>

          <Button
            bg="brand.purple"
            borderRadius={20}
            color="white"
            width="100%"
            type="submit"
            isLoading={isLoading}
            _hover={{
              backgroundColor: 'brand.darkPurple',
            }}
          >
            Continue
          </Button>
        </form>

        <Box textAlign="center" fontSize="sm" fontWeight="light" mt={3}>
          <Text>If you have questions, please call us at</Text>
          <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
            {MARLEY_PATIENT_PHONE_NUMBER}
          </Link>
          .
        </Box>
      </Box>
    </Box>
  );
}
