import { Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components';
import { usePatient } from '../../contexts';
import DownloadBiometricsButton, {
  BiometricCollectionType,
} from '../TrackMyHealthPage/DownloadBiometricsButton';
import Readings from './Readings';
import Summary from './Summary';

export default function TrackMyGlucosePage(): JSX.Element {
  const navigate = useNavigate();
  const { patient } = usePatient();

  return (
    <Layout
      nestedPage
      nestedPageTitle="Glucose Readings"
      onNestedPageBack={() => navigate('/track-my-health')}
    >
      {patient && (
        <>
          <Box mb={8}>
            <Summary patient={patient} />
          </Box>

          <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
            All readings
          </Text>

          <DownloadBiometricsButton
            type={BiometricCollectionType.BLOOD_GLUCOSE}
          />

          <Readings patient={patient} />
        </>
      )}
    </Layout>
  );
}
