import { File as SourceFile, Source } from '@source-health/client';

type SourceAttachment = {
  file: SourceFile;
};

export async function uploadFilesToSource(
  files: File[],
  sourceClient: Source,
): Promise<SourceAttachment[]> {
  const sourceAttachments: SourceAttachment[] = [];

  for (const file of files) {
    const uploadedFile = await sourceClient.files.create({
      // @ts-ignore
      file,
      purpose: 'message_attachment',
    });

    sourceAttachments.push({
      file: uploadedFile,
    });
  }

  return sourceAttachments;
}
