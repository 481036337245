import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getFirebaseConfig } from './utils';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = getFirebaseConfig();
const firebase = initializeApp(firebaseConfig);

initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider(firebaseConfig.reCaptchaV3SiteId),
  isTokenAutoRefreshEnabled: true,
});

const auth = getAuth(firebase);
const db = getFirestore(firebase);
const functions = getFunctions(firebase);

if (
  process.env.NODE_ENV === 'development' &&
  !Boolean(process.env.REACT_APP_PREVIEW_ENVIRONMENT)
) {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { auth, db, functions };
