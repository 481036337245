import { Box, Spinner, Text } from '@chakra-ui/react';
import { useFetchBPReadings } from '../../../../hooks';
import { getBPSummaryMetrics } from '../../../TrackMyHealthPage/utils';
import { dayjs } from '../../../../utils';
import { IPatient } from '../../../../types';

interface Props {
  patient: IPatient;
}

const twoWeeksAgoTimestamp = dayjs().subtract(2, 'week').toDate();

export default function TwoWeekAverage({ patient }: Props): JSX.Element {
  const [readings, isLoading] = useFetchBPReadings({
    patientUid: patient.uid,
    startTime: twoWeeksAgoTimestamp,
  });

  const { readingsAvg } = getBPSummaryMetrics({
    patient,
    bpReadings: readings,
  });

  return (
    <Box>
      <Text
        color="functional.warmGray.500"
        fontSize="xs"
        fontWeight="semibold"
        mb={1}
        textTransform="uppercase"
      >
        Two-Week Average
      </Text>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <Spinner color="purple" />
        </Box>
      ) : (
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {readingsAvg
            ? `${readingsAvg.systolic}/${readingsAvg.diastolic}`
            : '—/—'}
        </Text>
      )}
    </Box>
  );
}
