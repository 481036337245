import { Button, Link } from '@chakra-ui/react';
import { Events, mixpanel } from '../../analytics';
import { usePatient } from '../../contexts';
import { PROVIDER_ADMIN_ID_ASB, PROVIDER_ADMIN_ID_KB } from '../../constants';

export default function ScheduleAppointmentButton(): JSX.Element | null {
  const { patient } = usePatient();

  const { adminId } = patient?.careTeamLead || {};

  if (!adminId) {
    return null;
  }

  const appointmentUrl = getAcuityCalendarURL(adminId);

  if (!appointmentUrl) {
    return null;
  }

  function handleOnClick() {
    if (!adminId) {
      return;
    }

    mixpanel.track(Events.SCHEDULE_APPOINTMENT_CLICKED, {
      care_lead_id: adminId,
    });
  }

  return (
    <Button
      as={Link}
      href={appointmentUrl}
      target="_blank"
      variant="outlined"
      w="100%"
      onClick={handleOnClick}
    >
      Schedule an appointment
    </Button>
  );
}

const ACUITY_CALENDAR_ID_BY_CARE_LEAD_ID: Record<string, string> = {
  [PROVIDER_ADMIN_ID_ASB]: '8135095',
  [PROVIDER_ADMIN_ID_KB]: '9311371',
};

function getAcuityCalendarURL(adminId: string): string | undefined {
  const calendarId = ACUITY_CALENDAR_ID_BY_CARE_LEAD_ID[adminId];

  if (!calendarId) {
    return;
  }

  return `https://marleymed.as.me/schedule.php?calendarID=${calendarId}`;
}
