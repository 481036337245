import { Box, Text } from '@chakra-ui/react';
import { Calendar } from '../../../assets';
import { AppointmentTabs } from '../Tabs';

interface Props {
  selectedTab: AppointmentTabs;
}

export default function EmptyState({ selectedTab }: Props) {
  return (
    <Box
      alignItems="center"
      bg="white"
      border="2px solid"
      borderColor="functional.warmGray.100"
      display="flex"
      flexDirection="column"
      gap={2}
      justifyContent="center"
      minH="200px"
      p={4}
      rounded="xl"
    >
      <Calendar color="functional.warmGray.500" fontSize="2xl" />
      <Text>
        You have no{' '}
        <Box as="span" textTransform="lowercase">
          {selectedTab}
        </Box>{' '}
        appointments.
      </Text>
    </Box>
  );
}
