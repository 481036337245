import { Button, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Thread } from '@source-health/client';
import ThreadForm from './ThreadForm';
import { IPatient } from '../../types';
import { isPatientDeactivated } from '../../utils';

interface IProps {
  onSubmit: (thread: Thread) => void;
  patient: IPatient;
}

export default function CreateThreadButton({
  onSubmit,
  patient,
}: IProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const patientDeactivated = isPatientDeactivated(patient);

  return (
    <>
      <ThreadForm isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />

      <Tooltip
        isDisabled={!patientDeactivated}
        label="Only enrolled Marley patients are able to message the clinic."
      >
        <Button
          isDisabled={patientDeactivated}
          onClick={onOpen}
          variant="outlined"
          mb={8}
          size="lg"
          w="full"
        >
          Start a new conversation
        </Button>
      </Tooltip>
    </>
  );
}
