import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Events, mixpanel } from '../analytics';
import { Appointment } from '../types';

export default function useFetchAppointment(
  id?: string,
): [Appointment | undefined, boolean] {
  const [isLoading, setIsLoading] = useState(true);
  const [appointment, setAppointment] = useState<Appointment>();
  const toast = useToast();

  useEffect(() => {
    async function fetchAppointments() {
      try {
        setIsLoading(true);

        const appointmentRef = doc(db, `appointments/${id}`);
        const appointmentDoc = await getDoc(appointmentRef);

        setAppointment({
          id: appointmentDoc.id,
          ...appointmentDoc.data(),
        } as Appointment);
      } catch (error: any) {
        mixpanel.track(Events.ERROR_ENCOUNTERED, {
          error: 'Failed to fetch appointment',
          message: error?.message,
        });

        toast({
          description: 'An error occurred fetching your appointment.',
          status: 'error',
          title: 'Uh Oh.',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchAppointments();
  }, [id, toast]);

  return [appointment, isLoading];
}
