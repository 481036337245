import { Box, Spinner, Text } from '@chakra-ui/react';
import { useFetchGlucoseReadings } from '../../../../hooks';
import { dayjs } from '../../../../utils';
import { IPatient, IReadingBloodGlucose } from '../../../../types';

interface Props {
  patient: IPatient;
}

const twoWeeksAgoTimestamp = dayjs().subtract(2, 'week').toDate();

export default function TwoWeekAverage({ patient }: Props): JSX.Element {
  const [readings, isLoading] = useFetchGlucoseReadings({
    patientUid: patient.uid,
    startDate: twoWeeksAgoTimestamp,
  });

  return (
    <Box>
      <Text
        color="functional.warmGray.500"
        fontSize="xs"
        fontWeight="semibold"
        mb={1}
        textTransform="uppercase"
      >
        Two-Week Average
      </Text>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <Spinner color="purple" />
        </Box>
      ) : (
        <Text color="functional.warmGray.700" fontWeight="bold" size="sm">
          {getTwoWeekAverage(readings)}
        </Text>
      )}
    </Box>
  );
}

function getTwoWeekAverage(readings: IReadingBloodGlucose[]): number | string {
  if (!readings.length) {
    return 'N/A';
  }

  const totalValue = readings.reduce((acc, reading) => acc + reading.value, 0);
  const averageValue = totalValue / readings.length;

  return averageValue.toFixed(0);
}
