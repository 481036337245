import {
  Box,
  Skeleton,
  SkeletonText,
  Text,
  Tooltip,
  Image,
} from '@chakra-ui/react';
import helpCircleIcon from '../../assets/help-circle.svg';

interface IBpTileProps {
  isLoading: boolean;
  title: string;
  tooltipMessage?: string;
  systolic?: number;
  diastolic?: number;
  description?: string;
}

export default function BpTile({
  isLoading,
  title,
  tooltipMessage,
  systolic,
  diastolic,
  description,
}: IBpTileProps) {
  return (
    <Box minW={40} p={4} bg="white" flex="1" mr={4} mb={4} borderRadius={24}>
      <Skeleton
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        isLoaded={!isLoading}
        pb={4}
      >
        <Text
          fontWeight="bold"
          color="warmGray.500"
          lineHeight={6}
          letterSpacing={0.75}
        >
          {title.toUpperCase()}
        </Text>
        <Box>
          {tooltipMessage && (
            <Tooltip label={tooltipMessage} hasArrow rounded="md" size="lg">
              <Image src={helpCircleIcon} />
            </Tooltip>
          )}
        </Box>
      </Skeleton>
      <SkeletonText
        isLoaded={!isLoading}
        color={systolic && diastolic ? 'blackAlpha.900' : 'warmGray.500'}
        fontWeight="bold"
        fontSize={systolic && diastolic ? '4xl' : '2xl'}
        lineHeight={10}
        pb={4}
      >
        {systolic ?? '—'}/{diastolic ?? '—'}
      </SkeletonText>
      {description && (
        <Text fontWeight="medium" lineHeight={6} color="warmGray.500">
          {description}
        </Text>
      )}
    </Box>
  );
}
