import { ChangeEvent, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  useSourceClient,
  useThreadContext,
} from '@source-health/elements-react';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import ThreadAttachment from '../ThreadForm/ThreadAttachment';
import { uploadFilesToSource } from './utils';
import { AutoSizeTextarea } from '../../../components';
import { Paperclip } from '../../../assets';
import { Events, mixpanel } from '../../../analytics';
import { captureException, isPatientDeactivated } from '../../../utils';
import { usePatient } from '../../../contexts';
import { db } from '../../../firebase';

interface IProps {
  onSetFiles: (filesCount: number) => void;
  textareaHeight: number;
  setTextareaHeight: React.Dispatch<React.SetStateAction<number>>;
}

export default function MessageInput({
  onSetFiles,
  textareaHeight,
  setTextareaHeight,
}: IProps): JSX.Element {
  const [messageBody, setMessageBody] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { sendMessage } = useThreadContext();
  const sourceClient = useSourceClient();
  const toast = useToast();
  const { patient } = usePatient();
  const patientDeactivated = isPatientDeactivated(patient);

  function handleAddAttachment(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.item(0);

    if (fileUpload) {
      const updatedFiles = [...files, fileUpload];
      setFiles(updatedFiles);
      onSetFiles(updatedFiles.length);

      event.currentTarget.value = '';
    }
  }

  function handleRemoveAttachment(idx: number) {
    const newFiles = files.filter((_, index) => index !== idx);

    setFiles(newFiles);
    onSetFiles(newFiles.length);
  }

  async function handleOnSubmit() {
    setHasError(false);

    if (messageBody.trim().length === 0) {
      setHasError(true);

      return;
    }

    setIsLoading(true);

    try {
      const attachments = await uploadFilesToSource(files, sourceClient);

      await sendMessage({
        attachments,
        text: messageBody,
      });

      const patientRef = doc(db, 'patients', patient!.uid);
      await updateDoc(patientRef, {
        'engagementActivity.lastMessageSentAt': Timestamp.now(),
      });

      mixpanel.track(Events.MESSAGE_SENT, {
        has_attachment: Boolean(files.length),
      });

      setMessageBody('');
      setFiles([]);
    } catch (error) {
      captureException(error, 'critical');
      toast({
        description:
          'An error occurred while sending your message. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box borderTop="1px solid" borderColor="gray.200" py={2}>
      <FormControl isInvalid={hasError} mb={2}>
        <AutoSizeTextarea
          isDisabled={patientDeactivated}
          placeholder="Type your message here"
          onChange={setMessageBody}
          value={messageBody}
          textareaHeight={textareaHeight}
          setTextareaHeight={setTextareaHeight}
        />
        {hasError && (
          <FormErrorMessage>A message is required.</FormErrorMessage>
        )}
      </FormControl>

      {files.map((file, idx) => (
        <ThreadAttachment
          key={idx}
          fileName={file.name}
          onRemove={() => handleRemoveAttachment(idx)}
        />
      ))}

      <Box display="flex" justifyContent="space-between">
        <Box>
          {!patientDeactivated && (
            <label htmlFor="fileUpload">
              <Box p={2} cursor="pointer">
                <Icon as={Paperclip} />
              </Box>

              <Input
                id="fileUpload"
                type="file"
                display="none"
                onChange={handleAddAttachment}
              />
            </label>
          )}
        </Box>

        <Tooltip
          isDisabled={!patientDeactivated}
          label="Only enrolled Marley patients are able to message the clinic."
        >
          <Button
            variant="primary"
            size="sm"
            onClick={handleOnSubmit}
            isDisabled={patientDeactivated}
            isLoading={isLoading}
          >
            Send
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}
