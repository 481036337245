import { useEffect, useMemo, useState } from 'react';
import { Source } from '@source-health/client';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import { captureException } from '../../utils';

class Token {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  createHeaders() {
    return {
      Authorization: `Bearer ${this.token}`,
    };
  }
}

export function useSourceClient(): [Source | undefined, boolean] {
  const [memberJWT, setMemberJWT] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const source = useMemo(() => {
    if (memberJWT) {
      return new Source(new Token(memberJWT), {
        baseUrl: process.env.REACT_APP_SOURCE_BASE_URL,
      });
    }
  }, [memberJWT]);

  useEffect(() => {
    const generateJWT = httpsCallable(functions, 'sourceGenerateMemberJWT');

    async function fetchMemberJWT() {
      setIsLoading(true);

      try {
        const { data: jwt } = await generateJWT();

        setMemberJWT(jwt as string);
        setIsLoading(false);
      } catch (error) {
        captureException(error);
        setIsLoading(false);
      }
    }

    fetchMemberJWT();
  }, []);

  return [source, isLoading];
}

export function isThreadUnread(
  memberLastRead: Date | null,
  lastMessageSentAt: Date,
): boolean {
  if (memberLastRead === null) {
    return true;
  }

  return lastMessageSentAt > memberLastRead;
}
