import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import { BillingType, IPatient } from '../../types';

interface ResponseData {
  session: {
    url: string;
  };
}

export async function createStripeCustomerPortalUrl(): Promise<string> {
  const createStripeCustomerPortalSession = httpsCallable<null, ResponseData>(
    functions,
    'stripeCreateCustomerPortalSession',
  );

  const { data } = await createStripeCustomerPortalSession();

  return data?.session?.url;
}

export function isInsurancePatient(patient: IPatient | null): boolean {
  if (!patient) {
    return false;
  }

  const { billing } = patient;
  const { type } = billing;

  return type === BillingType.INSURANCE;
}

export function isStripePatient(patient: IPatient): boolean {
  const { billing } = patient;
  const { subscriptionType, type } = billing;

  return type === BillingType.SELF_PAY && subscriptionType !== null;
}
