import { ChangeEvent, useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  FormControl,
  FormHelperText,
  Icon,
  Input,
  Box,
  useToast,
} from '@chakra-ui/react';
import { ArrowLeft, Paperclip } from '../../../assets';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import { usePatient } from '../../../contexts';
import { encodeFiles } from './utils';
import CareTeam from './CareTeam';
import ThreadAttachment from './ThreadAttachment';
import { AutoSizeTextarea } from '../../../components';
import { Events, mixpanel } from '../../../analytics';
import { captureException } from '../../../utils';
import { db } from '../../../firebase';
import { INITIAL_TEXTAREA_HEIGHT } from '../../../components/AutoSizeTextarea';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (thread: any) => void;
}

export default function ThreadForm({
  isOpen,
  onClose,
  onSubmit,
}: IProps): JSX.Element {
  const toast = useToast();
  const [threadSubject, setThreadSubject] = useState<string>('');
  const [messageBody, setMessageBody] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { patient } = usePatient();

  async function handleCreateThread() {
    const trimmedMessage = messageBody.trim();

    setHasError(false);

    if (trimmedMessage.length === 0) {
      setHasError(true);

      return;
    }

    setIsLoading(true);

    const createThread = httpsCallable(functions, 'sourceCreateThread');

    try {
      const encodedFiles = await encodeFiles(files);
      const { data: thread } = await createThread({
        encodedFiles,
        memberId: patient?.sourceId,
        message: trimmedMessage,
        subject: threadSubject,
      });

      const patientRef = doc(db, 'patients', patient!.uid);
      await updateDoc(patientRef, {
        'engagementActivity.lastMessageSentAt': Timestamp.now(),
      });

      mixpanel.track(Events.THREAD_CREATED);

      toast({
        description:
          'Your care team has received your message and will get back to you soon',
        status: 'success',
        title: 'Message Sent',
      });

      handleClose();
      onSubmit(thread);
    } catch (error) {
      captureException(error);
      setIsLoading(false);

      toast({
        description:
          'An error occurred while sending your message. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  function handleAddAttachment({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) {
    const fileUpload = currentTarget.files?.item(0);

    if (!fileUpload) {
      return;
    }

    const updatedFiles = [...files, fileUpload];
    setFiles(updatedFiles);
    currentTarget.value = '';
  }

  function handleRemoveAttachment(idx: number) {
    const updatedFiles = files.filter((_, i) => i !== idx);
    setFiles(updatedFiles);
  }

  function handleClose() {
    setThreadSubject('');
    setMessageBody('');
    setHasError(false);
    setIsLoading(false);
    setFiles([]);

    onClose();
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={handleClose}
      size="full"
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent
        bg="functional.cream"
        height={`calc(${window.innerHeight}px)`}
      >
        <DrawerHeader bg="brand.sky" display="flex" px={7} py={5}>
          <Icon
            as={ArrowLeft}
            color="brand.purple"
            fontSize="2xl"
            onClick={handleClose}
          />
        </DrawerHeader>

        <DrawerBody
          margin="auto"
          py={4}
          maxWidth={512}
          width="100%"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Box flexBasis="40%">
            <CareTeam />
          </Box>

          <Box>
            <FormControl mb={2}>
              <Input
                bg="white"
                borderWidth="2px"
                borderColor="functional.warmGray.300"
                placeholder="Subject (optional)"
                fontSize="lg"
                onChange={({ currentTarget }) =>
                  setThreadSubject(currentTarget.value)
                }
              />
            </FormControl>

            <FormControl mb={2} isInvalid={hasError}>
              <AutoSizeTextarea
                placeholder="Type message"
                onChange={setMessageBody}
                textareaHeight={INITIAL_TEXTAREA_HEIGHT}
                value={messageBody}
              />
              {hasError && (
                <FormHelperText>A message is required.</FormHelperText>
              )}
            </FormControl>

            {files.map((file, idx) => (
              <ThreadAttachment
                key={idx}
                fileName={file.name}
                onRemove={() => handleRemoveAttachment(idx)}
              />
            ))}

            <Box display="flex" justifyContent="space-between">
              <Box>
                <label htmlFor="fileUpload">
                  <Box p={2} cursor="pointer">
                    <Icon as={Paperclip} />
                  </Box>

                  <Input
                    id="fileUpload"
                    type="file"
                    display="none"
                    onChange={handleAddAttachment}
                  />
                </label>
              </Box>

              <Button
                variant="primary"
                onClick={handleCreateThread}
                isLoading={isLoading}
              >
                Send
              </Button>
            </Box>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
