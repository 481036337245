import { Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Bell, ArrowRight, Cash } from '../../assets';

export default function SettingsMenu(): JSX.Element {
  const navigate = useNavigate();

  function handleOnClick(path: string) {
    navigate(path);
  }

  return (
    <Box border="2px solid" borderColor="gray.100" rounded="xl">
      <Box
        alignItems="center"
        borderBottom="2px solid"
        borderColor="gray.100"
        cursor="pointer"
        display="flex"
        justifyContent="space-between"
        p={4}
        onClick={() => handleOnClick('/settings/notifications')}
      >
        <Box
          alignItems="center"
          display="flex"
          gap={3}
          justifyContent="space-between"
        >
          <Bell fontSize="4xl" />

          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Notifications
            </Text>
            <Text fontSize="sm">Set your notification preferences</Text>
          </Box>
        </Box>

        <ArrowRight fontSize="2xl" />
      </Box>

      <Box
        alignItems="center"
        cursor="pointer"
        display="flex"
        justifyContent="space-between"
        p={4}
        onClick={() => handleOnClick('/settings/account')}
      >
        <Box
          alignItems="center"
          display="flex"
          gap={3}
          justifyContent="space-between"
        >
          <Cash fontSize="4xl" />

          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Account
            </Text>
            <Text fontSize="sm">Update your billing information</Text>
          </Box>
        </Box>

        <ArrowRight fontSize="2xl" />
      </Box>
    </Box>
  );
}
