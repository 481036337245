import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    background: 'white',
    border: '3px solid',
    borderColor: 'functional.warmGray.300',
    borderRadius: 'base',
    _disabled: {
      borderColor: 'gray.300',
      bg: 'gray.200',
    },
    _checked: {
      background: 'brand.purple',
      borderColor: 'brand.purple',
    },
  },
  icon: {
    background: 'brand.purple',
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'lg',
  },
});
