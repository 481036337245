import { IPatient } from '../types';

export default function isPatientDeactivated(
  patient?: IPatient | null,
): boolean {
  if (!patient) {
    return true;
  }

  const { deactivatedOn } = patient;

  return Boolean(deactivatedOn);
}
