import { LegalDocumentTitle, TLegalDocument } from './types';
import hipaaNotice from './markdown/hipaaNotice.md';
import informedConsent from './markdown/informedConsent.md';
import newPatientAgreement from './markdown/newPatientAgreement.md';
import telephoneConsumerProtections from './markdown/telephoneConsumerProtections.md';
import assignmentOfBenefits from './markdown/assignmentOfBenefits.md';

export const legalDocumentsByTitle: Record<LegalDocumentTitle, TLegalDocument> =
  {
    [LegalDocumentTitle.NEW_PATIENT_AGREEMENT]: {
      file: newPatientAgreement,
      title: LegalDocumentTitle.NEW_PATIENT_AGREEMENT,
    },
    [LegalDocumentTitle.INFORMED_CONSENT]: {
      file: informedConsent,
      title: LegalDocumentTitle.INFORMED_CONSENT,
    },
    [LegalDocumentTitle.HIPAA_NOTICE]: {
      file: hipaaNotice,
      title: LegalDocumentTitle.HIPAA_NOTICE,
    },
    [LegalDocumentTitle.TELEPHONE_CONSUMER_PROTECTIONS]: {
      file: telephoneConsumerProtections,
      title: LegalDocumentTitle.TELEPHONE_CONSUMER_PROTECTIONS,
    },
    [LegalDocumentTitle.ASSIGNMENT_OF_BENEFITS]: {
      file: assignmentOfBenefits,
      title: LegalDocumentTitle.ASSIGNMENT_OF_BENEFITS,
    },
  };

export const PROVIDER_ADMIN_ID_ASB = 'fFXmEZY5DQUOxfchyASZ3yzep6F2';
export const PROVIDER_ADMIN_ID_DL = '1o4fxD0ntgatJevHB0IpBYC07MQ2';
export const PROVIDER_ADMIN_ID_KB = 'qHVdjun2V4b14crlScXWxz0bBT92';
export const PROVIDER_ADMIN_ID_RB = 'bCB5Mu9NpRVq3i2NcpxOEQXLwk53';

export const CANVAS_PATIENT_PORTAL_URL =
  'https://marleymedical.canvasmedical.com/app/login-form';
export const MARLEY_PATIENT_PHONE_NUMBER = '888-890-2008';
