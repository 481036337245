import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { mixpanel } from '../analytics/';

export enum PageTitles {
  AGREEMENTS = 'Agreements',
  APPOINTMENTS = 'Appointments',
  LOGIN = 'Login',
  MESSAGES = 'Messages',
  PASSWORD_RESET = 'Request Password Reset',
  RESET_PASSWORD = 'Reset Password',
  SETTINGS = 'Settings',
  TRACK = 'Track',
  TRACK_BP = 'Track BP',
  TRACK_GLUCOSE = 'Track Glucose',
  TRACK_WEIGHT = 'Track Weight',
}

const PageTitleByPath: Record<string, PageTitles> = {
  '/': PageTitles.LOGIN,
  '/appointments': PageTitles.APPOINTMENTS,
  '/legal-agreements': PageTitles.AGREEMENTS,
  '/messages': PageTitles.MESSAGES,
  '/password-reset': PageTitles.PASSWORD_RESET,
  '/reset-password': PageTitles.RESET_PASSWORD,
  '/settings': PageTitles.SETTINGS,
  '/track-my-health': PageTitles.TRACK,
  '/track-my-bp': PageTitles.TRACK_BP,
  '/track-my-glucose': PageTitles.TRACK_GLUCOSE,
  '/track-my-weight': PageTitles.TRACK_WEIGHT,
};

export default function useOnLocationChange() {
  const location = useLocation();

  useEffect(() => {
    const page = PageTitleByPath[location.pathname];

    if (page) {
      updateDocumentTitle(page);
      mixpanel.trackPageView(page);
    } else {
      updateDocumentTitle();
    }
  }, [location]);
}

function updateDocumentTitle(page?: string) {
  const appTitle = 'My Marley Medical';

  if (!page) {
    document.title = appTitle;
    return;
  }

  document.title = `${appTitle} - ${page}`;
}
