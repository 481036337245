import { useCallback, useEffect, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import dayjs from '../utils/dayjs';

type TTimer = ReturnType<typeof setTimeout>;

export default function useAutoLogout() {
  const navigate = useNavigate();
  const timerRef = useRef<TTimer | null>(null);

  const startLogoutTimer = useCallback(() => {
    const now = dayjs().unix();
    const expirationTime = dayjs().add(48, 'hour').unix();
    const duration = (expirationTime - now) * 1000;

    timerRef.current = setTimeout(async () => {
      await auth.signOut();
      navigate('/login');
    }, duration);
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        startLogoutTimer();
      }

      if (!user) {
        clearTimeout(timerRef.current!);
        timerRef.current = null;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [startLogoutTimer]);
}
