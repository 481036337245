import { SyntheticEvent, useMemo, useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import EmailResetSuccess from './EmailResetSuccess';
import { Events, mixpanel } from '../../analytics';
import { auth } from '../../firebase';
import { isInternalMarleyEmail } from './utils';
import { captureException } from '../../utils';
import logo from '../../assets/marley-logo-purple.svg';
import { MARLEY_PATIENT_PHONE_NUMBER } from '../../constants';

export default function PasswordResetPage(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const location = useLocation();

  const pageTitle = useMemo(() => {
    if (location.pathname === '/welcome') {
      return ['Create', 'your account'];
    }

    return ['Reset', 'password'];
  }, [location.pathname]);

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsLoading(true);

    try {
      if (!isInternalMarleyEmail(email)) {
        await sendPasswordResetEmail(auth, email);
      }

      mixpanel.track(Events.PASSWORD_RESET_REQUESTED);
    } catch (error: any) {
      captureException(error, 'error');

      mixpanel.track(Events.ERROR_ENCOUNTERED, {
        error: 'Password reset request failed',
        message: error?.message,
      });
    } finally {
      setShowSuccess(true);
      setIsLoading(false);
    }
  }

  if (showSuccess) {
    return <EmailResetSuccess email={email} />;
  }

  return (
    <Box
      bg="white"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box w="100%" maxW={512} p={4} textAlign="center">
        <Image
          src={logo}
          alt="Marley Medical Logo"
          mb="72px"
          h="72px"
          w="72px"
        />

        <Box textAlign="left" mb={6}>
          <Box
            color="brand.purple"
            mb={2}
            fontSize={36}
            display="flex"
            gap="6px"
            alignItems="baseline"
          >
            <Text fontFamily="Reckless">{pageTitle[0]}</Text>
            <Text fontWeight="bold">{pageTitle[1]}</Text>
          </Box>
          <Text>
            Enter your email address and we’ll send a confirmation link.
          </Text>
        </Box>

        <form onSubmit={handleSubmit}>
          <FormControl isRequired mb={6}>
            <FormLabel fontWeight="bold">Email address</FormLabel>
            <Input
              border="2px solid"
              borderColor="gray.300"
              rounded="lg"
              height={12}
              _placeholder={{
                color: 'gray.500',
              }}
              px={5}
              placeholder="you@example.com"
              type="email"
              value={email}
              onChange={({ currentTarget }) => setEmail(currentTarget.value)}
            />
          </FormControl>

          <Button
            bg="brand.purple"
            borderRadius={20}
            color="white"
            width="100%"
            type="submit"
            isLoading={isLoading}
          >
            Continue
          </Button>
        </form>

        <Box textAlign="center" fontSize="sm" fontWeight="light" mt={3}>
          <Text>If you have questions, please call us at</Text>
          <Link href={`tel:${MARLEY_PATIENT_PHONE_NUMBER}`} color="blue.500">
            {MARLEY_PATIENT_PHONE_NUMBER}
          </Link>
          .
        </Box>
      </Box>
    </Box>
  );
}
