import { Box, Button } from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../components';
import Account from './Account';
import Notifications from './Notifications';
import SettingsMenu from './SettingsMenu';
import { Events, mixpanel } from '../../analytics';
import { auth } from '../../firebase';

function getPageTitle(tab?: string): string {
  if (!tab) {
    return 'Settings';
  }

  return tab.charAt(0).toUpperCase() + tab.slice(1);
}

export default function SettingsPage(): JSX.Element {
  const navigate = useNavigate();
  const { tab } = useParams();
  const pageTitle = getPageTitle(tab);

  async function handleSignOut() {
    await signOut(auth);

    mixpanel.track(Events.USER_LOGGED_OUT);
    mixpanel.reset();

    navigate('/login');
  }

  return (
    <Page title={pageTitle} showBackButton>
      <Box px={4} py={6}>
        {!tab && (
          <>
            <SettingsMenu />

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={6}
            >
              <Button variant="outlined" onClick={handleSignOut}>
                Logout
              </Button>
            </Box>
          </>
        )}

        {tab === 'account' && <Account />}
        {tab === 'notifications' && <Notifications />}
      </Box>
    </Page>
  );
}
