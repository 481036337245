import { Box, Text } from '@chakra-ui/react';
import ListSkeletonLoader from '../../components/ListSkeletonLoader';
import { useFetchGlucoseReadings } from '../../hooks';
import { dayjs } from '../../utils';
import { isSameDay } from '../TrackMyHealthPage/utils';
import { IPatient } from '../../types';

interface Props {
  patient: IPatient | null;
}

export default function AllBPReadings({ patient }: Props): JSX.Element {
  const [readings, isLoading] = useFetchGlucoseReadings({
    patientUid: patient?.uid,
  });

  return (
    <>
      {readings.map((reading, i) => {
        const isFirstReading = i === 0;
        const isLastReading = i === readings.length - 1;

        const { id, readingTime, value } = reading;

        const firstReadingForDay =
          isFirstReading ||
          !isSameDay(readingTime, readings[i - 1].readingTime);
        const lastReadingForDay =
          isLastReading || !isSameDay(readingTime, readings[i + 1].readingTime);

        return (
          <Box key={id}>
            {firstReadingForDay && (
              <Text
                fontSize={18}
                bgColor="white"
                color="functional.warmGray.700"
                fontWeight="semibold"
                lineHeight="28px"
                px={4}
                py={1.5}
                borderTopRadius={24}
                borderBottom="2px"
                borderColor="functional.cream"
              >
                {dayjs(readingTime.toDate()).format('MMMM D')}
              </Text>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              bg="white"
              p={4}
              borderBottom={lastReadingForDay ? undefined : '2px'}
              borderColor="functional.cream"
              borderBottomRadius={lastReadingForDay ? 24 : undefined}
              mb={lastReadingForDay ? 4 : undefined}
            >
              <Text lineHeight={6} color="functional.warmGray.700">
                {dayjs(readingTime.toDate()).format('h:mm A')}
              </Text>
              <Text
                lineHeight={7}
                fontSize="xl"
                fontWeight="semibold"
                color="functional.warmGray.700"
              >
                {value}
              </Text>
            </Box>
          </Box>
        );
      })}
      {isLoading && <ListSkeletonLoader />}
    </>
  );
}
