import { Box, Text } from '@chakra-ui/react';
import Hypertension from './Hypertension';
import Weight from './Weight';
import Glucose from './Glucose';
import { useGetPatientPrograms } from '../utils';
import { IPatient } from '../../../types';

interface Props {
  patient: IPatient;
}

export default function ReadingsData({ patient }: Props): JSX.Element {
  const patientPrograms = useGetPatientPrograms(patient);

  return (
    <Box>
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
        Readings
      </Text>

      {patientPrograms.map((program) => {
        switch (program.name) {
          case 'hypertension':
            return <Hypertension key={program.name} patient={patient} />;
          case 'weight':
            return <Weight key={program.name} patient={patient} />;
          case 'diabetes':
            return <Glucose key={program.name} patient={patient} />;
          default:
            return null;
        }
      })}
    </Box>
  );
}
