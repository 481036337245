import { useEffect, useState } from 'react';
import { Icon, Text, Box } from '@chakra-ui/react';
import { ArrowLeft } from '../../../assets';
import {
  MessageList,
  Thread as SourceThread,
} from '@source-health/elements-react';
import type { Thread as ThreadResource } from '@source-health/client';
import Message from '../Message';
import MessageInput from '../MessageInput';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import { captureException } from '../../../utils';
import { INITIAL_TEXTAREA_HEIGHT } from '../../../components/AutoSizeTextarea';
import { Events, mixpanel } from '../../../analytics';

interface IProps {
  onClose: () => void;
  thread: ThreadResource;
}

const SUM_HEADER_PADDING_FOOTER = 129;
const ATTACHMENT_HEIGHT = 68;

export default function Thread({ onClose, thread }: IProps): JSX.Element {
  const [textareaHeight, setTextareaHeight] = useState(INITIAL_TEXTAREA_HEIGHT);
  useEffect(() => {
    async function markThread() {
      const markThreadAsRead = httpsCallable(
        functions,
        'sourceMarkThreadAsRead',
      );

      try {
        await markThreadAsRead({ threadId: thread.id });
      } catch (error) {
        captureException(error, 'error');
      }
    }

    markThread();
  }, [thread.id]);

  useEffect(() => {
    mixpanel.track(Events.THREAD_VIEWED);
  }, [thread.id]);

  useEffect(() => {
    // @NOTE: Dynamically set the height of the Source thread container
    const threadContainer = document.querySelector(
      '.source-thread-container > div > div',
    ) as HTMLElement;

    if (threadContainer) {
      threadContainer.style.height = `calc(${window.innerHeight}px - ${
        SUM_HEADER_PADDING_FOOTER + textareaHeight
      }px)`;
    }
  }, [textareaHeight]);

  function handleSetFiles(filesCount: number) {
    // @NOTE: Dynamically set the height of the Source thread container given the number of attached files
    const threadContainer = document.querySelector(
      '.source-thread-container > div > div',
    ) as HTMLElement;

    const baseHeight = `calc(${window.innerHeight}px - ${
      SUM_HEADER_PADDING_FOOTER + textareaHeight
    }px)`;

    if (filesCount) {
      const attachmentsHeight = ATTACHMENT_HEIGHT * filesCount;

      threadContainer.style.height = `calc(${baseHeight} - ${attachmentsHeight}px)`;
    } else {
      threadContainer.style.height = baseHeight;
    }
  }

  return (
    <Box
      position="absolute"
      top={0}
      right={0}
      left={0}
      zIndex="100"
      bg="functional.cream"
      height={window.innerHeight}
    >
      <Box
        bg="brand.sky"
        display="flex"
        justifyContent="center"
        p={4}
        position="relative"
        alignItems="center"
      >
        <Icon
          as={ArrowLeft}
          color="brand.purple"
          cursor="pointer"
          fontSize="2xl"
          onClick={onClose}
        />
        <Box margin="auto" p={2} maxWidth={512} width="100%">
          <Text
            // mr used to offset arrow icon and keep subject text centered
            mr={8}
            textAlign="center"
            color="brand.purple"
            fontWeight="bold"
            fontSize="md"
          >
            {thread.subject}
          </Text>
        </Box>
      </Box>

      <Box margin="auto" p={2} maxWidth={512} width="100%">
        {/* @ts-ignore */}
        <SourceThread id={thread.id}>
          <div className="source-thread-container">
            <MessageList MessageComponent={Message} />
          </div>

          <Box
            position="absolute"
            bottom={0}
            right={0}
            left={0}
            px={2}
            bg="functional.cream"
            zIndex={1}
          >
            <MessageInput
              textareaHeight={textareaHeight}
              setTextareaHeight={setTextareaHeight}
              onSetFiles={handleSetFiles}
            />
          </Box>
        </SourceThread>
      </Box>
    </Box>
  );
}
