import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { usePatient } from '../contexts';

interface IProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: IProps): JSX.Element {
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { patient } = usePatient();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingAuth && !isAuthenticated) {
      navigate('/login');
    }
  }, [isLoadingAuth, isAuthenticated, navigate]);

  useEffect(() => {
    if (!isAuthenticated || !patient) {
      return;
    }

    if (!patient.hasConsentedToTreatment) {
      navigate('/legal-agreements');
    }
  }, [isAuthenticated, patient, navigate]);

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setIsLoadingAuth(false);
    });

    return subscriber();
  }, []);

  if (isLoadingAuth || !patient) {
    return <></>;
  }

  return <>{children}</>;
}
