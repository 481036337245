import { Box, Text } from '@chakra-ui/react';
import AutoCollectionSwitch from './AutoCollectionSwitch';
import { IPatient } from '../../../../types';

interface Props {
  patient: IPatient;
}

export default function Billing({ patient }: Props): JSX.Element {
  return (
    <Box>
      <Text textTransform="uppercase" fontWeight="bold" color="gray.600" mb={2}>
        Billing Settings
      </Text>

      <AutoCollectionSwitch patient={patient} />
    </Box>
  );
}
