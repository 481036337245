import { Box, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ReadingsOverview from './ReadingsOverview';
import TwoWeekAverage from './TwoWeekAverage';
import HypertensionChart from './HypertensionChart';
import { ArrowRight } from '../../../../assets';
import bpCuff from '../../../../assets/bp-cuff.svg';
import { IPatient } from '../../../../types';

interface Props {
  patient: IPatient;
}

export default function Hypertension({ patient }: Props): JSX.Element {
  return (
    <Box
      bg="white"
      border="2px solid"
      borderColor="functional.warmGray.100"
      borderRadius="xl"
      mb={4}
      p={4}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={4}
      >
        <Box
          alignItems="center"
          color="functional.warmGray.500"
          display="flex"
          gap={2}
        >
          <Image src={bpCuff} />
          <Text fontWeight="bold" color="functional.warmGray.500">
            Hypertension
          </Text>
        </Box>

        <Link to="/track-my-bp">
          <ArrowRight fontSize="2xl" />
        </Link>
      </Box>

      <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr" mb={4}>
        <ReadingsOverview patient={patient} />
        <TwoWeekAverage patient={patient} />
      </Box>

      <HypertensionChart patientUid={patient.uid} />
    </Box>
  );
}
