import { Box, Text } from '@chakra-ui/react';
import JoinAppointmentButton from './JoinAppointmentButton';
import { ProviderAvatar } from '../../components';
import { dayjs, getProviderNameById } from '../../utils';
import { Appointment } from '../../types';
import { Calendar, Clock } from '../../assets';

interface Props {
  appointment: Appointment;
}

export default function AppointmentDetails({
  appointment,
}: Props): JSX.Element {
  const { adminId, datetime, timezone } = appointment;
  const providerName = getProviderNameById(adminId);
  const date = dayjs(datetime).format(`dddd, MMMM D`);
  const time = dayjs(datetime).format('h:mm A');
  const appointmentTimezone = formatTimezone(timezone);

  return (
    <Box>
      <Text fontWeight="bold" fontSize="2xl" mb={4}>
        Details
      </Text>

      <Box
        bg="white"
        border="2px solid"
        borderColor="functional.warmGray.100"
        p={4}
        rounded="lg"
      >
        <Box alignItems="flex-start" display="flex" gap={3} mb={4}>
          <ProviderAvatar id={appointment.adminId} size="lg" />

          <Box>
            <Text fontWeight="semibold" fontSize="xl">
              {appointment.type.name}
            </Text>

            <Text color="functional.warmGray.500" fontSize="lg">
              {providerName}
            </Text>
          </Box>
        </Box>

        <Box
          alignItems="center"
          color="functional.warmGray.500"
          display="flex"
          gap={2}
          mb={2}
        >
          <Calendar />
          <Text>{date}</Text>
        </Box>

        <Box
          alignItems="center"
          color="functional.warmGray.500"
          display="flex"
          gap={2}
          mb={2}
        >
          <Clock />

          <Text>
            {time}, {appointmentTimezone}
          </Text>
        </Box>

        <JoinAppointmentButton appointment={appointment} />
      </Box>
    </Box>
  );
}

function formatTimezone(timezone: string) {
  const [, city] = timezone.split('/');

  return city.replace('_', ' ');
}
