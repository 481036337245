import { Box, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { BpPulse, Calendar, ChatBubble } from '../../assets';

export default function BottomTabNav({ height }: { height: number }) {
  const location = useLocation();
  const isMessagesTab = location.pathname.includes('messages');
  const isTrackingTab = location.pathname.includes('track-my');
  const isAppointmentsTab = location.pathname.includes('appointments');

  return (
    <Box
      height={height}
      bg="functional.cream"
      position="fixed"
      bottom={0}
      w="full"
      borderColor="functional.warmGray.100"
      borderTopWidth="2px"
      fontSize="sm"
    >
      <Box
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        margin="auto"
        maxW={512}
        px={2}
      >
        <Box
          as={Link}
          to="/messages"
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={2}
        >
          {isMessagesTab && (
            <Box
              h={0.5}
              w={10}
              bgColor="brand.purple"
              // allows focused purple line to sit directly on top of grey border
              position="absolute"
              top={-0.5}
            />
          )}
          <Box display="flex" flexDirection="column" alignItems="center">
            <ChatBubble
              h={5}
              w={5}
              fill={isMessagesTab ? 'brand.purple' : 'functional.warmGray.700'}
            />
            <Text
              color={isMessagesTab ? 'brand.purple' : 'functional.warmGray.700'}
            >
              Messages
            </Text>
          </Box>
        </Box>

        <Box
          as={Link}
          to="/appointments"
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={2}
        >
          {isAppointmentsTab && (
            <Box
              h={0.5}
              w={10}
              bgColor="brand.purple"
              // allows focused purple line to sit directly on top of grey border
              position="absolute"
              top={-0.5}
            />
          )}
          <Box display="flex" flexDirection="column" alignItems="center">
            <Calendar
              h={5}
              w={5}
              color={
                isAppointmentsTab ? 'brand.purple' : 'functional.warmGray.700'
              }
            />
            <Text
              color={
                isAppointmentsTab ? 'brand.purple' : 'functional.warmGray.700'
              }
            >
              Appointments
            </Text>
          </Box>
        </Box>

        <Box
          as={Link}
          to="/track-my-health"
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={2}
        >
          {isTrackingTab && (
            <Box
              h={0.5}
              w={10}
              bgColor="brand.purple"
              position="absolute"
              top={-0.5}
            />
          )}
          <Box display="flex" flexDirection="column" alignItems="center">
            <BpPulse
              h={5}
              w={5}
              fill={isTrackingTab ? 'brand.purple' : 'functional.warmGray.700'}
            />
            <Text
              color={isTrackingTab ? 'brand.purple' : 'functional.warmGray.700'}
            >
              Track your health
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
