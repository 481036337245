import { useMemo } from 'react';
import { Timestamp } from 'firebase/firestore';
import { IPatient } from '../../types';

type Program = {
  enrolledOn: Timestamp;
  name: string;
};

export function useGetPatientPrograms(patient: IPatient): Program[] {
  const { programs } = patient;

  const patientPrograms = useMemo(
    () =>
      Object.keys(programs).reduce<Program[]>((acc, programName) => {
        // @ts-ignore
        const program = programs[programName];

        if (program.enrolled) {
          acc.push({
            name: programName,
            ...program,
          });
        }

        return acc;
      }, []),
    [programs],
  );

  return patientPrograms;
}
