import { Icon, IconProps } from '@chakra-ui/react';

export default function Bell(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30.3807 24.6648C30.251 24.5086 30.1236 24.3523 29.9986 24.2016C28.2799 22.1227 27.24 20.868 27.24 14.9828C27.24 11.9359 26.5111 9.43594 25.0744 7.56094C24.015 6.17578 22.583 5.125 20.6955 4.34844C20.6712 4.33493 20.6495 4.3172 20.6314 4.29609C19.9525 2.02266 18.0947 0.5 15.9994 0.5C13.9041 0.5 12.0471 2.02266 11.3682 4.29375C11.35 4.31408 11.3286 4.33125 11.3049 4.34453C6.90018 6.15781 4.75956 9.63672 4.75956 14.9805C4.75956 20.868 3.72128 22.1227 2.00097 24.1992C1.87597 24.35 1.74862 24.5031 1.61893 24.6625C1.28394 25.0665 1.07169 25.558 1.0073 26.0789C0.942924 26.5997 1.02911 27.1281 1.25565 27.6016C1.73768 28.6172 2.76503 29.2477 3.93768 29.2477H28.0697C29.2369 29.2477 30.2572 28.618 30.7408 27.607C30.9683 27.1335 31.0553 26.6047 30.9915 26.0832C30.9277 25.5617 30.7157 25.0695 30.3807 24.6648Z"
          fill="#5F5B52"
        />
        <path
          d="M15.9994 35.5C17.1283 35.4991 18.236 35.1926 19.2048 34.6132C20.1737 34.0337 20.9677 33.2028 21.5025 32.2086C21.5277 32.1609 21.5402 32.1076 21.5387 32.0537C21.5371 31.9998 21.5217 31.9473 21.4939 31.9011C21.466 31.855 21.4268 31.8168 21.3798 31.7903C21.3329 31.7638 21.2799 31.7499 21.226 31.75H10.7744C10.7204 31.7498 10.6673 31.7636 10.6203 31.79C10.5732 31.8164 10.5338 31.8546 10.5059 31.9008C10.478 31.947 10.4625 31.9996 10.4609 32.0535C10.4594 32.1075 10.4718 32.1609 10.4971 32.2086C11.0319 33.2027 11.8257 34.0335 12.7944 34.613C13.7631 35.1924 14.8706 35.4989 15.9994 35.5Z"
          fill="#5F5B52"
        />
      </svg>
    </Icon>
  );
}
