import { IReadingWeight } from '../../types';
import { Box, Text } from '@chakra-ui/react';
import ListSkeletonLoader from '../../components/ListSkeletonLoader';
import { useFetchWeightReadings } from '../../hooks';
import { usePatient } from '../../contexts';
import dayjs from '../../utils/dayjs';
import DownloadBiometricsButton, {
  BiometricCollectionType,
} from '../TrackMyHealthPage/DownloadBiometricsButton';

export default function AllWeightReadings() {
  const { patient } = usePatient();
  const [allWeightReadings, isLoading] = useFetchWeightReadings({
    patientUid: patient?.uid,
  });

  return (
    <>
      <Text fontWeight="bold" fontSize="2xl" lineHeight={7} pb={4}>
        All readings
      </Text>
      <DownloadBiometricsButton type={BiometricCollectionType.WEIGHT} />
      {allWeightReadings.map((reading: IReadingWeight, i) => {
        const isFirstReading = i === 0;
        const isLastReading = i === allWeightReadings.length - 1;

        const firstReadingForDay = isFirstReading
          ? true
          : !dayjs(reading.readingTime.toDate()).isSame(
              allWeightReadings[i - 1].readingTime.toDate(),
              'day',
            );

        const lastReadingForDay = isLastReading
          ? true
          : !dayjs(reading.readingTime.toDate()).isSame(
              allWeightReadings[i + 1].readingTime.toDate(),
              'day',
            );
        return (
          <Box key={reading.id}>
            {firstReadingForDay && (
              <Text
                fontSize={18}
                bgColor="white"
                color="functional.warmGray.700"
                fontWeight="semibold"
                lineHeight="28px"
                px={4}
                py={1.5}
                borderTopRadius={24}
                borderBottom="2px"
                borderColor="functional.cream"
              >
                {dayjs(reading.readingTime.toDate()).format('MMMM D')}
              </Text>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              bg="white"
              p={4}
              borderBottom={lastReadingForDay ? undefined : '2px'}
              borderColor="functional.cream"
              borderBottomRadius={lastReadingForDay ? 24 : undefined}
              mb={lastReadingForDay ? 4 : undefined}
            >
              <Text lineHeight={6} color="functional.warmGray.700">
                {dayjs(reading.readingTime.toDate()).format('h:mm A')}
              </Text>
              <Text
                lineHeight={7}
                fontSize="xl"
                fontWeight="semibold"
                color="functional.warmGray.700"
              >
                {Math.round(reading.value * 10) / 10}
              </Text>
            </Box>
          </Box>
        );
      })}
      {isLoading && <ListSkeletonLoader />}
    </>
  );
}
