import mixpanel from 'mixpanel-browser';
import { Events, EventProperties } from './events';
import { PageTitles } from '../hooks/useOnLocationChange';

class Mixpanel {
  mixpanel: any;

  constructor() {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    const projectToken = getMixpanelProjectToken();

    if (!projectToken) {
      console.error('Unable to locate Mixpanel project token');
      return;
    }

    if (this.mixpanel) {
      console.error('Mixpanel already initialized');
      return;
    }

    this.mixpanel = mixpanel;

    mixpanel.init(projectToken, {
      // @NOTE: Prevents default properties potentially containing sensitive information from being sent to Mixpanel
      property_blacklist: ['$current_url'],
      ignore_dnt: true,
      // @NOTE: Turns off geolocation tracking
      ip: false,
    });
  }

  track(event: Events, properties?: EventProperties[Events]) {
    if (this.mixpanel) {
      this.mixpanel.track(event, properties);
    }
  }

  trackPageView(page: PageTitles) {
    if (this.mixpanel) {
      this.mixpanel.track_pageview({ page, 'Page Title': page });
    }
  }

  identify(id: string) {
    if (this.mixpanel && id) {
      this.mixpanel.identify(id);
    }
  }

  registerOnce(properties: { [key: string]: any }) {
    if (this.mixpanel) {
      this.mixpanel.register_once(properties);
    }
  }

  reset() {
    if (this.mixpanel) {
      this.mixpanel.reset();
    }
  }
}

function getMixpanelProjectToken(): string | null {
  // @NOTE: Unable to rely on environment variables within the staging environment due to Create React App limitations
  // These tokens are not sensitive and can safely be exposed to the client
  const stagingToken = 'd9159d8208d720348d7fe547ca32dddb';
  const productionToken = '803ce7e00ff145ac260902b4ff9cbb89';

  if (Boolean(process.env.REACT_APP_PREVIEW_ENVIRONMENT)) {
    return stagingToken;
  }

  if (process.env.NODE_ENV === 'production') {
    return productionToken;
  }

  return null;
}

const mixpanelInstance = new Mixpanel();

export default mixpanelInstance;
